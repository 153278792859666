// Desktop Styles
.hk-desktop-change-account-body {
  text-align: left;
  padding: 0px 8px;
  h3 {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 24px;
  }
  p {
    margin-bottom: 20px;
  }
  a {
    color: var(--hk-color-spock);
    text-decoration: none;
  }
  .call-concierge {
    font-weight: bold;
  }
}

.hk-desktop-change-account-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--hk-color-oxygen);
  ion-button {
    --background: var(--hk-color-uhura) !important;
    --color: black;
    --box-shadow: none;
    margin: auto;
    margin-bottom: 24px;
    width: 344px;
    text-transform: none;
    height: 48px;
    font-size: 16px;
  }
}

// Mobile Styles
.hk-change-account-body {
  text-align: center;
  margin-top: 64px;
  padding: 16px 10px;
  h3 {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }
  a {
    color: var(--hk-color-spock);
    font-weight: bold;
    text-decoration: none;
  }
}

.hk-change-account-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: var(--hk-color-oxygen);
  ion-button {
    --background: var(--hk-color-uhura) !important;
    --color: black;
    --box-shadow: none;
    text-transform: none;
    height: 48px;
    font-size: 16px;
  }
}
