.hk-home-switcher {
  width: 100%;
  padding: 24px 32px;
  color: var(--hk-color-oxygen);
  background-color: var(--hk-color-klingon);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.hk-home-switcher-icon {
  margin: 0 !important;
  font-size: 24px !important;
  transition: all 0.25s ease;
}
.hk-home-switcher-address-container {
  display: flex;
  align-items: center;
}

.hk-home-switcher-address-item {
  display: flex;
  align-items: center;
  color: var(--hk-color-oxygen);
  padding: 24px 32px;
  background-color: var(--hk-color-scotty);
  margin-bottom: 4px;
  --background: var(--hk-color-scotty);
  cursor: pointer;
  &::part(native) {
    padding-left: 0px;
  }
}

.hk-home-switcher-address-list {
  background: var(--ion-color-dark);
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
  overflow-y: auto;
}

.hk-home-switcher-address {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  margin-left: 8px;
}

ion-searchbar.hk-home-switcher-search-bar {
  --box-shadow: 2px 2px 11px 0px rgba(0, 0, 0, 0.25);
  --color: var(--hk-color-klingon);
  padding: 8px;
  input {
    height: 46px !important;
    font-size: 14px !important;
    --background: #fff;
    --border-radius: 4px;
  }
  .searchbar-search-icon {
    color: #000;
    width: 24px;
    height: 24px;
  }
}

// Result animation
.hk-home-switcher-result {
  position: absolute;
  opacity: 0;
  max-height: 0;
  max-width: 320px;
  transition: max-height 0.25s ease-in-out;
  background: var(--ion-color-dark);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.open {
    width: 100%;
    z-index: 1000;
    opacity: 1;
    max-height: 400px;
  }
}
