.main-menu {
  --background: var(--ion-color-dark);
  ion-content {
    --background: var(--ion-color-dark);
  }
  ion-header .hk-illustration {
    max-width: 180px;
    margin: 90px auto 30px auto;
    pointer-events: none;
    img {
      width: 100%;
    }
  }
  ion-list {
    background: none;
  }
  &-items {
    margin-top: 24px;
  }
  ion-item {
    --border-style: none;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;

    &.no-bold {
      font-weight: normal;
      font-style: italic;
    }
  }
  ion-icon {
    font-size: 16px;
    margin: 0 10px;
  }
  #side-menu-footer {
    border-top: 1px solid #494e5f;
    min-height: 180px;
    padding: 20px;
  }
}

.dev-tag {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  height: 20px;
  border-radius: 3px;
  padding: 3px 8px;

  color: var(--hk-color-oxygen);
  background-color: var(--hk-color-spock);
}
