// Desktop Styles
.hk-desktop-discard-account-changes-modal-body {
  height: 100%;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--hk-color-oxygen);
  &-title {
    font-size: 18px;
    font-weight: bold;
  }
  &-description {
    font-size: 16px;
    margin-top: 16px;
  }
}
.hk-desktop-discard-account-changes-modal-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--hk-color-oxygen);
  padding: 16px 24px;
  border-top: 1px solid var(--hk-color-vulcan);
  ion-button {
    --box-shadow: none;
    text-transform: none;
    height: 48px;
    width: 200px;
    font-size: 16px;
    margin: 0 8px;
  }
}

// Mobile Styles
.hk-discard-account-changes-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-title {
    font-weight: bold;
  }
  &-description {
    line-height: 24px;
  }
}

.hk-discard-account-changes-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  ion-button {
    --background: var(--hk-color-uhura) !important;
    --color: black;
    --box-shadow: none;
    text-transform: none;
    height: 48px;
    font-size: 16px;
  }
}
