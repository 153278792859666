// DESKTOP STYLES
.hk-desktop-zipcode-check-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  background-color: var(--hk-color-oxygen);
  &__title {
    font-size: 21px;
    font-weight: bold;
    padding: 0;
  }
  &__icon {
    font-size: 24px;
    cursor: pointer;
  }
}

.hk-desktop-zipcode-check-body {
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__title {
    color: var(--hk-color-romulan);
    text-align: center;
    max-width: 294px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 46px;
  }
  &__input {
    display: flex;
    .pincode-input-text {
      text-align: center !important;
      font-size: 44px !important;
      font-weight: 600 !important;
      width: 30px !important;
      margin-right: 24px !important;
      border: none !important;
      border-bottom: 4px solid var(--hk-color-spock) !important;
      &:last-child {
        margin-right: 0px !important;
      }
    }
  }
  &__subtitle {
    margin-top: 32px;
    color: var(--hk-color-klingon);
    text-align: center;
    max-width: 294px;
    font-size: 16px;
    font-weight: 700;
  }
  &__error {
    margin-top: 0;
    color: var(--hk-color-voyager);
    font-size: 16px;
    font-weight: bold;
    max-width: 268px;
    text-align: center;
  }
}

.hk-desktop-zipcode-check-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  background-color: var(--hk-color-oxygen);
  ion-button {
    width: 260px;
  }
}

.zipcode-input {
  padding-left: 16px;
  letter-spacing: 22px;
  font-size: 44px !important;
  font-weight: 600 !important;
  width: 256px !important;
  border: none !important;
  border-bottom: 4px solid var(--hk-color-spock) !important;
  background: var(--ion-background-color) !important;
  &:focus {
    outline: none;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    color: var(--ion-color-medium) !important;
    background-color: #fff !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

// MOBILE STYLES
.hk-zipcode-check-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  padding-top: 60px;
  &__title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    padding: 0;
  }
  &__icon {
    font-size: 24px;
    cursor: pointer;
  }
}

.hk-zipcode-check-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  &__title {
    color: var(--hk-color-romulan);
    text-align: center;
    max-width: 294px;
    font-size: 16px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 180px;
  }
  &__input {
    display: flex;
    .pincode-input-text {
      text-align: center !important;
      font-size: 44px !important;
      font-weight: 600 !important;
      width: 30px !important;
      margin-right: 24px !important;
      border: none !important;
      border-bottom: 4px solid var(--hk-color-spock) !important;
      &:last-child {
        margin-right: 0px !important;
      }
    }
  }
  &__subtitle {
    margin-top: 32px;
    color: var(--hk-color-klingon);
    text-align: center;
    max-width: 294px;
    font-size: 16px;
    font-weight: 700;
  }
  &__error {
    margin-top: 0;
    color: var(--hk-color-voyager);
    font-size: 16px;
    font-weight: bold;
    max-width: 268px;
    text-align: center;
  }
}

.hk-zipcode-check-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  background-color: var(--hk-color-oxygen);
  ion-button {
    &:first-child {
      margin-bottom: 16px;
    }
  }
}

.zipcode-error {
  .pincode-input-text {
    border-bottom: 4px solid var(--hk-color-uhura) !important;
  }
}
