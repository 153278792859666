.hk-cancel-subscription-content {
  --background: var(--hk-color-discovery);
}

.hk-cancel-subscription-body {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  color: var(--hk-color-oxygen);
  &-title {
    font-weight: bold;
    margin-top: 32px;
    padding-left: 24px;
  }
  &-description {
    padding-right: 16px;
    padding-left: 24px;
    margin-top: 16px;
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 400;
    &-main {
      padding-right: 16px;
      padding-left: 24px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
  ion-button {
    padding: 0 24px;
    --border-radius: 8px;
  }
  &-back-button {
    margin: 20px 0 32px;
  }
}

.hk-cancel-subscription-body-expanded {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  margin-bottom: 80px;
  color: var(--hk-color-oxygen);
  &-title {
    font-weight: bold;
    margin-top: 32px;
    padding-left: 24px;
  }
  &-description {
    padding-right: 16px;
    padding-left: 24px;
    margin-top: 16px;
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 400;
    &-main {
      padding-right: 16px;
      padding-left: 24px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
  ion-button {
    padding: 0 24px;
    --border-radius: 8px;
  }
  &-back-button {
    margin: 20px 0 32px;
  }
}

// Questions Styles
.hk-cancel-subscription-body-questions {
  .question-item-container {
    border-top: 1px solid var(--hk-color-scotty);
    &:last-child {
      border-bottom: 1px solid var(--hk-color-scotty);
    }
  }
  .question-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &-body {
      width: 100%;
      &-title {
        font-size: 16px;
        font-weight: bold;
        color: var(--hk-color-oxygen);
      }
      ion-textarea {
        font-size: 14px;
        border-bottom: 1px solid var(--ion-color-medium);
      }
    }
    &-checkmark {
      margin-right: 24px;
      ion-icon {
        font-size: 24px;
      }
      &-circle {
        height: 24px;
        width: 24px;
        background-color: var(--hk-color-discovery);
        border-radius: 50%;
        border: 1px solid var(--ion-color-primary);
      }
    }
  }
  &-footer {
    padding: 32px 24px 48px 24px;
    h6 {
      font-size: 16px;
      font-weight: bold;
      margin: 0 0 16px 0;
    }
    &-input {
      min-height: 88px;
      border-radius: 4px;
      font-size: 14px;
      color: var(--hk-color-romulan);
      background: var(--hk-color-oxygen);
      padding-left: 16px;
    }
  }
}

.hk-cancel-subscription-change-plan {
  border-top: 1px solid var(--hk-color-scotty);
  &-button {
    margin: 32px 0 16px;
  }
  &-description {
    padding-right: 16px;
    padding-left: 24px;
    font-size: 16px;
    font-weight: 400;
  }
}
.hk-mobile-cancel-subscription-toolbar {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px 40px 24px;
  ion-button {
    --border-radius: 8px;
    text-transform: none;
    height: 50px;
    width: 100%;
    font-size: 16px;
    margin-left: 0;
  }
}

.hk-cancel-subscription-toolbar {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  padding-bottom: 40px;
  background-color: var(--hk-color-discovery);

  ion-button {
    --border-radius: 8px;
    text-transform: none;
    height: 50px;
    width: 100%;
    font-size: 16px;
    margin-left: 0;
  }
}

// Toggle Animation
.hk-cancel-subscription-animation {
  height: 100%;
}
.toggle-enter.previous {
  opacity: 0;
  transform: translateX(-100%);
}
.toggle-enter-active.previous {
  opacity: 1;
  transform: translateX(0%);
}
.toggle-exit.previous {
  opacity: 1;
  transform: translateX(0%);
}
.toggle-exit-active.previous {
  opacity: 0;
  transform: translateX(-100%);
}
.toggle-enter-active,
.toggle-exit-active.previous {
  transition:
    opacity 300ms,
    transform 300ms;
}

.toggle-enter.next {
  opacity: 0;
  transform: translateX(100%);
}
.toggle-enter-active.next {
  opacity: 1;
  transform: translateX(0%);
}
.toggle-exit.next {
  opacity: 1;
  transform: translateX(0%);
}
.toggle-exit-active.next {
  opacity: 0;
}
.toggle-enter-active,
.toggle-exit-active.next {
  transition:
    opacity 300ms,
    transform 300ms;
}
