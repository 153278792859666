.hk-desktop-digital-inventory-details-page {
  top: 120px !important;
}
.hk-desktop-digital-inventory-details-header {
  justify-content: center;
  background-color: var(--hk-color-discovery);
}

.hk-desktop-digital-inventory-details-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  padding: 0 32px;
}

.hk-desktop-digital-inventory-details-header-breadcrumbs {
  ion-breadcrumb {
    color: var(--hk-color-enterprise);
    font-weight: 600;
    cursor: pointer;
  }
  .breadcrumb-active {
    color: var(--hk-color-oxygen);
  }
}
.hk-desktop-digital-inventory-details-search {
  .hk-search-bar {
    padding: 16px 0;
    width: 358px;
    color: var(--ion-color-grey);
  }
}
.hk-desktop-digital-inventory-details-header-button {
  ion-button {
    --box-shadow: none;
    text-transform: none;
    height: 48px;
    font-size: 16px;
    margin: 16px 0;
  }
}

.hk-desktop-digital-inventory-details-container {
  max-width: 1440px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0 32px;
}
.hk-desktop-digital-inventory-details {
  background-color: var(--hk-color-oxygen);
  padding: 48px;
  border-radius: 10px;
  &-overview {
    display: flex;
    padding-bottom: 40px;
    // border-bottom: 1px solid var(--hk-color-vulcan);
    &-image-slider {
      background-color: var(--ion-background-color);
      width: 600px;
      min-width: 600px;
      margin-right: 48px;
      img {
        max-width: 80%;
        margin: 0 auto 50px auto;
        max-height: 340px;
      }
    }
    .hk-image-placeholder {
      display: flex;
      justify-content: center;
      margin-right: 48px;
      width: 516px;
      img {
        height: 300px;
        opacity: 0.1;
      }
    }
    &-container {
      width: 100%;
    }
    // Product Details Section
    &-attribute {
      display: flex;
      height: 72px;
      font-size: 16px;
      line-height: 18px;
      border-bottom: 1px solid var(--hk-color-vulcan);
      .ion-text-end {
        font-weight: bold;
        color: var(--ion-color-step-250);
      }
    }

    // Documents Section
    .section-header-documents {
      margin-top: 40px;
      margin-bottom: 24px;
    }
    &-document-slider {
      width: 100%;
      display: flex;
      flex-direction: row;
      &-slide {
        width: 144px;
        margin-right: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:hover {
          cursor: pointer;
        }
        &-img {
          // border: 1px solid var(--hk-color-vulcan);
          // border-radius: 4px;
          width: 144px;
          height: 144px;
        }
        &-name {
          margin-top: 8px;
        }
      }
    }
  }

  // Maintenance Section
  .section-header-maintenance {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  // Misc. Styles
  &-label {
    font-size: 24px;
    font-weight: bold;
    line-height: 0;
    color: var(--ion-color-dark);
  }
  &-section-header {
    margin-top: 16px;
    padding-top: 16px !important;
    border-top: 1px solid var(--ion-color-medium);
    font-size: 18px;
    font-weight: bold;
  }

  ion-list,
  ion-list-header,
  ion-item {
    --background: #fff !important;
    background-color: #fff !important;
  }
  ion-list ion-item {
    --inner-padding-start: 0;
  }
}
