.hk-billing-confirmation-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  text-align: center;
  h2 {
    padding-top: 24px;
    font-weight: bold;
    font-size: 24px;
    color: var(--hk-color-scotty);
  }
  p {
    padding-bottom: 24px;
    font-weight: 600;
    font-size: 16px;
    color: var(--hk-color-voyager);
  }
}
