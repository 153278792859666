.hk-loading-spinner {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ion-spinner {
    width: 80px;
    height: 80px;
  }
}

// Desktop Styles
.hk-desktop-digital-catalog-details {
  --background: var(--hk-color-oxygen);
}

.hk-desktop-digital-catalog-details-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  width: 100%;
  top: 0;
  padding: 16px 24px;
  height: 146px;
  border-bottom: 1px solid var(--hk-color-vulcan);
  background-color: var(--hk-color-oxygen);
  z-index: 1000;
  transition: height 200ms ease-out;
  &-title {
    margin-bottom: 4px;
    color: var(--hk-color-klingon);
    font-size: 18px;
    font-weight: bold;
  }
  &-description {
    color: var(--hk-color-klingon);
    font-size: 14px;
  }
}

.hk-desktop-digital-catalog-details-body {
  padding: 24px;
  margin-top: 146px;
  height: 100%;
  overflow: auto;
  &-margin-bottom {
    margin-bottom: 80px;
  }
}

.hk-desktop-digital-catalog-details-body-select-product {
  background-color: var(--ion-background-color);
  border-radius: 8px;
  font-size: 14px;
  height: 52px;
  padding-right: 16px;
  margin-bottom: 24px;
}
// Steps Styles
.hk-desktop-digital-catalog-details-body-steps {
  margin-bottom: 24px;
  &-card {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 8px;
    &-title {
      font-size: 16px;
      font-weight: 600;
      color: var(--hk-color-oxygen);
      border-radius: 10px 10px 0 0;
      padding: 16px 24px;
      background-color: var(--hk-color-scotty);
    }
    &-description {
      border: 1px solid var(--hk-color-vulcan);
      border-radius: 0 0 10px 10px;
      padding: 16px;
      &-image {
        margin-top: 16px;
      }
      &-button {
        --background: var(--hk-color-oxygen);
        --box-shadow: none;
        border: 1px solid var(--hk-color-klingon);
        border-radius: 6px;
        font-size: 16px;
        font-weight: bold;
        color: var(--hk-color-klingon);
        width: 100%;
        height: 48px;
        margin-top: 16px;
        margin-inline: 0;
        ion-icon {
          margin-right: 16px;
        }
      }
    }
  }
}

.product-tag-container {
  display: flex;
  justify-content: space-between;
}
.product-tag {
  border: 1px solid var(--hk-color-romulan);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 100%;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
  &-selected {
    color: var(--hk-color-oxygen);
    background-color: var(--hk-color-scotty);
    border: 1px solid var(--hk-color-scotty);
  }
}

// Video Styles
.hk-desktop-digital-catalog-details-body-video {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--hk-color-vulcan);
  &-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    color: var(--hk-color-romulan);
  }
  &-content {
    display: flex;
    .video {
      width: 100%;
      margin-right: 16px;
      border-radius: 8px;
      border: 1px solid var(--hk-color-vulcan);
      &-description {
        color: var(--hk-color-romulan);
        &-title {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 8px;
        }
        &-subtitle {
          font-size: 14px;
        }
      }
    }
  }
}

// Note Styles
.hk-desktop-digital-catalog-details-input {
  height: 52px;
  border-radius: 8px;
  font-size: 14px;
  color: var(--hk-color-romulan);
  background: var(--ion-background-color);
  --padding-start: 16px;
}
.notes {
  margin: 24px 0;
}

// Tag Styles {
.hk-desktop-digital-catalog-details-body-tags {
  padding-bottom: 60px;
  &-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    color: var(--hk-color-romulan);
  }
  &-content {
    display: flex;
    padding-bottom: 8px;
    overflow-x: auto;
    .tag {
      cursor: pointer;
      background-color: var(--ion-background-color);
      border: 1px solid var(--hk-color-vulcan);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: fit-content;
      border-radius: 4px;
      padding: 4px 8px;
      font-size: 12px;
      font-weight: bold;
      margin-right: 8px;
      white-space: nowrap;
      &-selected {
        animation: highlight 0.3s linear;
        background-color: #eff4de;
        border: 1px solid #c5dc64;
      }
    }
  }
}

// Footer Styles
.hk-desktop-digital-catalog-details-footer {
  position: fixed;
  background-color: var(--hk-color-oxygen);
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  z-index: 1000;
  &-button-container {
    display: flex;
    width: 100%;
  }
  &-button {
    cursor: pointer;
    --background: var(--ion-color-primary);
    --box-shadow: none;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    width: 100%;
    height: 48px;
    width: 174px;
    text-transform: none;
    letter-spacing: inherit;
    margin: 0 0 0 14px;
  }
  &-no-item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--hk-color-klingon);
    font-size: 14px;
    font-weight: bold;
    height: 48px;
    width: 174px;
  }
}

// Mobile Styles
.hk-digital-catalog-details {
  --background: var(--hk-color-oxygen);
}

.hk-digital-catalog-details-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  width: 100%;
  top: 0;
  padding: 16px 24px;
  height: 146px;
  border-bottom: 1px solid var(--hk-color-vulcan);
  background-color: var(--hk-color-oxygen);
  z-index: 1000;
  transition: height 200ms ease-out;
  &-title {
    margin-bottom: 4px;
    color: var(--hk-color-klingon);
    font-size: 18px;
    font-weight: bold;
  }
  &-description {
    color: var(--hk-color-klingon);
    font-size: 14px;
  }
}

.hk-digital-catalog-details-body {
  padding: 24px;
  margin-top: 146px;
  height: 100%;
  overflow: auto;
  &-margin-bottom {
    margin-bottom: 116px;
  }
}
// Picker Styles
.hk-digital-catalog-details-body-picker-title {
  font-size: 16px;
  color: var(--hk-color-voyager);
  margin-bottom: 8px;
}
.hk-digital-catalog-details-body-picker-input {
  background-color: var(--ion-background-color);
  border-radius: 8px;
  font-size: 14px;
  height: 52px;
  width: 100%;
  border: none;
  padding: 16px;
  margin-bottom: 24px;
}
.hk-digital-catalog-details-body-picker-input-icon {
  position: absolute;
  right: 40px;
  top: 218px;
  height: 18px;
  width: 18px;
}
// Steps Styles
.hk-digital-catalog-details-body-steps {
  margin-bottom: 24px;
  &-card {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 8px;
    &-title {
      font-size: 16px;
      font-weight: 600;
      color: var(--hk-color-oxygen);
      border-radius: 10px 10px 0 0;
      padding: 16px 24px;
      background-color: var(--hk-color-scotty);
    }
    &-subtitle {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      ion-icon {
        margin: 8px 8px 8px 16px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: var(--hk-color-uhura);
        color: var(--hk-color-discovery);
      }
      font-weight: 600;
      color: var(--hk-color-uhura);
      background-color: var(--hk-color-discovery);
    }
    &-description {
      border: 1px solid var(--hk-color-vulcan);
      border-radius: 0 0 10px 10px;
      padding: 16px;
      &-image {
        margin-top: 16px;
      }
      &-button {
        --background: var(--hk-color-oxygen);
        --box-shadow: none;
        border: 1px solid var(--hk-color-klingon);
        border-radius: 6px;
        font-size: 16px;
        font-weight: bold;
        color: var(--hk-color-klingon);
        width: 100%;
        height: 48px;
        margin-top: 16px;
        margin-inline: 0;
        ion-icon {
          margin-right: 16px;
        }
      }
    }
  }
}

.product-tag-container {
  display: flex;
  justify-content: space-between;
}
.product-tag {
  border: 1px solid var(--hk-color-romulan);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 100%;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
  &-selected {
    color: var(--hk-color-oxygen);
    background-color: var(--hk-color-scotty);
    border: 1px solid var(--hk-color-scotty);
  }
}

// Video Styles
.hk-digital-catalog-details-body-video {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--hk-color-vulcan);
  &-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    color: var(--hk-color-romulan);
  }
  &-content {
    display: flex;
    .video {
      width: 100%;
      margin-right: 16px;
      border-radius: 8px;
      border: 1px solid var(--hk-color-vulcan);
      &-description {
        color: var(--hk-color-romulan);
        &-title {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 8px;
        }
        &-subtitle {
          font-size: 14px;
        }
      }
    }
  }
}

// Note Styles
.hk-digital-catalog-details-input {
  height: 52px;
  border-radius: 8px;
  font-size: 14px;
  color: var(--hk-color-romulan);
  background: var(--ion-background-color);
  --padding-start: 16px;
}
.notes {
  margin: 24px 0;
}

// Tag Styles {
.hk-digital-catalog-details-body-tags {
  &-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    color: var(--hk-color-romulan);
  }
  &-content {
    display: flex;
    padding-bottom: 16px;
    overflow-x: auto;
    .tag {
      background-color: var(--ion-background-color);
      border: 1px solid var(--hk-color-vulcan);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: fit-content;
      border-radius: 4px;
      padding: 4px 8px;
      font-size: 12px;
      font-weight: bold;
      margin-right: 8px;
      white-space: nowrap;
      cursor: pointer;
      &-selected {
        animation: highlight 0.5s ease-in-out;
        background-color: #eff4de;
        border: 1px solid #c5dc64;
      }
    }
  }
}

// Footer Styles
.hk-digital-catalog-details-footer {
  position: fixed;
  background-color: var(--hk-color-oxygen);
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  z-index: 1000;
  &-button-container {
    display: flex;
    width: 100%;
  }
  &-button {
    --background: var(--ion-color-primary);
    --box-shadow: none;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    width: 100%;
    height: 48px;
    text-transform: none;
    letter-spacing: inherit;
    margin: 0;
  }
  &-button:first-child {
    margin-right: 8px;
  }
  &-no-item {
    cursor: pointer;
    margin-top: 16px;
    margin-bottom: 24px;
    color: var(--hk-color-klingon);
    font-size: 16px;
    font-weight: bold;
  }
}

@keyframes highlight {
  0% {
    background: #c5dc64;
  }
  100% {
    background: #eff4de;
  }
}
