.hk-loading-spinner {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ion-spinner {
    width: 80px;
    height: 80px;
  }
}

// Desktop Styles
.hk-desktop-digital-task-details {
  --background: var(--hk-color-oxygen);
}

.hk-desktop-digital-task-details-not-available {
  pointer-events: none;
}

.hk-desktop-digital-task-details-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  width: 100%;
  top: 0;
  padding: 24px;
  height: 140px;
  background-color: var(--hk-color-voyager);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
  &-pills {
    display: flex;
    &-product {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      border-radius: 16px;
      padding: 4px 8px;
      margin-bottom: 12px;
      background-color: #e0f5f2;
      color: #00685a;
      font-size: 14px;
      font-weight: bold;
      text-transform: capitalize;
    }
    &-not-available {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      border-radius: 16px;
      padding: 4px 8px;
      margin-left: 16px;
      margin-bottom: 12px;
      background-color: var(--hk-color-klingon);
      color: var(--hk-color-oxygen);
      font-size: 14px;
      font-weight: bold;
      ion-icon {
        font-size: 24px;
      }
    }
  }
  &-title {
    color: var(--hk-color-oxygen);
    font-size: 21px;
    font-weight: bold;
  }
}

.hk-desktop-digital-task-details-why {
  background-color: var(--hk-color-scotty);
  color: var(--hk-color-oxygen);
  font-size: 16px;
  margin-top: 140px;
  padding: 16px 24px;
}

.hk-desktop-digital-task-details-body {
  padding: 8px 24px 24px 24px;
  &-margin-bottom {
    margin-bottom: 80px;
  }
}

// Accordion Desktop Styles
.hk-desktop-digital-task-details-body-consumables {
  &-has-tools {
    border-bottom: 1px solid var(--hk-color-vulcan);
    margin-bottom: 0px;
  }
}

.hk-desktop-digital-task-details-body-accordion {
  &-header::part(native) {
    padding: 0;
    background-color: var(--hk-color-oxygen);
    color: var(--hk-color-klingon);
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
  &-content {
    padding: 0px;
    background-color: var(--hk-color-oxygen);
    &__affiliate-text {
      font-size: 13px;
      font-style: italic;
      color: var(--hk-color-scotty);
      margin-top: 0;
    }
    & > * {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 24px;
      }
    }
  }
}

// Steps Desktop Styles
.hk-desktop-digital-task-details-body-steps {
  margin-bottom: 24px;
  &-card {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 8px;
    &-title {
      font-size: 16px;
      font-weight: 600;
      color: var(--hk-color-oxygen);
      // TODO: Add back border for step descrption
      // border-radius: 10px 10px 0 0;
      border-radius: 10px;
      padding: 16px 24px;
      background-color: var(--hk-color-scotty);
    }
    &-description {
      border: 1px solid var(--hk-color-vulcan);
      border-radius: 0 0 10px 10px;
      padding: 16px;
    }
  }
}

// Video Desktop Styles
.hk-desktop-digital-task-details-body-video {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--hk-color-vulcan);
  &-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    color: var(--hk-color-romulan);
  }
  &-content {
    display: flex;
    .video {
      width: 100%;
      &-description {
        color: var(--hk-color-romulan);
        &-title {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 8px;
        }
        &-subtitle {
          font-size: 14px;
        }
      }
    }
  }
}

// Note Desktop Styles
.hk-desktop-digital-task-details-body-note {
  height: 52px;
  margin: 24px 0;
  border-radius: 8px;
  font-size: 14px;
  color: var(--hk-color-voyager);
  background: var(--ion-background-color);
  --padding-start: 16px;
}

// Tag Desktop Styles {
.hk-desktop-digital-task-details-body-tags {
  cursor: pointer;
  padding-bottom: 24px;
  &-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    color: var(--hk-color-romulan);
  }
  &-content {
    display: flex;
    height: 48px;
    overflow-x: auto;
    .tag {
      background-color: var(--ion-background-color);
      border: 1px solid var(--hk-color-vulcan);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: fit-content;
      border-radius: 4px;
      padding: 4px 8px;
      font-size: 12px;
      font-weight: bold;
      margin-right: 8px;
      white-space: nowrap;
      &-selected {
        background-color: #eff4de;
        border: 1px solid #c5dc64;
      }
    }
  }
}

// Footer Desktop Styles
.hk-desktop-digital-task-details-footer {
  background-color: var(--hk-color-oxygen);
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 16px 24px;
  z-index: 1000;
  &-button {
    --background: var(--ion-color-primary);
    --box-shadow: none;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    width: 200px;
    height: 48px;
    text-transform: none;
    letter-spacing: inherit;
  }
}

//Mobile Styles
.hk-digital-task-details {
  --background: var(--hk-color-oxygen);
}

.hk-digital-task-details-not-available {
  pointer-events: none;
}

.hk-digital-task-details-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  width: 100%;
  top: 0;
  padding: 24px 24px;
  height: 192px;
  background-color: var(--hk-color-voyager);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
  &-pills {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-product {
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      border-radius: 16px;
      padding: 4px 8px;
      margin-bottom: 8px;
      background-color: #e0f5f2;
      color: #00685a;
      font-size: 12px;
      font-weight: bold;
      text-transform: capitalize;
    }
    &-not-available {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      border-radius: 16px;
      padding: 4px 8px;
      margin-left: 16px;
      margin-bottom: 8px;
      background-color: var(--hk-color-klingon);
      color: var(--hk-color-oxygen);
      font-size: 12px;
      font-weight: bold;
      ion-icon {
        font-size: 24px;
      }
    }
  }
  &-title {
    color: var(--hk-color-oxygen);
    font-size: 18px;
    font-weight: bold;
  }
}

.hk-digital-task-details-fab-button {
  position: relative;
  height: 24px;
  width: 24px;
  top: -18px;
  right: 14px;
}

.hk-digital-task-details-why {
  background-color: var(--hk-color-scotty);
  color: var(--hk-color-oxygen);
  font-size: 16px;
  margin-top: 192px;
  padding: 16px 24px;
}

.hk-digital-task-details-body {
  padding: 8px 24px 24px 24px;
  &-margin-bottom {
    margin-bottom: 80px;
  }
}

// Accordion Styles
.hk-digital-task-details-body-consumables {
  margin-bottom: 24px;
  &-has-tools {
    border-bottom: 1px solid var(--hk-color-vulcan);
    margin-bottom: 0px;
  }
}

.hk-digital-task-details-body-accordion {
  &-header::part(native) {
    padding: 0;
    background-color: var(--hk-color-oxygen);
    color: var(--hk-color-klingon);
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
  &-content {
    padding: 0px;
    background-color: var(--hk-color-oxygen);
    &__affiliate-text {
      font-size: 13px;
      font-style: italic;
      color: var(--hk-color-scotty);
      margin-top: 0;
    }
    & > * {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 24px;
      }
    }
  }
}

// Steps Styles
.hk-digital-task-details-body-steps {
  margin-bottom: 24px;
  &-card {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 8px;
    &-title {
      font-size: 16px;
      font-weight: 600;
      color: var(--hk-color-oxygen);
      // TODO: Add back border for step descrption
      // border-radius: 10px 10px 0 0;
      border-radius: 10px;
      padding: 16px 24px;
      background-color: var(--hk-color-scotty);
    }
    &-description {
      border: 1px solid var(--hk-color-vulcan);
      border-radius: 0 0 10px 10px;
      padding: 16px;
    }
  }
}

// Video Styles
.hk-digital-task-details-body-video {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--hk-color-vulcan);
  &-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    color: var(--hk-color-romulan);
  }
  &-content {
    display: flex;
    .video {
      width: 100%;
      &-description {
        color: var(--hk-color-romulan);
        &-title {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 8px;
        }
        &-subtitle {
          font-size: 14px;
        }
      }
    }
  }
}

// Note Styles
.hk-digital-task-details-body-note {
  height: 52px;
  margin: 24px 0;
  border-radius: 8px;
  font-size: 14px;
  color: var(--hk-color-voyager);
  background: var(--ion-background-color);
  --padding-start: 16px;
}

// Tag Styles {
.hk-digital-task-details-body-tags {
  padding-bottom: 24px;
  &-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    color: var(--hk-color-romulan);
  }
  &-content {
    display: flex;
    height: 48px;
    overflow-x: auto;
    .tag {
      background-color: var(--ion-background-color);
      border: 1px solid var(--hk-color-vulcan);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: fit-content;
      border-radius: 4px;
      padding: 4px 8px;
      font-size: 12px;
      font-weight: bold;
      margin-right: 8px;
      white-space: nowrap;
      &-selected {
        background-color: #eff4de;
        border: 1px solid #c5dc64;
      }
    }
  }
}

// Footer Styles
.hk-digital-task-details-footer {
  background-color: var(--hk-color-oxygen);
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 16px 24px;
  z-index: 1000;
  &-button {
    --background: var(--ion-color-primary);
    --box-shadow: none;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    width: 100%;
    height: 48px;
    text-transform: none;
    letter-spacing: inherit;
  }
}
