// Desktop Styles
.hk-desktop-appointment-wizard {
  --background: var(--ion-background-color) !important;
  .swiper {
    height: 100%;
  }
}
.hk-desktop-slide-notes {
  .hk-slide-notes-container {
    max-width: 400px;
  }
  .hk-illustration {
    max-width: 62px !important;
  }
  h2 {
    font-size: 24px !important;
  }
  h6 {
    font-size: 16px !important;
  }
  .native-textarea {
    font-size: 16px !important;
    min-height: 198px !important;
  }
}

.hk-desktop-slide-confirmation {
  .hk-slide-confirmation-container {
    max-width: 400px;
    max-height: 436px;
  }
  .hk-illustration {
    max-width: 62px !important;
  }
  .hk-desktop-slide-confirmation-image {
    max-width: 260px !important;
  }
  h2 {
    font-size: 24px !important;
  }
  h6 {
    font-size: 16px !important;
  }
  .native-textarea {
    font-size: 16px !important;
  }
}

.hk-desktop-slide-toolbar {
  ion-button {
    --box-shadow: none;
    width: 400px;
    margin: auto;
    text-transform: none;
    &:hover {
      cursor: pointer;
    }
  }
}

// Mobile Styles
.swipe {
  height: 100%;
}
.hk-appointment-wizard {
  --background: #fff;
  &-toolbar {
    --background: #fff !important;
  }
  .swiper-slide {
    flex-direction: column;
    justify-content: flex-start;

    h2 {
      font-size: 18px;
      font-weight: bold;
    }

    h6 {
      font-size: 14px;
      color: var(--ion-color-grey);
      margin: 24px auto;
      &.hk-appointment-wizard-current {
        margin-top: 0;
      }
    }

    .hk-body-spinner {
      margin: 50%;
    }
  }

  .hk-illustration {
    max-width: 42px;
    margin: 0 auto;
    img {
      margin: 50px 0 16px 0;
    }
  }

  .hk-slide-confirmation-image {
    max-width: 328px !important;
  }

  .textarea-wrapper {
    height: 100% !important;
  }
  .native-textarea {
    text-align: left;
    font-size: 14px;
    line-height: 14px;
    margin-top: 48px;
    padding: 16px;
    border-radius: 8px;
    min-height: 160px;
    background: var(--ion-background-color);
  }

  .list-ios,
  .list-md {
    background: #fff !important;
    width: 100%;
  }
  .hk-list-item {
    --background: var(--ion-background-color) !important;
    border-radius: 10px;
    margin: 16px 0;
    &:hover {
      cursor: pointer;
    }
  }
}

.hk-optional-notes {
  textarea {
    padding-left: 16px !important;
  }
}

@media only screen and (max-height: 750px) {
  .hk-appointment-wizard .hk-list-item {
    margin: 16px 0;
  }
}
