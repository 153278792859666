// Desktop Styles
.hk-desktop-account-page {
  top: 120px !important;
}
.hk-desktop-account {
  --background: var(--ion-color-dark);
  max-width: 1440px;
  margin: 32px auto;
  padding: 0 32px;

  &-header {
    display: flex;
    background: var(--ion-color-dark);
    border-bottom: 1px solid var(--ion-color-grey);
    padding: 32px;
    border-radius: 10px 10px 0 0;
    ion-text {
      color: #fff;
      display: block;
      font-size: 40px;
      &:nth-child(2) {
        margin-left: 10px;
        font-weight: bold;
      }
    }
  }

  ion-list {
    background: var(--ion-color-dark);
    padding: 0;
  }

  .hk-desktop-account-subtitle {
    padding-top: 4px;
    color: var(--hk-color-kirk);
    font-size: 14px;
    font-style: italic;
    white-space: nowrap;
  }

  .hk-delete-account-label {
    color: var(--hk-color-uhura);
  }
  &-item {
    &::part(native) {
      padding: 32px;
      border-bottom: 1px solid var(--ion-color-grey);
    }
  }
  .editable {
    &:hover {
      cursor: pointer;
    }
  }
  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &-details {
    display: flex;
    flex-direction: column;
  }
  &-label {
    margin-bottom: 4px;
    font-size: 18px !important;
    font-weight: bold !important;
  }
  &-value {
    margin: 0 !important;
    font-size: 14px !important;
  }
  &-edit-icon {
    font-size: 24px;
  }
  &-action-renew {
    --border-radius: 8px;
    height: 45px;
    width: 114px;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
  }
  &-footer {
    display: flex;
    justify-content: center;
    background: var(--ion-color-dark);
    padding: 32px;
    border-radius: 0 0 10px 10px;
    ion-button {
      font-weight: bold;
      text-transform: none;
      width: 310px;
      height: 50px;
    }
  }
}

// Mobile Styles
.hk-account {
  --background: var(--ion-color-dark);
  .ion-content {
    --background: var(--ion-color-dark);
  }

  &-header {
    border-bottom: 1px solid var(--ion-color-grey);
    padding: 32px;
    ion-text {
      color: #fff;
      display: block;
      font-size: 40px;
      &:nth-child(2) {
        font-weight: bold;
      }
    }
  }

  .hk-account-section-title {
    color: var(--hk-color-oxygen);
    font-size: 14px;
    padding: 16px 0 0 24px;
  }

  .hk-account-list {
    padding-top: 24px;
    background: var(--ion-color-dark);
    border-bottom: 1px solid var(--ion-color-grey);
  }

  &-label {
    margin-top: 24px;
    margin-bottom: 0px;
    font-size: 18px !important;
    font-weight: bold !important;
  }

  &-value {
    margin: 0 !important;
    font-size: 18px !important;
    font-family: 'Open Sans';
  }

  &-edit-icon {
    margin-top: 24px;
  }
}

.hk-account-toolbar {
  padding: 0 24px;
  ion-button {
    --box-shadow: none;
    --border-radius: 8px;
    text-transform: none;
    height: 48px;
    width: 100%;
    font-size: 16px;
    margin-left: 0;
  }
}

.hk-account-delete-container {
  background-color: var(--hk-color-scotty);
  margin: 24px 24px 40px 24px;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hk-account-delete-description {
  width: 200px;
  &-title {
    font-size: 18px;
    font-weight: bold;
    color: var(--hk-color-uhura);
  }
  &-body {
    color: var(--hk-color-oxygen);
    font-size: 14px;
  }
}

.hk-account-subtitle {
  padding-top: 8px;
  color: var(--hk-color-kirk);
  font-size: 14px;
  font-style: italic;
  white-space: nowrap;
}

.hk-account-delete-icon {
  color: var(--hk-color-uhura);
  font-size: 24px;
  line-height: 0;
  ion-icon {
    margin: 0;
  }
}

.hk-account {
  ion-list {
    background: var(--ion-color-dark);
  }
  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--hk-color-oxygen);
    margin: 0 24px 24px 24px !important;
    &-info {
      display: flex;
      flex-direction: column;
      width: 75%;
      &-label {
        margin-top: 0;
        margin-bottom: 4px;
        font-size: 18px !important;
        font-weight: bold !important;
      }
      &-value {
        margin: 0 !important;
        font-size: 14px !important;
        ion-text {
          display: inline-block;
          height: 22px;
        }
      }
    }
    &-action {
      display: flex;
      align-items: center;
      font-size: 24px;
      ion-icon {
        margin: 0;
      }
    }
  }
  &-item-subscription {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    color: var(--hk-color-oxygen);
    margin: 0 24px !important;
    &-info {
      display: flex;
      flex-direction: column;
      width: 100%;
      &-label {
        display: flex;
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 32px;
        &-plan {
          font-size: 18px !important;
          font-weight: bold !important;
        }
        &-status {
          font-size: 14px;
        }
      }
      &-value {
        margin: 0 !important;
        font-size: 14px !important;
        ion-text {
          display: inline-block;
          height: 22px;
        }
      }
    }

    &-action {
      width: 100%;
      &-renew {
        --border-radius: 8px;
        height: 45px;
        width: 100%;
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: bold;
      }
      &-change {
        height: 45px;
        width: 100%;
        margin-left: 8px;
        border-radius: 8px;
        border: 1px solid var(--hk-color-enterprise);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: var(--hk-color-enterprise);
      }
    }
    &-update-button {
      --border-radius: 8px !important;
      color: var(--ion-color-dark);
      margin: 0px 24px;
    }
    &-cancel-button {
      --background: var(--hk-color-uhura);
      --border-radius: 8px !important;
      color: var(--ion-color-dark);
      margin: 16px 24px;
    }
  }
  &-item-signin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--hk-color-oxygen);
    margin: 0 24px 24px 24px !important;
    &-info {
      display: flex;
      flex-direction: column;
      width: 75%;
      &-label {
        margin-top: 0;
        margin-bottom: 4px;
        font-size: 18px !important;
        font-weight: bold !important;
      }
      &-value {
        margin: 0 !important;
        font-size: 14px !important;
        ion-text {
          display: inline-block;
          height: 22px;
        }
      }
    }

    &-action {
      display: flex;
      align-items: center;
      font-size: 28px;
      ion-icon {
        margin: 0;
      }
    }
  }
}

.hk-account-renew-banner {
  padding: 16px 24px;
  background-color: #e0f1fa;
  font-weight: bold;
  font-size: 14px;
  z-index: 10;
}
