@import 'src/theme/variables.scss';

.hk-thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border-radius: 8px;
  background-color: var(--hk-color-oxygen);
  border: 1px solid var(--hk-color-vulcan);
  &-placeholder {
    height: 60px !important;
    width: 60px !important;
    color: var(--hk-color-vulcan);
  }
}
