.hk-billing-summary-toolbar {
  padding-bottom: 16px;
}

.hk-billing-summary-content {
  background-color: var(--hk-color-oxygen);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  padding-bottom: 40px;
  text-align: center;
  h2 {
    font-weight: bold;
    font-size: 24px;
    color: var(--hk-color-scotty);
    margin-top: 0;
  }
  p {
    padding-bottom: 16px;
    font-weight: 600;
    font-size: 16px;
    color: var(--hk-color-voyager);
  }
}

.hk-billing-summary-subscription {
  width: 100%;
  border: 1px solid var(--hk-color-vulcan);
  border-radius: 10px;
  padding: 24px 16px;
  &-header {
    height: 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    h4 {
      font-size: 16px;
      font-weight: bold;
    }
    .hk-subscription-pill {
      background-color: #d9f9f4;
      font-size: 12px;
      font-weight: bold;
      color: #00685a;
      border-radius: 16px;
      padding: 8px 4px;
      width: 68px;
    }
  }
  &-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    &-name {
      text-align: left;
      margin: 0;
      font-size: 16px;
      font-weight: 600;
    }
    &-price {
      text-align: right;
      font-size: 18px;
      font-weight: bold;
    }
  }
  &-promo {
    position: relative;
    padding: 24px 0;
    border-top: 1px solid var(--hk-color-vulcan);
    border-bottom: 1px solid var(--hk-color-vulcan);
    display: flex;
    flex-direction: column;
    align-items: center;
    &-input {
      min-height: 40px !important;
      font-weight: 600;
      text-align: left;
      border: 1px solid var(--hk-color-vulcan);
      border-right: 0;
      border-radius: 4px 0 0 4px;
      .input-wrapper {
        padding-left: 8px;
      }
      &-error {
        border: 1px solid var(--hk-color-uhura);
        border-right: 0;
      }
    }
    ion-button {
      height: 42px;
      --border-radius: 0 4px 4px 0;
      margin-left: 0px;
    }
    &-body {
      display: flex;
      align-items: center;
      width: 100%;
    }
    &-error {
      position: absolute;
      top: 70px;
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      color: var(--hk-color-uhura);
    }
  }
  &-auto-renew {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--hk-color-voyager);
    padding: 24px 0;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid var(--hk-color-vulcan);
    ion-toggle {
      padding-right: 0;
    }
  }
  &-total {
    &-sub-container {
      padding-top: 24px;
      padding-bottom: 4px;
      margin-bottom: 24px;
      border-bottom: 1px solid var(--hk-color-vulcan);
    }
    &-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      &-name {
        color: var(--hk-color-voyager);
        margin: 0;
        font-size: 16px;
        font-weight: 600;
      }
      &-price {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}

.hk-billing-summary-due {
  margin-top: 16px;
  margin-bottom: 36px;
  width: 100%;
  border: 1px solid var(--hk-color-vulcan);
  border-radius: 10px;
  padding: 24px 16px;
  &-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-name {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
    }
    &-price {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.hk-billing-summary-button {
  width: 100%;
}

// Toggle Animation
.hk-billing-summary-animation {
  height: calc(100% - 100px);
}
.toggle-enter.left {
  opacity: 0;
  transform: translateX(-100%);
}
.toggle-enter-active.left {
  opacity: 1;
  transform: translateX(0%);
}
.toggle-exit.left {
  opacity: 1;
  transform: translateX(0%);
}
.toggle-exit-active.left {
  opacity: 0;
  transform: translateX(-100%);
}
.toggle-enter-active,
.toggle-exit-active.left {
  transition:
    opacity 300ms,
    transform 300ms;
}

.toggle-enter.right {
  opacity: 0;
  transform: translateX(100%);
}
.toggle-enter-active.right {
  opacity: 1;
  transform: translateX(0%);
}
.toggle-exit.right {
  opacity: 1;
  transform: translateX(0%);
}
.toggle-exit-active.right {
  opacity: 0;
  transform: translateX(100%);
}
.toggle-enter-active,
.toggle-exit-active.right {
  transition:
    opacity 300ms,
    transform 300ms;
}

// Signup Address Styles
.hk-billing-summary-address-view {
  background-color: var(--hk-color-oxygen);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 24px;
  padding-top: 0;
  text-align: center;
  h2 {
    font-weight: bold;
    font-size: 24px;
    color: var(--hk-color-scotty);
    margin-top: 0;
  }
  p {
    padding-bottom: 16px;
    font-weight: 600;
    font-size: 16px;
    color: var(--hk-color-voyager);
  }
}
