/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500;600;700&display=swap');

@function tint($color, $percentage) {
  @return mix(#fff, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(#000, $color, $percentage);
}

/** Ionic CSS Variables **/
:root {
  --ion-font-family: 'Open Sans', sans-serif;
  font-family: 'Open Sans', sans-serif !important;

  --ion-background-color: #f0f0f0; /*#F7F8FA*/
  --ion-background-color-rgb: 255, 255, 255;

  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0;

  --ion-toolbar-background: transparent;
  /*--ion-toolbar-background-rgb: 53, 58, 775;*/
  /*--ion-toolbar-border-color: var(--ion-color-grey);*/
  /*--ion-item-background: #000000;*/

  --ion-card-background: #fff;
  --ion-card-border-radius: 10px;

  /** primary **/
  --ion-color-primary: #00d2b7;
  --ion-color-primary-rgb: 0, 210, 183;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #00b9a1;
  --ion-color-primary-tint: #1ad7be;

  /** secondary **/
  --ion-color-secondary: #04abff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 246, 197, 99;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d8ad57;
  --ion-color-warning-tint: #f7cb73;

  /** danger **/
  --ion-color-danger: #f88e8e;
  --ion-color-danger-rgb: 248, 142, 142;
  --ion-color-danger-contrast: #1d202b;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #da7d7d;
  --ion-color-danger-tint: #f99999;

  /** dark **/
  --ion-color-dark: #1d202b;
  --ion-color-dark-rgb: 27, 28, 31;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #18191b;
  --ion-color-dark-tint: #323335;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** Custom colors **/

  /** grey **/
  --ion-color-grey: #353a4d;
  --ion-color-grey-rgb: 53, 58, 77;
  --ion-color-grey-contrast: #ffffff;
  --ion-color-grey-contrast-rgb: 255, 255, 255;
  --ion-color-grey-shade: #2f3344;
  --ion-color-grey-tint: #494e5f;

  /** white **/
  --hk-color-oxygen: #ffffff;
  --hk-color-oxygen-rgb: 255, 255, 255;
  --hk-color-oxygen-contrast: #000000;
  --hk-color-oxygen-contrast-rgb: 0, 0, 0;
  --hk-color-oxygen-shade: #e0e0e0;
  --hk-color-oxygen-tint: #ffffff;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --hk-color-oxygen: #ffffff;
  --hk-color-enterprise: #00d2b7;
  --hk-color-scotty: #353a4d;
  --hk-color-discovery: #1d202b;
  --hk-color-romulan: #1b1c1f;
  --hk-color-klingon: #4a4a4a;
  --hk-color-vulcan: #bdbdbd;
  --hk-color-sulu: #00eddc;
  --hk-color-uhura: #f88e8e;
  --hk-color-kirk: #f6c563;
  --hk-color-spock: #00acff;
  --hk-color-mccoy: #00d8f1;
  --hk-color-bajor: #00d8f1;
  --hk-color-tendi: #c5dc64;
  --hk-color-voyager: #808080;
  --hk-color-picard: #680000;
  --hk-color-riker: #eb5a46;
}

/*@media (prefers-color-scheme: dark) {*/
/*  !**/
/*   * Dark Colors*/
/*   * -------------------------------------------*/
/*   *!*/

/*  body {*/
/*    --ion-color-primary: #428cff;*/
/*    --ion-color-primary-rgb: 66,140,255;*/
/*    --ion-color-primary-contrast: #ffffff;*/
/*    --ion-color-primary-contrast-rgb: 255,255,255;*/
/*    --ion-color-primary-shade: #3a7be0;*/
/*    --ion-color-primary-tint: #5598ff;*/

/*    --ion-color-secondary: #50c8ff;*/
/*    --ion-color-secondary-rgb: 80,200,255;*/
/*    --ion-color-secondary-contrast: #ffffff;*/
/*    --ion-color-secondary-contrast-rgb: 255,255,255;*/
/*    --ion-color-secondary-shade: #46b0e0;*/
/*    --ion-color-secondary-tint: #62ceff;*/

/*    --ion-color-tertiary: #6a64ff;*/
/*    --ion-color-tertiary-rgb: 106,100,255;*/
/*    --ion-color-tertiary-contrast: #ffffff;*/
/*    --ion-color-tertiary-contrast-rgb: 255,255,255;*/
/*    --ion-color-tertiary-shade: #5d58e0;*/
/*    --ion-color-tertiary-tint: #7974ff;*/

/*    --ion-color-success: #2fdf75;*/
/*    --ion-color-success-rgb: 47,223,117;*/
/*    --ion-color-success-contrast: #000000;*/
/*    --ion-color-success-contrast-rgb: 0,0,0;*/
/*    --ion-color-success-shade: #29c467;*/
/*    --ion-color-success-tint: #44e283;*/

/*    --ion-color-warning: #ffd534;*/
/*    --ion-color-warning-rgb: 255,213,52;*/
/*    --ion-color-warning-contrast: #000000;*/
/*    --ion-color-warning-contrast-rgb: 0,0,0;*/
/*    --ion-color-warning-shade: #e0bb2e;*/
/*    --ion-color-warning-tint: #ffd948;*/

/*    --ion-color-danger: #ff4961;*/
/*    --ion-color-danger-rgb: 255,73,97;*/
/*    --ion-color-danger-contrast: #ffffff;*/
/*    --ion-color-danger-contrast-rgb: 255,255,255;*/
/*    --ion-color-danger-shade: #e04055;*/
/*    --ion-color-danger-tint: #ff5b71;*/

/*    --ion-color-dark: #f4f5f8;*/
/*    --ion-color-dark-rgb: 244,245,248;*/
/*    --ion-color-dark-contrast: #000000;*/
/*    --ion-color-dark-contrast-rgb: 0,0,0;*/
/*    --ion-color-dark-shade: #d7d8da;*/
/*    --ion-color-dark-tint: #f5f6f9;*/

/*    --ion-color-medium: #989aa2;*/
/*    --ion-color-medium-rgb: 152,154,162;*/
/*    --ion-color-medium-contrast: #000000;*/
/*    --ion-color-medium-contrast-rgb: 0,0,0;*/
/*    --ion-color-medium-shade: #86888f;*/
/*    --ion-color-medium-tint: #a2a4ab;*/

/*    --ion-color-light: #222428;*/
/*    --ion-color-light-rgb: 34,36,40;*/
/*    --ion-color-light-contrast: #ffffff;*/
/*    --ion-color-light-contrast-rgb: 255,255,255;*/
/*    --ion-color-light-shade: #1e2023;*/
/*    --ion-color-light-tint: #383a3e;*/
/*  }*/

/*  !**/
/*   * iOS Dark Theme*/
/*   * -------------------------------------------*/
/*   *!*/

/*  .ios body {*/
/*    --ion-background-color: #000000;*/
/*    --ion-background-color-rgb: 0,0,0;*/

/*    --ion-text-color: #ffffff;*/
/*    --ion-text-color-rgb: 255,255,255;*/

/*--ion-color-step-50: #0d0d0d;*/
/*--ion-color-step-100: #1a1a1a;*/
/*--ion-color-step-150: #262626;*/
/*--ion-color-step-200: #333333;*/
/*--ion-color-step-250: #404040;*/
/*--ion-color-step-300: #4d4d4d;*/
/*--ion-color-step-350: #595959;*/
/*--ion-color-step-400: #666666;*/
/*--ion-color-step-450: #737373;*/
/*--ion-color-step-500: #808080;*/
/*--ion-color-step-550: #8c8c8c;*/
/*--ion-color-step-600: #999999;*/
/*--ion-color-step-650: #a6a6a6;*/
/*--ion-color-step-700: #b3b3b3;*/
/*--ion-color-step-750: #bfbfbf;*/
/*--ion-color-step-800: #cccccc;*/
/*--ion-color-step-850: #d9d9d9;*/
/*--ion-color-step-900: #e6e6e6;*/
/*--ion-color-step-950: #f2f2f2;*/

/*    --ion-toolbar-background: #0d0d0d;*/

/*    --ion-item-background: #000000;*/

/*    --ion-card-background: #1c1c1d;*/
/*  }*/

/*  .ios ion-modal {*/
/*    --ion-background-color: var(--ion-color-step-100);*/
/*    --ion-toolbar-background: var(--ion-color-step-150);*/
/*    --ion-toolbar-border-color: var(--ion-color-step-250);*/
/*  }*/

/*  !**/
/*   * Material Design Dark Theme*/
/*   * -------------------------------------------*/
/*   *!*/

/*  .md body {*/
/*    --ion-background-color: #121212;*/
/*    --ion-background-color-rgb: 18,18,18;*/

/*    --ion-text-color: #ffffff;*/
/*    --ion-text-color-rgb: 255,255,255;*/

/*    --ion-border-color: #222222;*/

/*    --ion-color-step-50: #1e1e1e;*/
/*    --ion-color-step-100: #2a2a2a;*/
/*    --ion-color-step-150: #363636;*/
/*    --ion-color-step-200: #414141;*/
/*    --ion-color-step-250: #4d4d4d;*/
/*    --ion-color-step-300: #595959;*/
/*    --ion-color-step-350: #656565;*/
/*    --ion-color-step-400: #717171;*/
/*    --ion-color-step-450: #7d7d7d;*/
/*    --ion-color-step-500: #898989;*/
/*    --ion-color-step-550: #949494;*/
/*    --ion-color-step-600: #a0a0a0;*/
/*    --ion-color-step-650: #acacac;*/
/*    --ion-color-step-700: #b8b8b8;*/
/*    --ion-color-step-750: #c4c4c4;*/
/*    --ion-color-step-800: #d0d0d0;*/
/*    --ion-color-step-850: #dbdbdb;*/
/*    --ion-color-step-900: #e7e7e7;*/
/*    --ion-color-step-950: #f3f3f3;*/

/*    --ion-item-background: #1e1e1e;*/

/*    --ion-toolbar-background: #1f1f1f;*/

/*    --ion-tab-bar-background: #1f1f1f;*/

/*    --ion-card-background: #1e1e1e;*/
/*  }*/
/*}*/

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
  --ion-color-base-rgb: var(--ion-color-grey-rgb);
  --ion-color-contrast: var(--ion-color-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-shade);
  --ion-color-tint: var(--ion-color-grey-tint);
}

.ion-color-white {
  --ion-color-base: var(--hk-color-oxygen);
  --ion-color-base-rgb: var(--hk-color-oxygen-rgb);
  --ion-color-contrast: var(--hk-color-oxygen-contrast);
  --ion-color-contrast-rgb: var(--hk-color-oxygen-contrast-rgb);
  --ion-color-shade: var(--hk-color-oxygen-shade);
  --ion-color-tint: var(--hk-color-oxygen-tint);
}

.hk-grey-background {
  background-color: var(--ion-color-grey);
}

.hk-bold {
  font-weight: bold;
}

.hk-refresher {
  .refresher-pulling-text,
  .refresher-refreshing-text {
    color: var(--ion-color-medium);
  }
  .refresher-pulling-icon {
    color: var(--ion-color-medium);
    font-size: 17px;
  }
  .refresher-refreshing-icon {
    display: none;
  }
}

.vertical-align-center {
  height: 100% !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
}

.hk-error {
  font-size: 16px;
  font-weight: normal;
  color: #ff6b69;
  text-align: center;
}

.hk-fab-button {
  --box-shadow: none !important;
  &-margin-top {
    margin-top: 28px;
  }
}

.hk-loading-list-spinner.vertical-align-center {
  // margin: 40px;
  ion-spinner {
    width: 80px;
    height: 80px;
  }
}

.hk-loading-inline-spinner {
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  flex-grow: 1;
  background-color: #999;
}

// Ionic Styles
ion-header {
  z-index: 1000;
}
ion-select {
  &::part(container) {
    padding-left: 16px;
    justify-content: space-between;
    width: 100%;
  }
  &::part(icon) {
    position: absolute;
    right: 16px;
  }
}

// Toggle Styles
ion-toggle::part(track) {
  min-height: 24px !important;
  min-width: 46px !important;
  background: var(--hk-color-vulcan);
}
ion-toggle.toggle-checked::part(track) {
  background: var(--hk-color-enterprise);
}
ion-toggle::part(handle) {
  background: var(--hk-color-oxygen);
}

// Accordion Icon Animation Overrides
.ion-accordion-toggle-icon {
  transform: rotate(-90deg);
}
ion-accordion.accordion-expanding > [slot='header'] .ion-accordion-toggle-icon,
ion-accordion.accordion-expanded > [slot='header'] .ion-accordion-toggle-icon {
  transform: rotate(0deg);
}
.accordion-animated {
  &::part(content) {
    transition: max-height 500ms ease-in-out;
  }
}

ion-card {
  border-radius: 10px;
  box-shadow: none;
}

ion-button.ion-color-white {
  border-radius: 10px;
  color: var(--ion-color-dark);
  &::part(native) {
    border: 1px solid var(--ion-color-dark);
  }
}

ion-button.button-disabled {
  --ion-color-base: var(--hk-color-vulcan) !important;
  --background: var(--hk-color-vulcan) !important;
  &::part(native) {
    color: var(--hk-color-voyager) !important;
  }
}

ion-breadcrumb {
  --color: var(--hk-color-oxygen);
  --color-active: var(--ion-color-primary);
  --color-hover: var(--ion-color-primary);
  font-size: 16px;
  &::part(native) {
    padding: 0px;
  }
  &::part(separator) {
    margin: 4px;
  }
}

ion-popover {
  --background: var(--hk-color-discovery);
  --backdrop-opacity: 0.2;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --color: white;
  --width: 150px;
}

ion-popover ion-list {
  color: var(--hk-color-oxygen);
  background: var(--hk-color-discovery) !important;
  &:hover {
    cursor: pointer;
    --background: var(--hk-color-scotty);
  }
}

ion-popover ion-content {
  --color: var(--hk-color-oxygen);
  --background: var(--hk-color-discovery);
  &:hover {
    cursor: pointer;
    --background: var(--hk-color-scotty);
  }
}

ion-modal {
  &::part(content) {
    border-radius: 10px;
  }
}

ion-alert {
  .alert-button {
    color: var(--hk-color-romulan) !important;
  }
}

// Picker Styles
.hk-picker {
  .picker-toolbar {
    height: 50px;
  }
  .picker-button {
    color: var(--hk-color-spock);
    font-weight: 600;
  }
}

.alert-radio-label {
  color: var(--ion-color-dark) !important;
}
.item-has-focus .label-floating {
  transform: scale(1) !important;
}
.item-has-value .label-floating {
  transform: scale(1) !important;
}

// Modal Styles (Desktop)
@media screen and (min-width: 1024px) {
  .hk-appointment-wizard-modal {
    --max-width: 1200px;
    --min-width: 1024px;
    --width: 80%;
    --height: 788px !important;
    --border-radius: 10px;
  }
  .hk-desktop-account-edit-modal {
    --border-radius: 10px;
    --min-width: 900px;
  }
  .hk-appointment-details-modal {
    --border-radius: 10px;
    --min-width: 900px;
  }
  .hk-digital-inventory-add-product-modal {
    --height: 740px;
  }
  .hk-catalog-task-modal {
    --height: 740px;
  }
  .hk-change-account-modal {
    --max-height: 422px;
    --max-width: 600px;
  }
  .hk-cancel-subscription-modal {
    --height: 740px;
  }
  .hk-delete-account-modal {
    --height: 740px;
  }
  .hk-product-type-toggles-modal {
    --height: 740px;
  }
  .hk-digital-new-contacts-modal {
    --height: 670px;
  }
  .hk-digital-edit-contacts-modal {
    --height: 670px;
  }
  .hk-zipcode-modal {
    --height: 544px;
  }
  .hk-cancel-appointment-modal {
    --height: 348px;
  }
  .hk-desktop-digital-documents-edit-modal {
    --max-height: 600px;
    --width: 800px;
  }
  .hk-digital-documents-add-modal {
    --max-width: 600px;
    --max-height: 680px;
  }
}

.hk-mobile-web-redirect-modal {
  --height: 200px;
}

.hk-plan-addon-modal {
  --height: 564px;
}

.hk-desktop-addon-modal {
  --height: 604px;
}

.hk-terms-and-conditions-modal {
  --height: 432px;
  --max-width: 400px;
  --width: 90%;
}

.hk-desktop-discard-account-changes-modal {
  --max-height: 286px;
  --max-width: 600px;
  --backdrop-opacity: 0.2 !important;
}

/* On Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: var(--hk-color-romulan);
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
/* For Firefox and IE */
.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflw-style: none;
}

// Nav Link Transition Animation
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 400ms;
}

// Slide Left
.fade-enter.left {
  ion-content {
    opacity: 0;
    transform: translateX(100%);
  }
}
.fade-enter-active.left {
  ion-content {
    opacity: 1;
    transform: translateX(0%);
  }
}
.fade-exit.left {
  ion-content {
    opacity: 1;
    transform: translateX(0%);
  }
}
.fade-exit-active.left {
  ion-content {
    opacity: 0;
    transform: translateX(0%);
  }
}
.fade-enter-active,
.fade-exit-active.left {
  ion-content {
    transition:
      opacity 200ms,
      transform 300ms;
  }
}

// Slide Right
.fade-enter.right {
  ion-content {
    opacity: 0;
    transform: translateX(-100%);
  }
}
.fade-enter-active.right {
  ion-content {
    opacity: 1;
    transform: translateX(0%);
  }
}
.fade-exit.right {
  ion-content {
    opacity: 1;
    transform: translateX(0%);
  }
}
.fade-exit-active.right {
  ion-content {
    opacity: 0;
    transform: translateX(0%);
  }
}
.fade-enter-active,
.fade-exit-active.right {
  ion-content {
    transition:
      opacity 200ms,
      transform 300ms;
  }
}

// Mobile Page Slide
.slide-enter {
  .ion-page {
    opacity: 0;
    transform: translateX(100%);
  }
}
.slide-enter-active {
  .ion-page {
    opacity: 1;
    transform: translateX(0%);
  }
}
.slide-exit {
  .ion-page {
    opacity: 1;
    transform: translateX(0%);
  }
}
.slide-exit-active {
  .ion-page {
    opacity: 0;
    transform: translateX(0%);
  }
}
.slide-enter-active,
.slide-exit-active {
  .ion-page {
    transition:
      opacity 200ms,
      transform 300ms;
  }
}

// Swiper React Styles
.swiper-pagination {
  position: relative;
  bottom: -4px !important;
}
.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  border: solid 1px #000 !important;
  background: #e7e7e7 !important;
}
.swiper-pagination-bullet-active {
  background: #00d2b7 !important;
  border: solid 1px #00d2b7 !important;
}
.swiper-button-disabled {
  display: none;
}
.swiper-button-prev {
  width: 32px;
  height: 100px;
  top: 220px;
  left: calc(50% - 180px);
  border-radius: 10px;
  background-color: #00acff;
  background-image: url(../assets/icons/swiper-prev-arrow.svg) !important;
  background-repeat: no-repeat;
  background-position: center;
  &::after {
    display: none;
  }
}
.swiper-button-next {
  width: 32px;
  height: 100px;
  top: 220px;
  right: calc(50% - 180px);
  border-radius: 10px;
  background-color: #00acff;
  background-image: url(../assets/icons/swiper-next-arrow.svg) !important;
  background-repeat: no-repeat;
  background-position: center;
  &::after {
    display: none;
  }
}
