// Desktop Styles
.hk-desktop-appointment-details-page {
  top: 118px !important;
}
.hk-desktop-appointment-details {
  max-width: 1440px;
  margin: auto;
  margin-top: 32px;
  padding: 0 32px;
  &-header {
    background-color: #fff;
    border-radius: 10px;
    &-description {
      font-size: 14px;
      line-height: 20px;
      color: var(--ion-color-grey);
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &-tasks .hk-list-item {
    border-radius: 6px;
  }
}

// Mobile Styles
.hk-appointment-details {
  &-header {
    background-color: #fff;
    border-radius: 10px;
    &-description {
      font-size: 14px;
      line-height: 20px;
      color: var(--ion-color-grey);
      margin-top: 0;
    }
  }
  &-tasks .hk-list-item {
    border-radius: 6px;
  }
}
