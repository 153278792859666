// Desktop Styles
.hk-desktop-initial-signin-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 196px);
}

.hk-desktop-initial-signin-title {
  margin-top: 80px;
  font-size: 24px;
  font-weight: bold;
  color: var(--hk-color-scotty);
}

.hk-desktop-initial-signin-description {
  font-size: 16px;
  font-weight: 600;
  color: var(--hk-color-voyager);
  width: 348px;
  text-align: center;
}

.hk-desktop-initial-signin-search-container {
  margin-top: 40px;
  margin-bottom: 106px;
  max-width: 740px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 24px;
  background-color: var(--hk-color-oxygen);
  border-radius: 10px;
  border: 1px solid var(--hk-color-vulcan);
}

.hk-desktop-initial-signin-password-title {
  font-size: 18px;
  font-weight: bold;
  color: var(--hk-color-romulan);
  margin-top: 24px;
  margin-bottom: 16px;
}

.hk-desktop-initial-signin-password-description {
  font-size: 16px;
  color: var(--hk-color-klingon);
  margin: 0;
}

.hk-desktop-initial-signin-password-email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  div:nth-child(1) {
    margin-top: 24px;
  }
}

.hk-desktop-initial-signin-password-email {
  max-width: 600px;
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid var(--hk-color-klingon);
  font-weight: bold;
  cursor: pointer;
  &-selected {
    background-color: var(--hk-color-enterprise);
    transition: background-color 100ms ease-in-out 0s;
  }
}

.hk-desktop-initial-signin-password-button {
  margin-top: 40px;
}

// Desktop Sent Email Styles
.hk-desktop-initial-signin-sent-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 196px);
}
.hk-desktop-initial-signin-sent-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--hk-color-oxygen);
  margin-top: 80px;
  width: 100%;
  max-width: 740px;
  padding: 14px 70px 8px 70px;
  border: 1px solid var(--hk-color-vulcan);
  border-radius: 10px;
}

.hk-desktop-initial-signin-sent-title {
  font-size: 18px;
  font-weight: bold;
  color: var(--hk-color-scotty);
  margin-bottom: 0;
}

.hk-desktop-initial-signin-sent-description {
  text-align: center;
  max-width: 600px;
  margin-bottom: 24px;
}

// Mobile Styles
.hk-initial-signin-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 196px);
  background-color: var(--hk-color-oxygen);
}

.hk-initial-signin-text-container {
  height: 100%;
  width: 100%;
  padding: 32px 24px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
}

.hk-initial-signin-title {
  text-align: center;
  width: 160px;
  margin: 0;
  margin-bottom: -8px;
  font-size: 24px;
  font-weight: bold;
  color: var(--hk-color-scotty);
}

.hk-initial-signin-description {
  font-size: 16px;
  font-weight: 600;
  color: var(--hk-color-voyager);
  width: 348px;
  text-align: center;
}

.hk-initial-signin-search-container {
  max-width: 740px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 24px;
  background-color: var(--hk-color-oxygen);
}

.hk-initial-signin-password-title {
  font-size: 18px;
  font-weight: bold;
  color: var(--hk-color-romulan);
  margin-top: 24px;
  margin-bottom: 16px;
}

.hk-initial-signin-password-description {
  font-size: 16px;
  color: var(--hk-color-klingon);
  margin: 0;
  text-align: center;
}

.hk-initial-signin-password-email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  div:nth-child(1) {
    margin-top: 24px;
  }
}

.hk-initial-signin-password-email {
  max-width: 600px;
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid var(--hk-color-klingon);
  font-weight: bold;
  cursor: pointer;
  &-selected {
    background-color: var(--hk-color-enterprise);
    transition: background-color 100ms ease-in-out 0s;
  }
}

.hk-initial-signin-password-help {
  text-decoration: none;
  color: var(--hk-color-spock);
  font-weight: bold;
}

.hk-initial-signin-password-button {
  margin-top: 40px;
}

// Mobile Sent Email Styles
.hk-initial-signin-sent-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 196px);
}
.hk-initial-signin-sent-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--hk-color-oxygen);
  margin-top: 32px;
  width: 100%;
  max-width: 740px;
  max-height: 214px;
  padding: 16px 24px 8px 24px;
  border-top: 1px solid var(--hk-color-vulcan);
}

.hk-initial-signin-sent-title {
  font-size: 24px;
  font-weight: bold;
  color: var(--hk-color-scotty);
  margin-top: 32px;
}

.hk-initial-signin-sent-subtitle {
  font-size: 16px;
  color: var(--hk-color-voyager);
  text-align: center;
  padding: 0 24px;
}

.hk-initial-signin-sent-description {
  text-align: center;
  max-width: 600px;
  margin-bottom: 24px;
}
