.hk-calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  padding: 24px 40px;
  &-prev {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 60px;
    font-size: 16px;
    color: var(--ion-color-grey);
    &:hover {
      cursor: pointer;
    }
    ion-icon {
      font-size: 20px;
      margin-right: 6px;
    }
  }
  &-next {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 60px;
    font-size: 16px;
    color: var(--ion-color-grey);
    &:hover {
      cursor: pointer;
    }
    ion-icon {
      font-size: 20px;
      margin-left: 6px;
    }
  }
  &-current {
    font-size: 20px;
  }
}

.hk-calendar-week {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 4px;
  padding: 0;
  & > label {
    width: 98px;
    margin-right: 8px;
    font-family: 'Open Sans';
    font-size: 14px;
    text-align: right;
    color: var(--ion-color-grey);
    &:last-child {
      margin-right: 0;
    }
  }
}

.hk-calendar-days {
  &-row {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 8px;
    padding: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &-column {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 98px;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
  .cell {
    position: relative;
    height: 84px;
    width: 100px;
    background-color: var(--ion-color-step-950);
    border-radius: 10px;
    cursor: pointer;
    font-family: 'Open Sans';
    font-size: 25px;
    font-weight: bold;
    transition: 100ms ease;
    .number {
      position: absolute;
      font-size: 24px;
      line-height: 1;
      top: 12px;
      right: 10px;
      font-weight: bold;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  .selected {
    color: var(--hk-color-oxygen);
    background-color: var(--ion-color-primary) !important;
    &::before {
      background-color: #ffeaec;
      border-radius: 0px;
    }
  }
  .disabled {
    cursor: not-allowed;
    background-color: var(--ion-color-step-950) !important;
    .number {
      color: var(--ion-color-step-750) !important;
    }
  }
}
