// Desktop Styles
.hk-desktop-checkout-page {
  top: 120px !important;
  background-color: var(--hk-color-oxygen);
}

.hk-desktop-checkout-page-header {
  background-color: var(--hk-color-discovery);
  ion-breadcrumbs {
    padding: 16px 32px;
    margin: auto;
    max-width: 1440px;
    font-size: 16px;
  }
  ion-breadcrumb {
    color: var(--hk-color-enterprise);
  }
  .breadcrumb-active {
    color: var(--hk-color-oxygen);
  }
}

.hk-desktop-checkout-page-container {
  overflow: auto;
  max-width: 1440px;
  width: 100%;
  padding: 32px;
  margin: auto;
  margin-top: 0;
  display: flex;
  align-items: start;
  justify-content: space-between;
  color: var(--hk-color-romulan);
}

// Credit Card Styles
.hk-desktop-checkout-card-section {
  width: 100%;
}
.hk-desktop-checkout-card-header {
  background-color: var(--hk-color-oxygen);
  border: 1px solid var(--hk-color-vulcan);
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 16px;
  h2 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }
  p {
    font-size: 16px;
    font-weight: 600;
    color: var(--hk-color-voyager);
    margin: 0;
    padding-top: 8px;
  }
}

.hk-desktop-checkout-card-container {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: start;
  padding: 24px;
  border-radius: 10px;
  border: 1px solid var(--hk-color-vulcan);
  .hk-desktop-checkout-card-icon {
    width: 108px;
    height: 60px;
    margin-bottom: 32px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid var(--hk-color-vulcan);
    font-size: 12px;
    font-weight: bold;
    ion-icon {
      font-size: 24px;
    }
  }

  .hk-saved-card {
    display: flex;
    justify-content: space-between;
    background-color: #d9f9f4;
    padding: 16px;
    width: 100%;
    border-radius: 10px;
    border: 2px solid var(--hk-color-enterprise);
    &-info-container {
      display: flex;
    }
    &-icon {
      ion-icon {
        height: 24px;
        width: 40px;
      }
      margin-right: 8px;
    }
    &-info {
      display: flex;
      flex-direction: column;
      &-card-number {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 4px;
      }
      &-expiration {
        font-weight: 14px;
        font-weight: 600;
        color: var(--hk-color-klingon);
        margin-bottom: 4px;
      }
      &-remove {
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        text-decoration: underline;
      }
    }
    &-check {
      font-size: 20px;
    }
    &-new {
      cursor: pointer;
      margin-top: 24px;
      height: 48px;
      width: 200px;
      border-radius: 10px;
      border: 1px solid var(--hk-color-klingon);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.hk-billing-checkout-form {
  padding: 24px;
}

.hk-billing-checkout-button {
  width: 100%;
  padding: 0 24px;
}

.hk-desktop-recurly-form-back-button {
  cursor: pointer;
  margin-right: 16px;
  height: 48px;
  width: 200px;
  border-radius: 10px;
  border: 1px solid var(--hk-color-klingon);
  background-color: var(--hk-color-oxygen);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}

// Summary Styles
.hk-desktop-checkout-summary-section {
  min-width: 390px;
  .hk-billing-summary-content {
    margin-top: 0;
    padding-top: 0;
  }
  .hk-billing-summary-subscription {
    background-color: var(--hk-color-oxygen);
  }
  .hk-billing-summary-due {
    background-color: var(--hk-color-oxygen);
  }
}
