@import 'src/theme/variables.scss';

//Desktop Styles
.hk-desktop-task {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  min-height: 100px;
  height: 100px;
  padding: 24px;
  margin-bottom: 8px;
  border-left: 8px solid var(--hk-color-vulcan);
  &:hover {
    cursor: pointer;
    border-top: 2px solid var(--ion-color-primary);
    border-right: 2px solid var(--ion-color-primary);
    border-bottom: 2px solid var(--ion-color-primary);
    border-radius: 10px;
    transition: 50ms ease;
  }

  &.level-1 {
    border-left: 8px solid var(--ion-color-warning);
  }
  &.level-2 {
    border-left: 8px solid var(--ion-color-danger);
  }
  &.finished {
    border-left: 8px solid var(--ion-color-primary);
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 90% !important;
    flex-grow: 1;
    margin: 0;
    &-name {
      margin-top: 0;
      color: var(--ion-color-dark);
      text-align: left;
      margin-bottom: 6px !important;
      font-size: 16px !important;
      font-weight: bold !important;
    }
    .hk-status-tag-list {
      display: flex;
      &-item {
        margin: 0 8px 0px 0;
      }
    }
    .hk-status-pill-incomplete {
      font-weight: bold;
      font-size: 10px;
      border-radius: 3px;
      padding: 3px 8px;
      width: 74px;
      color: var(--hk-color-oxygen);
      background-color: var(--hk-color-klingon);
    }
  }
  &-icon {
    font-size: 24px;
  }
}

//Mobile Styles
.hk-task {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  min-height: 80px;
  padding: 12px;
  margin-bottom: 8px;
  border-left: 4px solid var(--ion-color-medium);

  &.level-1 {
    border-left: 4px solid var(--ion-color-warning);
  }
  &.level-2 {
    border-left: 4px solid var(--ion-color-danger);
  }
  &.finished {
    border-left: 4px solid var(--ion-color-primary);
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 90% !important;
    flex-grow: 1;
    margin: 0;
    &-name {
      margin-top: 0;
      color: var(--ion-color-dark);
      text-align: left;
      margin-bottom: 4px !important;
      font-size: 14px !important;
      font-weight: bold !important;
      line-height: 19px;
    }
    &-status {
      display: flex;
      align-items: center;
      font-weight: bold;
      text-align: left;
      font-size: 14px;
      ion-icon {
        margin-right: 4px;
      }
      ion-text {
        line-height: 14px;
      }
    }
  }
  &-icon {
    flex-grow: 0;
    flex-basis: 10% !important;
    width: 32px !important;
    height: 32px !important;
  }
}

.hk-status-tag-list {
  display: flex;
  &-item {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 10px;
    border-radius: 3px;
    padding: 3px 8px;
    margin: 8px 8px 8px 0;
    &.level-0 {
      border: 1px solid var(--hk-color-enterprise);
      background-color: tint(#00d2b7, 85);
    }
    &.level-1 {
      border: 1px solid var(--hk-color-kirk);
      background-color: tint(#f6c563, 85);
    }
    &.level-2 {
      border: 1px solid var(--hk-color-uhura);
      background-color: tint(#f88e8e, 85);
    }
  }
}
