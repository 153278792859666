// Desktop Styles
.hk-desktop-task-card {
  cursor: pointer;
  width: 100%;
  padding: 16px;
  border: 1px solid var(--hk-color-vulcan);
  border-radius: 8px;
  background-color: var(--hk-color-oxygen);
  display: flex;
  &-thumbnail {
    min-height: 100px;
    min-width: 100px;
    margin-right: 16px;
    border-radius: 8px;
    &-img {
      border-radius: 8px;
      height: 100px;
      width: 100px;
      img {
        height: 100px;
        width: 100px;
        border-radius: 8px;
      }
    }
  }
  &-video {
    display: flex;
    align-items: center;
    min-height: 80px;
    min-width: 80px;
    margin-right: 16px;
  }

  &-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 16px;
    &-product {
      width: fit-content;
      border-radius: 16px;
      padding: 4px 8px;
      background-color: rgba(0, 210, 183, 0.2);
      color: #00685a;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 4px;
    }
    &-title {
      font-size: 14px;
      font-weight: 600;
      color: var(--hk-color-klingon);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &-description {
      font-size: 18px;
      font-weight: 600;
      color: var(--hk-color-klingon);
      margin: 4px 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &-time {
      display: flex;
      align-items: center;
      position: absolute;
      top: 52px;
      right: 80px;
      font-size: 18px;
      font-weight: bold;
      color: var(--hk-color-voyager);
      ion-icon {
        margin-right: 8px;
      }
    }
    &-completed {
      position: absolute;
      top: 50px;
      right: 16px;
      font-size: 24px;
      &-small {
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: 16px;
      }
    }
    &-locked {
      position: absolute;
      top: 50px;
      right: 16px;
      font-size: 24px;
    }
  }
}

.completed {
  border: 1px solid var(--hk-color-enterprise);
}

// Mobile Styles
.hk-task-card {
  width: 100%;
  padding: 16px;
  border: 1px solid var(--hk-color-vulcan);
  border-radius: 8px;
  background-color: var(--hk-color-oxygen);
  display: flex;
  &-thumbnail {
    min-height: 100px;
    min-width: 100px;
    margin-right: 16px;
    border-radius: 8px;
    &-img {
      border-radius: 8px;
      height: 100px;
      width: 100px;
      img {
        height: 100px;
        width: 100px;
        border-radius: 8px;
      }
    }
  }
  &-video {
    min-height: 80px;
    min-width: 80px;
    margin-right: 16px;
  }

  &-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 16px;
    &-product {
      width: fit-content;
      border-radius: 16px;
      padding: 4px 8px;
      background-color: rgba(0, 210, 183, 0.2);
      color: #00685a;
      font-size: 12px;
      font-weight: bold;
    }
    &-title {
      font-size: 14px;
      font-weight: 600;
      color: var(--hk-color-klingon);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &-description {
      font-size: 14px;
      font-weight: 600;
      color: var(--hk-color-klingon);
      margin: 4px 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &-time {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: bold;
      color: var(--hk-color-voyager);
      ion-icon {
        margin-right: 8px;
      }
    }
    &-completed {
      position: absolute;
      top: 16px;
      right: 32px;
      font-size: 24px;
      &-small {
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: 16px;
      }
    }
    &-locked {
      position: absolute;
      top: 16px;
      right: 32px;
      font-size: 24px;
    }
  }
}

.completed {
  border: 1px solid var(--hk-color-enterprise);
}
