// Desktop Styles
.hk-desktop-account-edit {
  .label-stacked {
    margin-bottom: 0;
  }
  .hk-desktop-account-edit-title {
    font-size: 18px !important;
    font-weight: bold;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--hk-color-vulcan);
    ion-icon {
      margin-right: 8px;
      font-size: 24px;
      vertical-align: middle;
    }
  }
  &-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &-item {
      --inner-padding-end: 0;
      width: 410px;
    }
  }

  &-field {
    border-bottom: 1px solid var(--hk-color-vulcan);
    &.ion-color-danger {
      border-bottom: 1px solid var(--ion-color-danger);
    }
  }
}

.hk-desktop-account-edit-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  ion-button {
    --box-shadow: none;
    width: 410px;
    text-transform: none;
  }
}

// Mobile Styles
.hk-account-edit {
  .label-stacked {
    margin-bottom: 0;
  }
  .hk-account-edit-title {
    font-size: 18px !important;
    font-weight: bold;
    ion-icon {
      margin-right: 8px;
      font-size: 24px;
      vertical-align: middle;
    }
  }

  &-field {
    border-bottom: 1px solid var(--ion-color-medium);
    &.ion-color-danger {
      border-bottom: 1px solid var(--ion-color-danger);
    }
  }
}

.hk-state-alert .alert-wrapper {
  background: #fff;
}

.hk-mobile-account-edit-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  ion-button {
    --box-shadow: none;
    text-transform: none;
    height: 48px;
    font-size: 16px;
  }
}
