.hk-delete-account-content {
  --background: var(--hk-color-discovery);
}

.hk-delete-account-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  color: var(--hk-color-oxygen);
  &-title {
    font-weight: bold;
    margin-top: 32px;
    padding-left: 24px;
  }
  &-subtitle {
    padding-left: 24px;
    font-size: 18px;
    font-weight: bold;
    color: var(--hk-color-uhura);
  }
  &-description {
    padding-left: 24px;
    margin-top: 8px;
    font-size: 16px;
    padding-right: 16px;
  }
}

.hk-delete-account-body-confirm {
  margin-top: 16px;
  padding: 24px;
  border-top: 1px solid var(--hk-color-scotty);
  border-bottom: 1px solid var(--hk-color-scotty);
  &-input {
    height: 58px;
    border-radius: 4px;
    font-size: 28px;
    color: var(--hk-color-klingon);
    background: var(--ion-background-color);
  }
  &-description {
    margin-bottom: 0;
    span {
      font-weight: 600;
      color: var(--hk-color-uhura);
    }
  }
}

.hk-delete-account-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  ion-button {
    --border-radius: 8px;
    text-transform: none;
    height: 50px;
    width: 100%;
    font-size: 16px;
    margin-left: 0;
    margin-bottom: 40px;
  }
}
.hk-mobile-delete-account-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  ion-button {
    --border-radius: 8px;
    text-transform: none;
    height: 50px;
    width: 100%;
    font-size: 16px;
    margin-left: 0;
    margin-bottom: 40px;
  }
}
