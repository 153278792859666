// Desktop Styles
.hk-desktop-plan-addon {
  --background: var(--hk-color-oxygen);
}

.hk-fab-button {
  padding-top: 10px;
}

.hk-desktop-plan-addon-content-header {
  background-color: var(--hk-color-oxygen);
  position: sticky;
  padding: 8px 24px;
  h4 {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
  }
}

.hk-desktop-plan-addon-content {
  padding: 24px;
  background-color: var(--hk-color-oxygen);
  height: 100%;
}

.hk-desktop-plan-addon-content-body {
  &-types {
    display: flex;
    padding-bottom: 8px;
    overflow-x: auto;
    .plan-type {
      padding: 8px 16px;
      font-size: 12px;
      font-weight: 600;
      color: var(--hk-color-klingon);
      white-space: nowrap;
    }
    .selected {
      background-color: var(--ion-background-color);
      border-radius: 10px;
    }
  }
}

.hk-desktop-plan-addon-toolbar {
  background-color: var(--hk-color-oxygen);
  width: 100%;
  ion-button {
    margin: auto;
    width: 342px;
    height: 48px;
  }
}

// Mobile Styles
.hk-plan-addon {
  --background: var(--hk-color-oxygen);
}

.hk-fab-button {
  padding-top: 10px;
}

.hk-plan-addon-content-header {
  padding: 8px 24px;
  background-color: var(--hk-color-oxygen);
  h4 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
  }
}

.hk-plan-addon-content {
  padding: 24px;
  padding-top: 0;
  background-color: var(--hk-color-oxygen);
  height: 100%;
}

.hk-plan-addon-content-body {
  &-types {
    display: flex;
    padding-bottom: 8px;
    overflow-x: auto;
    .plan-type {
      padding: 8px 16px;
      font-size: 12px;
      font-weight: 600;
      color: var(--hk-color-klingon);
      white-space: nowrap;
    }
    .selected {
      background-color: var(--ion-background-color);
      border-radius: 10px;
    }
  }
}

.plan-card {
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid var(--ion-background-color);
  padding: 16px;
  margin-bottom: 24px;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-title {
      font-size: 20px;
      font-weight: bold;
    }
    &-checked {
      font-size: 20px;
    }
    &-circle {
      height: 20px;
      width: 20px;
      background-color: var(--hk-color-oxygen);
      border-radius: 50%;
      border: 1px solid var(--ion-color-primary);
    }
  }
  &-body {
    text-align: left;
    &-title {
      font-size: 18px;
      font-weight: 600;
    }
    &-list {
      padding-inline-start: 16px;

      font-size: 14px;
    }
  }
}

.card-selected {
  border: 2px solid var(--hk-color-enterprise);
  background-color: #d4f4ef;
}

.hk-plan-addon-toolbar {
  background-color: var(--hk-color-oxygen);
}
