.upload-box {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='black' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='10' stroke-linecap='butt'/%3e%3c/svg%3e");
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 48px 0;
  cursor: pointer;
}

.upload-icon {
  font-size: 24px;
}
.upload-label {
  color: var(--hk-color-spock);
  cursor: pointer;
}

// File Upload Progress Styles
.file-upload-list {
  margin-top: 14px;
}
.file-upload-item {
  display: flex;
  flex-direction: column;
  background-color: var(--ion-background-color);
  padding: 16px 16px 22px 16px;
  border-radius: 10px;
  margin-bottom: 4px;
  font-size: 15px;
  font-weight: bold;
  color: var(--hk-color-klingon);
}
.file-info {
  display: flex;
  justify-content: start;
  align-items: center;
}
.file-icon {
  font-size: 24px;
  margin-right: 16px;
}
.file-complete {
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  text-align: left;
  color: #00a1ff;
  transform: translateY(4px);
}
.progress-bar {
  background-color: var(--hk-color-oxygen);
  width: 100%;
  height: 10px;
  border-radius: 100px;
  margin-top: 8px;
  position: relative;
}

.progress-bar-fill {
  background-color: var(--hk-color-spock);
  height: 10px;
  border-radius: 100px;
  transition: width 0.25s ease;
}
