// Desktop Navigation Styles
.hk-desktop-nav-header-container {
  background-color: var(--ion-color-grey);
  width: 100%;
  z-index: 1;
}
.hk-desktop-nav-header {
  display: flex;
  flex-direction: column;
  height: 120px;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 0 32px;
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    &-logo {
      width: 200px !important;
      img {
        max-height: 60px;
      }
    }
    &-nav {
      cursor: pointer;
      ion-icon {
        color: white;
        font-size: 16px;
        margin: 0 10px;
      }
      ion-item {
        --background: none;
        --border-style: none;
        --color: white;
        font-size: 16px;
        font-weight: bold;
        transition: color ease-in 0.1s;
        &:hover {
          color: var(--ion-color-primary);
          ion-icon {
            color: var(--ion-color-primary);
          }
        }
        &.selected {
          color: var(--ion-color-primary);
          ion-icon {
            color: var(--ion-color-primary);
          }
        }
        &::part(native) {
          padding: 0;
        }
      }
    }
    &-help {
      --border-radius: 8px;
      height: 38px;
      width: 68px;
      margin-left: 8px;
      font-size: 16px;
      font-weight: bold;
    }
  }
  &-bottom {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    &-image {
      position: relative;
      bottom: -8px;
      width: 192px !important;
      height: 82px;
      img {
        position: absolute;
        clip: rect(0px, 200px, 77px, 0px);
      }
    }
  }
}
.hk-desktop-nav-body {
  height: 100%;
}

.hk-desktop-digital-nav-header {
  height: 120px;
}
