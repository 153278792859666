// Desktop Styles
.hk-desktop-plans-header {
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 80px 24px 32px;
  margin: auto;
  text-align: center;
  h1 {
    color: var(--hk-color-scotty);
    font-size: 24px;
    font-weight: 700;
    margin: 0;
  }
  h4 {
    font-size: 16px;
    font-weight: 600;
    color: var(--hk-color-voyager);
    margin: auto;
    padding-top: 8px;
    max-width: 324px;
  }
  p {
    font-size: 14px;
    font-weight: 600;
    color: var(--hk-color-voyager);
    margin: 0;
    padding-top: 8px;
  }
}

.hk-desktop-plans-toggle-container {
  height: 48px;
  width: 326px;
  margin-top: 42px;
}
.hk-desktop-plans-toggle {
  height: 48px;
  background-color: var(--hk-color-oxygen);
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 2px;
  ion-chip {
    height: 44px;
    margin: 0;
    width: 326px;
    border-radius: 20px;
    justify-content: center;
    font-weight: bold;
    color: var(--hk-color-klingon);
    background-color: var(--hk-color-oxygen);
    &.selected {
      color: #fff;
      background-color: var(--hk-color-discovery);
    }
  }
}

.hk-desktop-plans-body {
}

.hk-desktop-plans {
  max-width: 1440px;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 80px;
}

.hk-signup-plan-card {
  width: 326px;
  z-index: 10;
  position: relative;
  .hk-plan-card-last-plan-header {
    position: absolute;
    top: -46px;
    height: 52px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 210, 183, 0.15);
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px 8px 0 0;
  }

  .hk-plan-card {
    width: 326px;
    z-index: 10;
    position: relative;
  }

  .hk-promo-banner {
    display: flex;
    justify-content: center;
    padding: 16px;
    align-items: center;
    min-height: 10px;
    width: 326px;
    border-radius: 10px 10px 0 0;
    background-color: var(--hk-color-enterprise);
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: var(--hk-color-oxygen);
  }
  .no-promo {
    padding: 0;
  }

  .hk-subscription-plan {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0 0 10px 10px;
    width: 100%;
    height: 490px;
    background-color: var(--hk-color-romulan);
    color: var(--hk-color-vulcan);
    padding: 16px;
    text-align: center;
    &-title {
      background: var(--hk-color-oxygen);
      color: var(--hk-color-scotty);
      h6 {
        margin: 0;
        font-size: 15px;
        font-weight: bold;
        text-align: center;
        padding: 6px 0;
      }
    }
    &-cost {
      margin-top: 16px;
      color: var(--hk-color-oxygen);
      font-size: 20px;
      font-weight: bold;
      &-amount {
        font-size: 36px;
        &__label {
          font-size: 20px;
        }
      }
    }
    hr {
      border-bottom: 1px solid var(--hk-color-enterprise);
      width: 180px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
    &-description {
      margin-top: 8px;
      color: var(--hk-color-oxygen);
      font-size: 16px;
      font-weight: bold;
    }
    &-list {
      margin-top: 16px;
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      ul {
        padding-inline-start: 24px;
      }
    }
    &-add-ons {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-top: 16px;
      color: var(--hk-color-enterprise);
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      ion-icon {
        margin-left: 8px;
      }
    }
  }
}

// Full Service Sign Up Card
.hk-full-signup-plan-card {
  width: 326px;
  z-index: 10;
  position: relative;
  .hk-plan-card-last-plan-header {
    position: absolute;
    top: -46px;
    height: 52px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 210, 183, 0.15);
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px 8px 0 0;
  }

  .hk-plan-card {
    width: 326px;
    z-index: 10;
    position: relative;
  }

  .hk-promo-banner {
    display: flex;
    justify-content: center;
    padding: 16px;
    align-items: center;
    min-height: 10px;
    width: 326px;
    border-radius: 10px 10px 0 0;
    background-color: var(--hk-color-spock);
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: var(--hk-color-oxygen);
  }
  .no-promo {
    padding: 0;
  }

  .hk-subscription-plan {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0 0 10px 10px;
    width: 100%;
    height: 490px;
    background-color: var(--hk-color-romulan);
    color: var(--hk-color-vulcan);
    padding: 16px;
    text-align: center;
    &-title {
      background: var(--hk-color-oxygen);
      color: var(--hk-color-scotty);
      h6 {
        margin: 0;
        font-size: 15px;
        font-weight: bold;
        text-align: center;
        padding: 6px 0;
      }
    }
    &-cost {
      margin-top: 16px;
      color: var(--hk-color-oxygen);
      font-size: 20px;
      font-weight: bold;
      &-amount {
        font-size: 36px;
        &__label {
          font-size: 20px;
        }
      }
    }
    hr {
      border-bottom: 1px solid var(--hk-color-spock);
      width: 180px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
    &-description {
      margin-bottom: 46px;
      margin-top: 8px;
      color: var(--hk-color-oxygen);
      font-size: 16px;
      font-weight: bold;
    }
    &-list {
      h4 {
        color: var(--hk-color-oxygen);
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 0;
      }
      p {
        margin-top: 4px;
        color: var(--hk-color-oxygen);
        font-size: 16px;
        font-weight: 500;
        text-align: center;
      }
    }
    &-button {
      margin-bottom: 36px;
    }
  }
}

// Compare Plans Styles
.hk-desktop-compare-plans-container {
  background-color: var(--hk-color-oxygen);
}

.hk-desktop-compare-plans {
  max-width: 1440px;
  width: 100%;
  margin: auto;
  padding: 0 32px;
}

.hk-desktop-compare-plans-title {
  padding-top: 56px;
  padding-bottom: 18px;
  text-align: center;
  h1 {
    color: var(--hk-color-scotty);
    font-size: 24px;
    font-weight: 700;
    margin-top: 0;
  }
  h4 {
    color: var(--hk-color-voyager);
    font-size: 16px;
    font-weight: 600;
    max-width: 376px;
    margin: auto;
  }
}

.hk-desktop-compare-plans-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 400px repeat(3, 1fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  align-items: center;
  justify-items: center;
  padding-bottom: 24px;
  h2,
  h4 {
    margin: 0;
  }
  &-row-wrapper {
    display: contents;

    .grid-header {
      width: 100%;
      background-color: #363837;
      color: var(--hk-color-oxygen);
      text-align: center;
      padding: 16px 0;
      &:first-child {
        text-align: left;
        border-radius: 10px 0 0 10px;
        padding-left: 32px;
      }
      &:last-child {
        border-radius: 0 10px 10px 0;
      }
    }

    .grid-pricing {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      color: var(--hk-color-klingon);
      font-size: 20px;
      font-weight: 700;
      padding: 16px 0;
      border-bottom: 1px solid var(--hk-color-klingon);
      &:first-child {
        align-items: start;
        padding-left: 32px;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: var(--hk-color-klingon);
      }
      h2 {
        font-size: 20px;
        font-weight: 700;
      }
    }

    .grid-data {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 24px 0;
      border-bottom: 1px solid var(--hk-color-klingon);
      h4 {
        color: var(--hk-color-klingon);
        font-size: 18px;
        font-weight: 600;
      }
      &:first-child {
        justify-content: start;
        padding-left: 32px;
      }
    }

    .grid-button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 24px 0;
      ion-button {
        width: 180px;
        --border-radius: 10px;
      }
    }
  }
  ion-icon {
    font-size: 24px;
  }
}

// Mobile Styles
.hk-signup-plans-header {
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px;
  margin: auto;
  text-align: center;
  h1 {
    color: var(--hk-color-scotty);
    font-size: 24px;
    font-weight: 700;
    margin: 0;
  }
  h4 {
    font-size: 16px;
    font-weight: 400;
    color: var(--hk-color-scotty);
    margin: auto;
    padding-top: 16px;
    max-width: 324px;
  }
  p {
    font-size: 14px;
    font-weight: 600;
    color: var(--hk-color-voyager);
    margin: 0;
    padding-top: 8px;
  }
}

.hk-signup-plans-toggle-container {
  height: 48px;
  width: 326px;
  margin-top: 34px;
}
.hk-signup-plans-toggle {
  height: 48px;
  background-color: var(--hk-color-oxygen);
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 2px;
  ion-chip {
    height: 44px;
    margin: 0;
    width: 326px;
    border-radius: 20px;
    justify-content: center;
    font-weight: bold;
    color: var(--hk-color-klingon);
    background-color: var(--hk-color-oxygen);
    &.selected {
      color: #fff;
      background-color: var(--hk-color-discovery);
    }
  }
}

.hk-plan-slider {
  margin-bottom: 40px;
}

.hk-compare-plans-container {
  background-color: var(--hk-color-oxygen);
}

.hk-compare-plans {
  max-width: 1440px;
  width: 100%;
  margin: auto;
  padding: 24px;
  padding-bottom: 0;
}

.hk-compare-plans-title {
  padding-top: 8px;
  padding-bottom: 18px;
  text-align: center;
  h1 {
    color: var(--hk-color-scotty);
    font-size: 24px;
    font-weight: 700;
    margin-top: 0;
  }
  h4 {
    color: var(--hk-color-voyager);
    font-size: 16px;
    font-weight: 600;
    max-width: 376px;
    margin: auto;
  }
}

.hk-compare-plans-grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  h2,
  h4 {
    margin: 0;
  }

  .grid-header {
    width: 100%;
    background-color: #363837;
    color: var(--hk-color-oxygen);
    text-align: left;
    padding: 20px 24px;
    margin-bottom: 6px;
    border-radius: 10px;
  }
  .grid-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--hk-color-klingon);
    &-data {
      font-size: 14px;
      padding: 18px 0;
      h4 {
        font-size: 14px;
      }
    }
  }
  .grid-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px 0 40px;
    ion-button {
      width: 180px;
      --border-radius: 10px;
    }
  }
  ion-icon {
    font-size: 24px;
  }
}
