// Desktop Styles
.hk-desktop-initial-appointment-page {
  top: 118px !important;
}
.hk-desktop-initial-appointment {
  max-width: 1440px;
  margin: auto;
  margin-top: 40px;
  padding: 0 32px;
  display: flex;
  justify-content: space-between;

  &-header {
    padding: 32px;
    background-color: var(--hk-color-oxygen);
    border-radius: 10px;
    &-text h1 {
      font-size: 40px !important;
      margin: 0 0 24px 0 !important;
    }

    p {
      font-size: 18px;
      padding: 0;
    }
  }

  &-body {
    min-width: 400px;
    background-color: var(--ion-color-grey);
    margin-left: 24px;
    padding: 32px;
    border-radius: 10px;
    ion-button {
      font-weight: bold;
    }
    &-content {
      display: flex;
      margin-bottom: 40px;
    }
  }

  .hk-card-image {
    width: 74px;
    margin-right: 16px;
  }

  h1.hk-card-text {
    font-size: 20px;
    font-weight: bold !important;
    margin: 0 0 16px 0;
  }

  h3.hk-card-text {
    font-size: 16px;
    margin: 0;
  }
}

// Mobile Styles
.hk-schedule {
  &-header {
    padding-top: 80px !important;
    background-color: var(--ion-color-grey);

    &-text h1 {
      font-size: 40px !important;
      &.name h1 {
        margin-top: 0;
        font-weight: bold !important;
      }
    }

    p {
      font-size: 14px;
      padding: 0;
    }
  }

  &-body {
    background-color: var(--ion-background-color);
    ion-button {
      font-weight: bold;
    }
    .hk-illustration {
      margin-top: 0;
    }
    .hk-item-title {
      font-weight: normal !important;
      font-size: 14px !important;
    }
    .hk-item-description {
      font-weight: bold !important;
      font-size: 16px !important;
      text-transform: uppercase;
    }
  }

  .hk-card-image {
    margin-right: 8px;
  }

  h1.hk-card-text {
    font-size: 18px;
    font-weight: bold !important;
    margin-bottom: 8px;
  }

  h3.hk-card-text {
    font-size: 14px;
    font-weight: bold !important;
  }
}

/*.schedule-body .hk-illustration img {*/
/*  height: 120px;*/
/*}*/
