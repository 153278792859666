.hk-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.hk-not-found-border-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.hk-not-found-border-left {
  position: absolute;
  top: 0;
  left: 0;
}

.hk-not-found-404 {
  margin-bottom: 32px;
}

.hk-not-found-text {
  font-size: 16px;
  margin: 0;
  color: var(--hk-color-scotty);
}

.hk-not-found-button {
  --border-radius: 10px;
  margin-top: 40px;
  width: 294px;
}

.hk-not-found-border-desktop {
  top: 120px;
}

.hk-not-found-border-mobile {
  width: 200px;
}
