// Desktop Styles
.hk-desktop-document-edit-body-container {
  --background: var(--hk-color-romulan);
}
.hk-desktop-document-edit-toolbar {
  background-color: var(--hk-color-romulan);
}
.hk-desktop-document-edit-title {
  font-size: 18px;
  margin: 24px 0;
  padding-left: 24px;
  text-align: left;
  color: var(--hk-color-oxygen);
}

.hk-desktop-document-edit-buttons {
  font-size: 24px;
  margin-right: 12px;
}

.hk-desktop-document-edit-body {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 74px;
  background-color: var(--hk-color-romulan);
}

.hk-desktop-document-edit-body-loading {
  height: 400px;
  width: 400px;
  border-radius: 40px;
  background: var(--hk-color-scotty);
}

// Desktop Actions Styles
.hk-desktop-document-edit-dropdown-content {
  position: absolute;
  min-width: 392px;
  font-weight: 400;
  font-size: 16px;
  white-space: nowrap;
  right: 62px;
  top: 48px;
  background-color: var(--hk-color-discovery);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  border-radius: 8px;
}
.hk-desktop-edit-document-modal-action-item {
  font-weight: 600;
  color: var(--hk-color-oxygen);
  margin: 16px 24px 8px 24px;
  --background: var(--hk-color-discovery);
  --border-radius: 8px;
  &:hover {
    --background: var(--hk-color-scotty);
    cursor: pointer;
  }
  ion-icon {
    margin: 16px 16px 16px 0;
  }
}

#hk-desktop-edit-document-delete {
  margin-top: 16px;
  border-top: 1px solid var(--hk-color-scotty);
}

.hk-desktop-edit-document-delete-container {
  padding: 16px;
  margin: 16px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  &:hover {
    background: var(--hk-color-scotty);
    cursor: pointer;
  }
}

.hk-desktop-edit-document-delete-description {
  &-title {
    font-size: 16px;
    font-weight: 600;
    color: var(--hk-color-uhura);
  }
}

.hk-desktop-edit-document-delete-icon {
  color: var(--hk-color-uhura);
  font-size: 24px;
  line-height: 0;
  ion-icon {
    margin-right: 16px;
  }
}

// Desktop Rename Styles
.hk-desktop-rename-title {
  font-weight: 700;
  font-size: 18px;
  text-align: center;
}

.hk-desktop-rename-action {
  font-weight: 600 !important;
  font-size: 18px !important;
}

.hk-desktop-rename-footer {
  display: flex;
  position: absolute;
  align-items: center;
  bottom: 0;
  background-color: var(--hk-color-discovery);
  min-height: 100px;
  width: 100%;
  padding: 16px 90px 24px;
}

.hk-desktop-rename-input {
  --padding-start: 16px;
  --padding-end: 16px;

  border-radius: 4px;
  height: 48px;
  width: 100%;
  font-size: 14px;
  margin-right: 16px;
  color: var(--hk-color-romulan);
  background: var(--hk-color-oxygen);
}

.hk-desktop-rename-button {
  font-weight: bold;
  font-size: 16px;
}

// Desktop Delete Overlay Styles
.hk-desktop-document-delete-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  &-body-title {
    font-size: 23px;
    font-weight: bold;
  }
  &-body-description {
    width: 224px;
    margin: auto;
    margin-top: 16px;
    margin-bottom: 0;
    font-size: 16px;
  }
  &-body-document {
    display: flex;
    align-items: center;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 18px;
    width: 344px;
    padding: 16px;
    background: var(--hk-color-klingon);
    font-size: 15px;
    font-weight: bold;
    border-radius: 8px;
    &-icon {
      font-size: 32px;
      margin-right: 16px;
    }
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    width: 344px;
    ion-button {
      --border-radius: 8px;
      text-transform: none;
      height: 48px;
      width: 164px;
      font-size: 16px;
      margin-left: 0;
    }
  }
}

// Mobile Styles
.hk-document-edit-title {
  font-size: 18px;
  text-align: center;
}

.hk-document-edit-button {
  font-size: 24px;
}

.hk-document-edit-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 16px;
  background-color: var(--hk-color-romulan);
}

.hk-rename-title {
  font-weight: 700;
  font-size: 18px;
  text-align: center;
}

.hk-rename-action {
  font-weight: 600 !important;
  font-size: 18px !important;
}

.hk-rename-footer {
  position: absolute;
  bottom: 0;
  background-color: var(--hk-color-discovery);
  min-height: 100px;
  width: 100%;
  padding: 24px 16px;
}

.hk-rename-input {
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-start: 16px;
  --padding-end: 16px;

  border-radius: 8px;
  width: 100%;
  padding: 16px;
  font-size: 14px;
  background: var(--ion-background-color);
}

// Document Viewer Modal
.hk-edit-document-modal-body {
  overflow: scroll;
  height: 100%;
  padding-top: 16px;
  background-color: var(--hk-color-discovery);
}

.hk-edit-document-modal-action-item {
  padding: 16px 8px 0 8px;
  font-weight: 600;
}

#hk-edit-document-delete {
  margin-top: 24px;
  border-top: 1px solid var(--hk-color-scotty);
}

.hk-edit-document-delete-container {
  background-color: var(--hk-color-scotty);
  margin: 16px 24px;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.hk-edit-document-delete-description {
  &-title {
    font-size: 16px;
    font-weight: 600;
    color: var(--hk-color-uhura);
  }
}

.hk-edit-document-delete-icon {
  color: var(--hk-color-uhura);
  font-size: 24px;
  line-height: 0;
  ion-icon {
    margin-right: 16px;
  }
}

// Mobile Delete Modal Styles
.hk-document-delete-account-content {
  --background: var(--hk-color-discovery);
}

.hk-document-delete-account-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  color: var(--hk-color-oxygen);
  &-title {
    font-weight: bold;
    margin-top: 32px;
    padding-left: 24px;
  }
  &-description {
    padding-left: 24px;
    margin-top: 16px;
    margin-bottom: 0;
    font-size: 16px;
    padding-right: 16px;
  }
}

.hk-document-delete-account-body-document {
  display: flex;
  align-items: center;
  margin: 24px;
  padding: 16px;
  background: var(--hk-color-klingon);
  font-size: 15px;
  font-weight: bold;
  border-radius: 8px;
  &-icon {
    font-size: 32px;
    margin-right: 16px;
  }
}

.hk-document-delete-account-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  ion-button {
    --border-radius: 8px;
    text-transform: none;
    height: 50px;
    width: 100%;
    font-size: 16px;
    margin-left: 0;
  }
}

.hk-mobile-document-delete-account-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px 40px 20px;
  ion-button {
    --border-radius: 8px;
    text-transform: none;
    height: 50px;
    width: 100%;
    font-size: 16px;
    margin-left: 0;
    margin-bottom: 40px;
  }
}
