// Dashboard Styles
.hk-desktop-visit-card {
  display: block;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 8px;
  background-color: var(--hk-color-oxygen);
  .hk-desktop-visit-card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 142px;
    padding: 24px;
    padding-right: 40px;
    &__header {
      display: flex;
      align-items: center;
      width: 50%;
      margin-right: 24px;

      .hk-desktop-season-icon {
        min-width: 72px;
        height: 48px;
        width: 72px;
        margin-right: 24px;
        color: var(--hk-color-oxygen);
      }
      &-title {
        display: flex;
        flex-direction: column;
        &-name {
          font-size: 15px;
          white-space: normal;
        }
        &-description {
          margin-top: 4px;
        }
      }
    }
    &__content {
      display: flex;
      justify-content: space-between;
      width: 35%;
      margin-right: 24px;
      font-weight: bold;
      a {
        color: var(--ion-color-grey);
        text-decoration: underline;
        &:hover {
          cursor: pointer;
          color: var(--ion-color-primary);
        }
      }
    }
    &__button {
      display: flex;
      justify-content: flex-end;
      .hk-desktop-schedule-button {
        width: 200px;
        height: 44px;
        --background: var(--ion-color-primary);
        --border-radius: 8px !important;
        --box-shadow: none;
        font-weight: bold;
        color: #fff;
        text-transform: none;
        letter-spacing: inherit;
        &.disabled {
          --background: var(--ion-color-step-900);
          color: var(--ion-color-step-500);
        }
      }
    }
  }
  .hk-desktop-visit-card-footer {
    display: flex;
    padding: 8px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    background-color: var(--ion-color-primary);
  }
}

// Mobile Styles
.hk-visit-card {
  display: block;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 16px;
  &-header-wrapper {
    background-color: var(--ion-color-grey);
    padding: 16px;
    &-disabled {
      background: var(--hk-color-voyager);
      .hk-season-icon {
        color: var(--hk-color-voyager) !important;
      }
    }
  }
  &.no-schedule {
    .hk-visit-card-header-wrapper {
      background-color: var(--ion-color-step-500);
    }
    .hk-visit-card-header .hk-season-icon {
      color: var(--ion-color-step-700) !important;
    }
  }

  .hk-visit-card-header {
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;

    .hk-season-icon {
      width: 40px;
      height: 24px;
      margin-right: 8px;
      color: var(--hk-color-scotty);
    }

    &-name {
      white-space: normal;
    }
  }

  .hk-visit-card-body-wrapper {
    background-color: #fff;
    border-top: none;

    .hk-visit-card-body {
      flex-direction: column;
      border-top: 1px solid #fff;
      width: 100%;
      display: flex;
      padding: 16px 8px 8px 8px;

      ion-thumbnail {
        width: 48px;
        height: 48px;
        margin-right: 8px;
        img {
          border-radius: 4px;
        }
      }

      p {
        margin: 0;
        padding: 0 8px 8px 8px;
        font-size: 14px;
      }

      ion-button {
        font-weight: bold;
        height: 44px;
        --border-radius: 8px;
      }

      .hk-schedule-button {
        --background: var(--ion-color-primary);
        color: #fff;
        font-size: 16px;
        &.disabled {
          --background: var(--ion-color-step-900);
          color: var(--ion-color-step-500);
        }
      }
    }
  }

  .hk-visit-card-footer {
    display: flex;
    padding: 8px;
    font-size: 13px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    background-color: var(--ion-color-primary);

    ion-icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
}
