ion-searchbar.hk-search-bar {
  --box-shadow: none;
  --color: var(--hk-color-klingon);
  padding: 12px 16px;
  input {
    font-size: 14px !important;
    --background: #fff;
    --border-radius: 4px;
  }
  .searchbar-search-icon {
    color: #000;
    width: 16px;
  }
}

.searchbar-disabled {
  opacity: 1 !important;
  color: rgba(74, 74, 74, 0.4) !important;
  input {
    --background: var(--ion-background-color) !important;
  }
  .searchbar-search-icon {
    color: rgba(74, 74, 74, 0.4) !important;
  }
}
