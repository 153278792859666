// Alternative Styles
.hk-alt-list-item {
  --background: #fff;
  --border-radius: 10px;
  --min-height: 100px;
  height: 100px;
  margin-bottom: 4px;
  &::part(native) {
    padding-left: 24px;
  }
  &:hover {
    cursor: pointer;
    border: 2px solid var(--ion-color-primary);
    border-radius: 10px;
    transition: 50ms ease;
  }

  &-start-label {
    height: 48px;
    width: 48px;
    padding: 12px;
    margin-right: 24px;
    font-size: 18px;
    text-align: center;
    font-weight: bold !important;
    color: #fff;
    border-radius: 4px;
    background-color: var(--hk-color-scotty);

    &-start-icon {
      height: 24px;
      width: 24px;
      padding: 8px;
      margin-right: 16px;
      font-size: 16px;
      text-align: center;
      font-weight: bold !important;
      color: #fff;
      border-radius: 4px;
      background-color: var(--ion-color-primary);
    }
  }

  &-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &-title {
    width: 33%;
    h2 {
      text-align: left;
      margin: 0px !important;
      font-size: 18px !important;
      font-weight: bold !important;
    }
  }

  &-description {
    display: flex;
    flex-direction: column;
    max-width: 240px;
    width: 33%;
    font-size: 16px;
    font-weight: bold;
    p {
      font-weight: normal;
      margin: 4px 0;
      color: var(--hk-color-klingon);
    }
  }

  &-phone {
    display: flex;
    align-items: center;
    width: 33%;
    ion-icon {
      font-size: 20px;
      margin-right: 24px;
    }
    &-number {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      font-weight: bold;
      p {
        font-weight: normal;
        margin: 4px 0;
        color: var(--hk-color-klingon);
      }
    }
  }
}

// Mobile Styles
.hk-list-item {
  --background: #fff;
  --border-radius: 4px;
  --min-height: 80px;
  margin-bottom: 8px;

  &-title {
    text-align: left;
    margin-bottom: 4px !important;
    font-size: 16px !important;
    font-weight: bold !important;
  }

  &-description {
    text-align: left;
    font-size: 14px;
  }

  &-start-label {
    height: 48px;
    width: 48px;
    padding: 8px;
    margin-right: 16px;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    font-weight: bold !important;
    color: #fff;
    border-radius: 4px;
    background-color: var(--hk-color-scotty);

    &-start-icon {
      height: 24px;
      width: 24px;
      padding: 8px;
      margin-right: 16px;
      font-size: 16px;
      text-align: center;
      font-weight: bold !important;
      color: #fff;
      border-radius: 4px;
      background-color: var(--ion-color-primary);
    }
  }
}
