// Desktop Styles
.hk-desktop-digital-new-contact-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  top: 0;
  padding: 16px 24px;
  height: 146px;
  border-bottom: 1px solid var(--hk-color-vulcan);
  background-color: var(--hk-color-oxygen);
  z-index: 1000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  &-title {
    margin-bottom: 4px;
    color: var(--hk-color-oxygen);
    font-size: 18px;
    font-weight: bold;
  }
}

.hk-desktop-digital-new-contact-body {
  background-color: var(--hk-color-oxygen);
  padding: 40px;
}

.hk-desktop-digital-new-contact-form {
  &-item {
    --inner-padding-end: 0;
    &::part(native) {
      background-color: var(--hk-color-oxygen);
    }
    &-field {
      border-bottom: 1px solid var(--hk-color-vulcan);
      &.ion-color-danger {
        border-bottom: 1px solid var(--ion-color-danger);
      }
      &-select {
        border-bottom: 1px solid var(--hk-color-vulcan);
        margin-bottom: 24px;
        padding: 12px 0;
      }
    }
  }
}
.hk-desktop-digital-new-contact-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--hk-color-oxygen);
  padding: 16px 24px;
  border-top: 1px solid var(--hk-color-vulcan);
  ion-button {
    --box-shadow: none;
    text-transform: none;
    height: 48px;
    width: 200px;
    font-size: 16px;
    margin: 0 8px;
  }
}

// Mobile Styles
.hk-mobile-digital-new-contact-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  top: 0;
  padding: 20px 24px 40px 24px;
  height: 146px;
  border-bottom: 1px solid var(--hk-color-vulcan);
  background-color: var(--hk-color-oxygen);
  z-index: 1000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  &-title {
    margin-bottom: 4px;
    color: var(--hk-color-oxygen);
    font-size: 18px;
    font-weight: bold;
  }
}

.hk-digital-new-contact-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  top: 0;
  padding: 16px 24px;
  height: 146px;
  border-bottom: 1px solid var(--hk-color-vulcan);
  background-color: var(--hk-color-oxygen);
  z-index: 1000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  &-title {
    margin-bottom: 4px;
    color: var(--hk-color-oxygen);
    font-size: 18px;
    font-weight: bold;
  }
}

.hk-digital-new-contact-body {
  padding: 50px 24px;
}

.hk-digital-new-contact-form {
  &-item {
    --inner-padding-end: 0;
    &-field {
      border-bottom: 1px solid var(--hk-color-vulcan);
      &.ion-color-danger {
        border-bottom: 1px solid var(--ion-color-danger);
      }
      &-select {
        border-bottom: 1px solid var(--hk-color-vulcan);
        margin-bottom: 24px;
        padding: 12px 0;
      }
    }
  }
}

.hk-digital-new-contact-form-picker {
  width: 100%;
  .hk-digital-new-contact-form-picker-input {
    background-color: var(--ion-background-color);
    border-radius: 8px;
    font-size: 16px;
    height: 100%;
    width: 100%;
    border: none;
    padding: 16px 16px 6px 0;
    &:focus {
      outline: none;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      color: var(--ion-color-medium) !important;
      background-color: #fff !important;
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    &-icon {
      position: fixed;
      right: 24px;
      top: 454px;
      height: 18px;
      width: 18px;
    }
  }
}

.hk-digital-new-contact-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  ion-button {
    --box-shadow: none;
    text-transform: none;
    height: 48px;
    font-size: 16px;
  }
}

.picker-columns {
  height: 100% !important;
}

.invalid-phone-number-error {
  position: absolute;
  right: 0;
  font-size: 13px;
  color: var(--hk-color-picard);
  ion-icon {
    position: relative;
    top: 3px;
    margin-left: 8px;
    font-size: 16px;
  }
}

.invalid-email-address-error {
  position: absolute;
  right: 0;
  font-size: 13px;
  color: var(--hk-color-picard);
  ion-icon {
    position: relative;
    top: 3px;
    margin-left: 8px;
    font-size: 16px;
  }
}

.hk-contact-type-alert .alert-wrapper {
  min-width: 400px;
}
