// Desktop Styles
.hk-desktop-task-details-container {
  --background: var(--hk-color-oxygen);
}
.hk-desktop-task-details {
  padding: 32px;
  &-header {
    margin-top: 48px;
    padding-bottom: 16px;
    &-title {
      font-weight: bold;
      font-size: 18px;
      margin-top: 4px;
    }
  }
  &-notes {
    border-top: 1px solid var(--hk-color-vulcan);
    h6 {
      font-weight: bold;
    }
    p {
      font-size: 14px;
      font-weight: 600;
      color: var(--ion-color-step-250);
      padding: 16px;
      background-color: var(--ion-background-color);
      border-radius: 4px;
    }
  }
  &-images {
    display: flex;
    justify-content: space-between;
    &-image {
      max-width: 410px;
      border-radius: 4px;
    }
  }
}
.hk-status-pill-incomplete {
  font-weight: bold;
  font-size: 10px;
  border-radius: 3px;
  padding: 3px 8px;
  width: 74px;
  color: var(--hk-color-oxygen);
  background-color: var(--hk-color-klingon);
}

// Mobile Styles
.hk-task-details {
  --background: #fff;
  &-header {
    margin-top: 48px;
    h5 {
      font-weight: bold;
    }
    &-status {
      display: flex;
      align-items: center;
      ion-icon {
        margin-right: 4px;
      }
    }
  }
  &-notes {
    margin-top: 16px;
    border-top: 1px solid var(--ion-color-medium);
    h6 {
      font-weight: bold;
    }
    p {
      font-size: 14px;
      font-weight: 600;
      color: var(--ion-color-step-250);
      padding: 8px;
      background-color: var(--ion-color-step-900);
      border-radius: 8px;
    }
  }
  &-images-image {
    width: 100%;
    margin-top: 8px;
    border-radius: 6px;
  }
}
