// Desktop View Styles
.hk-desktop-dashboard-page {
  top: 118px !important;
}

.hk-desktop-dashboard-header {
  justify-content: center;
  background-color: var(--hk-color-discovery);
}

.hk-desktop-dashboard-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  padding: 0 32px;
}

.hk-desktop-dashboard-home-switcher {
  display: flex;
  justify-content: center;
  z-index: 1000;
  width: 100%;
  max-height: 80px;
  background-color: var(--hk-color-discovery);
  color: var(--hk-color-oxygen);
  &-container {
    width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 32px;
    border-bottom: 1px solid var(--hk-color-scotty);
  }
  &-title {
    font-weight: 700;
    font-size: 20px;
  }
  &-content {
    min-width: 320px;
  }
}

.hk-desktop-dashboard-home {
  display: flex;
  justify-content: center;
  z-index: 1000;
  width: 100%;
  background-color: var(--hk-color-discovery);
  color: var(--hk-color-oxygen);
  &-info {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    text-align: right;
    &-address {
      font-style: italic;
      font-weight: 600;
      font-size: 14px;
      padding-bottom: 4px;
      &-icon {
        color: var(--ion-color-primary);
        transform: translateY(2px);
        margin-right: 8px;
        height: 32px;
        width: 32px;
      }
    }
    &-welcome {
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.hk-desktop-dashboard-renew-banner-container {
  width: 100%;
  background-color: rgba(0, 172, 255, 0.1);
}
.hk-desktop-dashboard-renew-banner {
  margin: auto;
  max-width: 1440px;
  padding: 24px 32px;
  color: var(--hk-color-scotty);
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ion-button {
    --background: var(--hk-color-spock);
  }
}

.hk-desktop-dashboard-toggle {
  display: flex;
  position: relative;
  top: 19px;
  color: var(--hk-color-oxygen);
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  & > div {
    padding: 8px 0;
    &:not(:first-child) {
      margin-left: 32px;
    }
  }
  .selected {
    color: var(--ion-color-primary);
    border-bottom: 4px solid var(--ion-color-primary);
  }
}

.hk-desktop-dashboard-appointments {
  max-width: 1440px;
  margin: auto;
  padding: 0 32px;
  padding-top: 16px;
}

// Mobile View Styles
.hk-dashboard {
  background-color: var(--ion-color-grey);
  overflow: visible;
  &-home-info {
    background: var(--ion-color-grey);
    color: var(--hk-color-oxygen);
    display: flex;
    flex-direction: column;
    padding: 16px;
    padding-bottom: 0;
    &-address {
      font-size: 14px;
      padding-bottom: 4px;
      &-icon {
        color: var(--hk-color-oxygen);
        transform: translateY(2px);
        margin-right: 8px;
      }
    }
    &-welcome {
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &-toggle {
    z-index: 10;
    position: relative;
    bottom: -18px;
    width: 244px;
    height: 40px;
    background-color: var(--ion-background-color);
    border-radius: 20px;
    margin: auto;
    display: flex;
    align-items: center;
    ion-chip {
      width: 118px;
      height: 32px;
      justify-content: center;
      font-weight: bold;
      color: var(--ion-color-grey);
      background-color: var(--ion-background-color);
      &.selected {
        color: #fff;
        background-color: var(--ion-color-grey);
      }
    }
  }
  &-renew-banner-container {
    background-color: var(--hk-color-oxygen);
  }
  &-renew-banner {
    padding: 36px 24px 16px 24px;
    background-color: rgba(0, 172, 255, 0.1);
    color: var(--hk-color-scotty);
    font-weight: bold;
    font-size: 14px;
    z-index: 10;
    ion-button {
      --background: var(--hk-color-spock);
    }
  }
}

// Toggle Animation
.toggle-enter.left {
  opacity: 0;
  transform: translateX(-100%);
}
.toggle-enter-active.left {
  opacity: 1;
  transform: translateX(0%);
}
.toggle-exit.left {
  opacity: 1;
  transform: translateX(0%);
}
.toggle-exit-active.left {
  opacity: 0;
  transform: translateX(-100%);
}
.toggle-enter-active,
.toggle-exit-active.left {
  transition:
    opacity 300ms,
    transform 300ms;
}

.toggle-enter.right {
  opacity: 0;
  transform: translateX(100%);
}
.toggle-enter-active.right {
  opacity: 1;
  transform: translateX(0%);
}
.toggle-exit.right {
  opacity: 1;
  transform: translateX(0%);
}
.toggle-exit-active.right {
  opacity: 0;
  transform: translateX(100%);
}
.toggle-enter-active,
.toggle-exit-active.right {
  transition:
    opacity 300ms,
    transform 300ms;
}
