// Desktop Styles
.hk-desktop-digital-dashboard-page {
  top: 120px !important;
}

.hk-desktop-digital-dashboard-home-switcher {
  display: flex;
  justify-content: center;
  z-index: 1000;
  width: 100%;
  max-height: 80px;
  background-color: var(--hk-color-discovery);
  color: var(--hk-color-oxygen);
  &-container {
    width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 32px;
    border-bottom: 1px solid var(--hk-color-scotty);
  }
  &-title {
    font-weight: 700;
    font-size: 20px;
  }
  &-content {
    min-width: 320px;
  }
}

.hk-desktop-digital-dashboard-home {
  display: flex;
  justify-content: center;
  z-index: 1;
  width: 100%;
  background-color: var(--hk-color-discovery);
  color: var(--hk-color-oxygen);
  &-info {
    width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 16px 32px;
    &-address {
      font-size: 14px;
      padding-bottom: 4px;
      &-icon {
        color: var(--ion-color-primary);
        transform: translateY(2px);
        margin-right: 8px;
        height: 16px;
        width: 16px;
      }
    }
    &-welcome {
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.hk-desktop-digital-dashboard-renew-banner-container {
  width: 100%;
  background-color: rgba(0, 172, 255, 0.1);
}
.hk-desktop-digital-dashboard-renew-banner {
  margin: auto;
  max-width: 1440px;
  padding: 24px 32px;
  color: var(--hk-color-scotty);
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ion-button {
    --background: var(--hk-color-spock);
  }
}

.hk-desktop-digital-dashboard-content {
  max-width: 1440px;
  margin: auto;
  &-wrapped {
    display: flex;
  }
}

.hk-desktop-digital-dashboard {
  margin-top: -1px;
  background-color: var(--hk-color-oxygen);
  &-home-profile-card {
    display: flex;
    justify-content: space-between;
    background-color: #d6ddeb;
    margin: 16px 16px 0px 16px;
    padding: 20px 16px;
    border-radius: 8px;
    cursor: pointer;
    &-text {
      p {
        margin: 0;
        padding-bottom: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #6b6f76;
      }
      h6 {
        margin: 0;
        font-weight: bold;
      }
    }
    ion-icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }

  &-tasks-list {
    background-color: var(--hk-color-oxygen);
    padding-top: 0;
    margin: 0 16px;
    &-redirect {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0 16px;
      text-align: center;
      h3 {
        margin-top: 32px;
        font-size: 24px;
        font-weight: bold;
      }
      p {
        font-size: 16px;
        font-weight: 600;
      }
      ion-button {
        width: 200px;
        margin-bottom: 32px;
      }
    }
  }
}

// Desktop Available/Completed Toggle Styles
.hk-desktop-digital-dashboard-tasks-toggle {
  position: sticky;
  justify-content: space-between;
  align-items: center;
  top: 0;
  z-index: 1;
  padding: 24px 16px;
  background-color: var(--hk-color-oxygen);
  &-container {
    display: flex;
  }
  .toggle-pill {
    border: 1px solid var(--ion-background-color);
    border-radius: 8px;
    padding: 8px 16px;
    margin-right: 16px;
    font-size: 13px;
    font-weight: 600;
    color: var(--hk-color-klingon);
    cursor: pointer;
  }
  .selected {
    background-color: var(--ion-background-color);
  }
  .disabled {
    background-color: var(--ion-color-step-900);
    color: var(--hk-color-vulcan);
    cursor: not-allowed;
    pointer-events: none;
  }
}

// Mobile Styles
.hk-digital-dashboard {
  margin-top: -1px;
  background-color: var(--hk-color-oxygen);
  &-home-profile-card {
    display: flex;
    justify-content: space-between;
    background-color: #d6ddeb;
    margin: 16px 16px 14px 16px;
    padding: 20px 16px;
    border-radius: 8px;
    &-text {
      p {
        margin: 0;
        padding-bottom: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #6b6f76;
      }
      h6 {
        margin: 0;
        font-weight: bold;
      }
    }
    ion-icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  &-home-info {
    background: var(--ion-color-grey);
    color: var(--hk-color-oxygen);
    display: flex;
    flex-direction: column;
    padding: 24px;
    &-address {
      font-size: 14px;
      padding-bottom: 4px;
      &-icon {
        color: var(--hk-color-oxygen);
        transform: translateY(2px);
        margin-right: 8px;
      }
    }
    &-welcome {
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &-renew-banner {
    padding: 16px 24px;
    background-color: rgba(0, 172, 255, 0.1);
    color: var(--hk-color-scotty);
    font-weight: bold;
    font-size: 14px;
    z-index: 10;
    ion-button {
      --background: var(--hk-color-spock);
    }
  }
  &-tasks-list {
    background-color: var(--hk-color-oxygen);
    padding-top: 0;
    &-redirect {
      width: 100%;
      padding: 0 16px;
      text-align: center;
      h3 {
        margin-top: 32px;
        font-size: 24px;
        font-weight: bold;
      }
      p {
        font-size: 16px;
        font-weight: 600;
      }
      ion-button {
        margin-top: 24px;
        margin-bottom: 32px;
      }
    }
  }
}

.hk-dashboard-accordion-divider {
  height: 1px;
  margin: 16px;
  background-color: #d9d9d9;
}

.hk-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.hk-show {
  opacity: 1;
  transform: translateY(0%);
  transition: 200ms;
}

.hk-hidden {
  height: 0;
  opacity: 0;
  transform: translateY(-100%);
  transition: 200ms;
}

// Card Style
.todo-card {
  margin: 16px;
  border-radius: 8px;
  &-image {
    height: 100px;
    border-radius: 8px 8px 0 0;
    background-color: var(--hk-color-vulcan);
    img {
      height: 100px;
      width: 100%;
      border-radius: 8px 8px 0 0;
      object-fit: cover;
      object-position: 100% 50%;
    }
  }
  &-button {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    height: 78px;
    border-radius: 0 0 8px 8px;
    border: 1px solid var(--hk-color-vulcan);
    border-top: none;
    background-color: var(--hk-color-oxygen);
    &-description {
      display: flex;
      flex-direction: column;
      &-title {
        font-family: 'Open Sans';
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 4px;
      }
      &-subtitle {
        font-family: 'Open Sans';
        font-size: 13px;
        font-weight: bold;
      }
    }
    &-caret {
      display: flex;
      align-items: center;
      font-size: 24px;
    }
  }
}

// Available/Completed Toggle Styles
.hk-digital-dashboard-tasks-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 24px 16px 24px;
  background-color: var(--hk-color-oxygen);
  &-container {
    display: flex;
  }
  &-settings {
    height: 18px;
    width: 18px;
    border: 1px solid var(--ion-background-color);
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
  }
  .toggle-pill {
    border: 1px solid var(--ion-background-color);
    border-radius: 8px;
    padding: 8px 16px;
    margin-right: 16px;
    font-size: 13px;
    font-weight: 600;
    color: var(--hk-color-klingon);
  }
  .selected {
    background-color: var(--ion-background-color);
  }
  .disabled {
    background-color: var(--ion-color-step-900);
    color: var(--hk-color-vulcan);
    cursor: not-allowed;
    pointer-events: none;
  }
}

// Toggle Animation
.hk-digital-dashboard-tasks-list-animation {
  height: 100%;
}
.toggle-enter.left {
  opacity: 0;
  transform: translateX(-100%);
}
.toggle-enter-active.left {
  opacity: 1;
  transform: translateX(0%);
}
.toggle-exit.left {
  opacity: 1;
  transform: translateX(0%);
}
.toggle-exit-active.left {
  opacity: 0;
  transform: translateX(-100%);
}
.toggle-enter-active,
.toggle-exit-active.left {
  transition:
    opacity 300ms,
    transform 300ms;
}

.toggle-enter.right {
  opacity: 0;
  transform: translateX(100%);
}
.toggle-enter-active.right {
  opacity: 1;
  transform: translateX(0%);
}
.toggle-exit.right {
  opacity: 1;
  transform: translateX(0%);
}
.toggle-exit-active.right {
  opacity: 0;
  transform: translateX(100%);
}
.toggle-enter-active,
.toggle-exit-active.right {
  transition:
    opacity 300ms,
    transform 300ms;
}
