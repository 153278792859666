.hk-update {
  --ion-background-color: var(--ion-color-grey);
  section .hk-illustration {
    max-width: 70%;
    margin: 50% auto 48px auto;
  }
  h3 {
    color: #fff;
    font-weight: 700;
    margin: 24px auto;
  }
  p {
    color: #fff;
    font-weight: 600;
    &:first-of-type {
      margin-bottom: 0;
    }
    &:last-of-type {
      margin: 0;
    }
  }
  &-button {
    margin: 40px 24px;
  }
}
