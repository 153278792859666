// Desktop View Styles
.hk-desktop-create-account-spinner {
  margin-top: 120px;
}
.hk-desktop-create-account-view {
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &-content {
    width: 342px;
  }
  .hk-desktop-create-account-header {
    margin-top: 64px;
    font-weight: bold;
    color: var(--hk-color-romulan);
  }

  h6.hk-desktop-create-account-subheader {
    color: var(--hk-color-voyager);
    font-weight: 600;
    font-size: 16px;
    max-width: 298px;
    text-align: center;
    margin: auto;
  }

  .hk-illustration img {
    max-width: 70%;
    margin-top: 64px;
  }

  .hk-desktop-create-account-options {
    margin: auto;
    margin-top: 40px;
    padding-bottom: 24px;
    &-divider {
      text-align: center;
      margin: 8px 0;
      span {
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        color: var(--ion-color-medium) !important;
        background: var(--ion-background-color);
        padding: 10px;
        z-index: 1;
      }
      hr {
        border-width: 1px 0 0 0;
        border-color: var(--ion-color-medium);
        border-style: solid;
        margin: -10px 4px 0 4px;
      }
    }
    &-email {
      font-family: 'Open Sans', sans-serif;
      font-weight: bold;
      font-size: 16px;
      width: 100%;
      padding: 0 6px !important;
    }
    .hk-desktop-create-account-email-button {
      --box-shadow: none;
      text-transform: none;
      height: 48px;
      width: 100%;
      font-size: 16px;
      margin-top: 16px;
    }
    .hk-signup-button {
      --box-shadow: none;
      text-transform: none;
      font-family: 'Open Sans', sans-serif;
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
      width: 100%;
      border-radius: 8px;
      height: 46px;
      margin-bottom: 16px;
      background-color: #fff;
      color: var(--hk-color-romulan);
      display: flex;
      align-items: center;
      img {
        margin-left: 24px;
        flex-grow: 0;
        width: 24px;
        height: 24px;
      }
      span {
        flex-grow: 1;
        margin-left: -48px;
      }
    }
  }

  &-form {
    margin-top: 24px;
    .hk-form {
      color: var(--hk-color-vulcan);
      background-color: var(--hk-color-oxygen);
      width: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .create-account-field {
        --background: transparent;
        border-bottom: 1px solid var(--ion-color-medium);
      }
      &-container {
        padding: 16px;
        border-radius: 10px;
        border: 1px solid var(--hk-color-vulcan);
      }
      &-row {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 32px;
        input {
          width: 100%;
          font-family: 'Open Sans', sans-serif;
          font-size: 16px;
          color: var(--hk-color-romulan) !important;
          padding: 16px 0;
          border-radius: 0;
          flex-grow: 1;
          background-color: #fff;
          border: 1px solid #fff;
          border-bottom: 1px solid var(--ion-color-medium);
          &:focus {
            outline: none;
          }
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            color: var(--hk-color-romulan) !important;
            background-color: #fff !important;
            -webkit-box-shadow: 0 0 0 30px white inset !important;
          }
        }
      }
      &-terms {
        margin-top: 32px;
        text-align: center;
        color: var(--hk-color-voyager);
        font-size: 14px;
        &-consent {
          font-size: 16px;
          text-align: left;
          border-bottom: 1px solid var(--ion-color-medium);
          padding-bottom: 16px;
          margin-bottom: 32px;
        }
        &-consent-checkbox {
          display: flex;
          justify-content: start;
          align-items: center;
          margin-top: 16px;
          ion-checkbox {
            --size: 25px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.hk-desktop-create-account-button {
  --box-shadow: none;
  text-transform: none;
  height: 48px;
  width: 100%;
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.hk-desktop-create-account-view-footer {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  color: var(--hk-color-klingon);

  &-link:hover {
    cursor: pointer;
    filter: brightness(0.85);
  }
}

// Mobile View Styles
.hk-create-account-spinner {
  margin-top: 120px;
}
.create-account-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .create-account-header {
    margin-bottom: 24px;
    font-weight: bold;
  }

  h6.create-account-subheader {
    color: var(--ion-color-medium);
    font-weight: 600;
    font-size: 16px;
    max-width: 268px;
    width: 100%;
    margin: auto;
    text-align: center;
  }

  .hk-create-account-options {
    margin: auto;
    margin-top: 80px;
    padding-bottom: 24px;
    &-divider {
      text-align: center;
      margin: 8px 0;
      span {
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        color: var(--ion-color-medium) !important;
        background-color: var(--ion-background-color);
        padding: 10px;
      }
      hr {
        border-width: 1px 0 0 0;
        border-color: var(--ion-color-medium);
        border-style: solid;
        margin: -10px 4px 0 4px;
      }
    }
    &-email {
      font-family: 'Open Sans', sans-serif;
      font-weight: bold;
      font-size: 16px;
      width: 100%;
      padding: 0 6px !important;
    }
    .hk-create-account-email-button {
      --box-shadow: none;
      text-transform: none;
      height: 48px;
      width: 100%;
      font-size: 16px;
      margin-top: 16px;
    }

    .hk-signup-button {
      --box-shadow: none;
      text-transform: none;
      font-family: 'Open Sans', sans-serif;
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
      width: 100%;
      border-radius: 8px;
      height: 46px;
      margin-bottom: 16px;
      background-color: #fff;
      color: var(--hk-color-romulan);
      display: flex;
      align-items: center;
      img {
        margin-left: 24px;
        flex-grow: 0;
        width: 24px;
        height: 24px;
      }
      span {
        flex-grow: 1;
        margin-left: -48px;
      }
    }
  }

  &-form {
    margin-top: 24px;
    .hk-form {
      color: var(--hk-color-vulcan);
      width: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .create-account-field {
        --background: transparent;
        border-bottom: 1px solid var(--ion-color-medium);
      }
      &-container {
        padding: 16px;
        background-color: var(--hk-color-oxygen);
        border-radius: 10px;
        border: 1px solid var(--hk-color-vulcan);
      }
      &-row {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 32px;
        input {
          width: 100%;
          font-family: 'Open Sans', sans-serif;
          font-size: 16px;
          color: var(--hk-color-romulan) !important;
          padding: 16px 0;
          border-radius: 0;
          flex-grow: 1;
          background-color: #fff;
          border: 1px solid #fff;
          border-bottom: 1px solid var(--ion-color-medium);
          &:focus {
            outline: none;
          }
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            color: var(--hk-color-romulan) !important;
            background-color: #fff !important;
            -webkit-box-shadow: 0 0 0 30px white inset !important;
          }
        }
      }
      &-terms {
        margin-top: 32px;
        text-align: center;
        color: var(--hk-color-voyager);
        font-size: 14px;
        &-consent {
          font-size: 16px;
          text-align: left;
          border-bottom: 1px solid var(--ion-color-medium);
          padding-bottom: 16px;
          margin-bottom: 32px;
        }
        &-consent-checkbox {
          display: flex;
          justify-content: start;
          align-items: center;
          margin-top: 16px;
          ion-checkbox {
            --size: 25px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.hk-create-account-view-footer {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  &-link:hover {
    cursor: pointer;
    filter: brightness(0.85);
  }
}

.create-account-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: var(--hk-color-oxygen);
  ion-button {
    --box-shadow: none;
    text-transform: none;
    height: 48px;
    width: 100%;
    font-size: 16px;
  }
}

// Form Validation Styles
.hk-form-validation-container {
  font-size: 14px;
  width: 100%;
}

.hk-form-validation {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  ion-icon {
    font-size: 21px;
    margin-right: 8px;
    color: var(--hk-color-enterprise);
  }
}

.invalid {
  color: var(--hk-color-vulcan);
  ion-icon {
    color: var(--hk-color-vulcan);
  }
}
