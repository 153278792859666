.hk-mobile-web-redirect-modal {
  &::part(content) {
    position: absolute;
    bottom: 0px;
    border-radius: 10px 10px 0 0 !important;
  }
  .hk-mobile-web-redirect-content {
    background-color: var(--hk-color-oxygen);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
    color: var(--ion-color-dark);
    &-header {
      border-bottom: 1px solid var(--ion-color-light-shade);
      padding-bottom: 16px;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
    }
    &-body {
      &-redirect-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 16px;

        .redirect-item-title {
          font-weight: bold;
          display: flex;
          align-items: center;
          &-icon {
            padding: 8px;
            height: 24px;
            width: 24px;
            margin-right: 16px;
            border-radius: 4px;
            color: var(--hk-color-oxygen);
            background-color: var(--ion-color-grey);
          }
        }

        .redirect-item-button {
          --background: var(--ion-color-primary);
          --border-radius: 20px !important;
          --box-shadow: none;
          font-weight: bold;
          color: #fff;
          width: 96px;
          height: 40px;
          text-transform: none;
          letter-spacing: inherit;
        }
      }
    }
  }
}
