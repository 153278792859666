.hk-loading-spinner-container {
  z-index: 1 !important;
  background: var(--ion-color-grey);
}
.hk-loading-spinner {
  width: 100%;
  height: 100%;
  &.logo {
    --background: var(--ion-color-grey);
  }

  &-logo {
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: var(--hk-color-oxygen) !important;
    ion-icon {
      margin: auto auto;
      height: 60px;
      width: 60px;
      -webkit-animation: hk-bouncedelay 1.4s infinite ease-in-out both;
      animation: hk-bouncedelay 1.4s infinite ease-in-out both;
    }
  }
}

@-webkit-keyframes hk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.8);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes hk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
