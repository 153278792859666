.hk-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--hk-color-romulan);
  background: var(--hk-color-oxygen);
  height: 96px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  z-index: 1000;
  &-dark {
    background: var(--hk-color-romulan);
    color: var(--hk-color-oxygen);
  }
}
