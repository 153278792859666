.hk-external-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--hk-color-spock);
  border-radius: 10px;
  padding: 8px 16px;
  cursor: pointer;

  &__name {
    color: var(--hk-color-scotty);
    font-size: 14px;
    font-weight: 600;
    padding: 0;
  }

  &__icon {
    color: var(--hk-color-spock);
    font-size: 16px;
  }
}

.no-url {
  border: 1px solid var(--hk-color-vulcan);
}
