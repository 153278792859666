.hk-address-search-bar-container {
  position: relative;
  width: 100%;
}

ion-searchbar.hk-address-search-bar {
  --box-shadow: 2px 2px 11px 0px rgba(0, 0, 0, 0.25);
  --color: var(--hk-color-klingon);
  padding: 0px;
  input {
    height: 46px !important;
    font-size: 14px !important;
    --background: #fff;
    --border-radius: 4px;
  }
  .searchbar-search-icon {
    color: #000;
    width: 24px;
    height: 24px;
  }
}

.hk-error-address-search-bar-item {
  --background: var(--hk-color-oxygen);
  border-radius: 8px;
  border: 1px solid red;
  margin-bottom: 16px;
  &::part(native) {
    padding-left: 0px;
  }
  &-text {
    padding: 10px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
  }
  &:hover {
    cursor: pointer;
  }
}
.hk-error-address-search-bar-item-selected {
  margin-top: 16px;
  margin-bottom: 0;
  &-icon {
    font-size: 16px;
  }
}

.hk-address-search-bar-item {
  --background: var(--hk-color-oxygen);
  border-radius: 8px;
  border: 1px solid var(--hk-color-enterprise);
  margin-bottom: 16px;
  &::part(native) {
    padding-left: 0px;
  }
  &-text {
    padding: 10px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
  }
  &:hover {
    cursor: pointer;
  }
}

.hk-address-search-bar-item-selected {
  margin-top: 16px;
  margin-bottom: 0;
  &-icon {
    font-size: 16px;
  }
}

#hk-address-search-bar-suggestions {
  box-shadow: 2px 2px 11px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  width: 100%;
  padding: 16px;
  margin-top: 4px;
  background-color: var(--hk-color-oxygen);
  border-radius: 10px;
  z-index: 1000;
}

.searchbar-disabled {
  opacity: 1 !important;
  color: rgba(74, 74, 74, 0.4) !important;
  input {
    --background: var(--ion-background-color) !important;
  }
  .searchbar-search-icon {
    color: rgba(74, 74, 74, 0.4) !important;
  }
}
