// Desktop Styles
.hk-desktop-dashboard-accordion {
  background-color: var(--hk-color-oxygen);
  margin-bottom: 16px;
}

.hk-desktop-dashboard-accordion-header::part(native) {
  background-color: var(--ion-background-color);
  border-radius: 10px;
  font-size: 20px;
  font-weight: 700;
  color: var(--hk-color-klingon);
}
.hk-desktop-dashboard-accordion-content {
  padding: 0px;
  padding-top: 16px;
  ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --inner-border-width: 0;
    margin-bottom: 8px;
  }
}

.hk-desktop-dashboard-accordion-header {
}

ion-list[slot='content'] {
  background-color: var(--hk-color-oxygen);
}

// Mobile Styles
.hk-dashboard-accordion-content {
  padding: 0px;
  padding-top: 6px;
  ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-start: 16px;
    --inner-padding-end: 16px;
    --inner-border-width: 0;
    margin-bottom: 8px;
  }
}

.hk-dashboard-accordion-header {
  font-size: 20px;
  font-weight: 700;
}

ion-list[slot='content'] {
  background-color: var(--hk-color-oxygen);
}
