.hk-menu-header {
  &-title {
    text-align: center;
  }
  .buttons-first-slot {
    margin-left: 8px;
  }
  .buttons-last-slot {
    margin-right: 8px;
  }

  .buttons-first-slot ion-icon,
  .buttons-last-slot ion-icon {
    width: 20px;
    height: 20px;
  }
}
