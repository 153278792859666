.hk-digital-contact-details-actions {
  display: flex;
  justify-content: flex-end;
  padding-right: 24px;
  font-size: 24px;
  &-button {
    --color: var(--hk-color-oxygen);
    --background: var(--hk-color-discovery);
    font-size: 16px;
    font-weight: 600;
    &:hover {
      cursor: pointer;
      --background: var(--hk-color-scotty);
    }
    &-delete {
      color: var(--ion-color-danger) !important;
    }
  }
}
.hk-digital-contact-details {
  --background: var(--ion-color-dark);
  .ion-content {
    --background: var(--ion-color-dark);
  }

  &-header {
    border-bottom: 1px solid var(--ion-color-grey);
    padding: 32px;
    display: flex;
    align-items: center;
    &-avatar {
      height: 48px;
      width: 48px;
      padding: 12px;
      margin-right: 24px;
      font-size: 18px;
      text-align: center;
      font-weight: bold !important;
      color: #fff;
      border-radius: 4px;
      background-color: #4e535b;
    }
    ion-text {
      color: #fff;
      display: block;
      font-size: 18px;
      font-weight: bold;
    }
  }

  ion-list {
    background: var(--ion-color-dark);
  }

  &-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--hk-color-oxygen);
    margin: 0 24px !important;
  }

  &-label {
    margin-top: 24px;
    margin-bottom: 4px;
    font-size: 18px !important;
    font-weight: bold !important;
  }

  &-value {
    margin: 0 !important;
    font-size: 14px !important;
    ion-text {
      display: inline-block;
      height: 22px;
    }
  }

  &-edit-form {
    input {
      width: 100%;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      color: var(--hk-color-oxygen);
      border: none;
      padding: 12px 0;
      background-color: var(--ion-color-dark);
      border-bottom: 1px solid var(--hk-color-oxygen);
      &:focus {
        outline: none;
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        color: var(--hk-color-oxygen) !important;
        background-color: var(--ion-color-dark) !important;
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
    }
    &.ion-color-danger {
      border-bottom: 1px solid var(--ion-color-danger);
    }
    &-select {
      border-bottom: 1px solid var(--hk-color-vulcan);
      margin-bottom: 24px;
      padding: 12px 0;
    }
  }
}

.hk-digital-contact-details-edit-form-picker {
  width: 100%;
  .hk-digital-contact-details-edit-form-picker-input {
    color: var(--hk-color-oxygen);
    border-bottom: 1px solid var(--hk-color-oxygen);
    .native-input {
      padding-left: 0;
    }
    &-icon {
      position: fixed;
      right: 24px;
      top: 270px;
      height: 18px;
      width: 18px;
    }
  }
}

.hk-digital-contact-details-toolbar {
  padding: 8px;
  padding-bottom: 40px;
  ion-button {
    height: 48px;
    font-weight: bold;
    text-transform: capitalize;
  }
}

.invalid-input-error {
  position: absolute;
  right: 0px;
  top: 26px;
  font-size: 13px;
  color: var(--hk-color-uhura);
  ion-icon {
    position: relative;
    top: 3px;
    margin-left: 8px;
    font-size: 16px;
  }
}
