.hk-loading-spinner {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ion-spinner {
    width: 80px;
    height: 80px;
  }
}

// Desktop Styles
.hk-desktop-digital-add-product {
  --background: var(--hk-color-oxygen);
}

.hk-desktop-digital-add-product-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  width: 100%;
  top: 0;
  padding: 16px 24px;
  height: 146px;
  border-bottom: 1px solid var(--hk-color-vulcan);
  background-color: var(--hk-color-oxygen);
  z-index: 1000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  &-title {
    margin-bottom: 4px;
    color: var(--hk-color-oxygen);
    font-size: 18px;
    font-weight: bold;
  }
  &-description {
    color: var(--hk-color-oxygen);
    font-size: 14px;
  }
}

.hk-desktop-digital-add-product-body {
  padding: 24px;
  margin-top: 146px;
  height: 75%;
  overflow: auto;
  &-margin-bottom {
    margin-bottom: 80px;
  }
}

.hk-desktop-digital-add-product-body-select-product {
  background-color: var(--ion-background-color);
  border-radius: 8px;
  font-size: 14px;
  height: 52px;
  margin-bottom: 24px;
  margin-top: 8px;
  &-label {
    color: var(--hk-color-voyager);
  }
}
// Steps Styles
.hk-desktop-digital-add-product-body-steps {
  margin-bottom: 24px;
  &-card {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 8px;
    &-title {
      font-size: 16px;
      font-weight: 600;
      color: var(--hk-color-oxygen);
      border-radius: 10px 10px 0 0;
      padding: 16px 24px;
      background-color: var(--hk-color-scotty);
    }
    &-description {
      border: 1px solid var(--hk-color-vulcan);
      border-radius: 0 0 10px 10px;
      padding: 16px;
      &-image {
        margin-top: 16px;
      }
      &-button {
        --background: var(--hk-color-oxygen);
        --box-shadow: none;
        border: 1px solid var(--hk-color-klingon);
        border-radius: 6px;
        font-size: 16px;
        font-weight: bold;
        color: var(--hk-color-klingon);
        width: 100%;
        height: 48px;
        margin-top: 16px;
        margin-inline: 0;
        ion-icon {
          margin-right: 16px;
        }
      }
    }
  }
}

.product-tag-container {
  display: flex;
  justify-content: space-between;
}
.product-tag {
  border: 1px solid var(--hk-color-romulan);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 100%;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
  &-selected {
    color: var(--hk-color-oxygen);
    background-color: var(--hk-color-scotty);
    border: 1px solid var(--hk-color-scotty);
  }
}

// Video Styles
.hk-desktop-digital-add-product-body-video {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--hk-color-vulcan);
  &-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    color: var(--hk-color-romulan);
  }
  &-content {
    display: flex;
    .video {
      width: 100%;
      margin-right: 16px;
      border-radius: 8px;
      border: 1px solid var(--hk-color-vulcan);
      &-description {
        color: var(--hk-color-romulan);
        &-title {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 8px;
        }
        &-subtitle {
          font-size: 14px;
        }
      }
    }
  }
}

// Note Styles
.hk-desktop-digital-add-product-input {
  height: 52px;
  border-radius: 8px;
  font-size: 14px;
  color: var(--hk-color-voyager);
  background: var(--ion-background-color);
}
.notes {
  margin: 24px 0;
}

// Tag Styles {
.hk-desktop-digital-add-product-body-tags {
  padding-bottom: 24px;
  &-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    color: var(--hk-color-romulan);
  }
  &-content {
    display: flex;
    height: 48px;
    overflow-x: auto;
    .tag {
      background-color: var(--ion-background-color);
      border: 1px solid var(--hk-color-vulcan);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: fit-content;
      border-radius: 4px;
      padding: 4px 8px;
      font-size: 12px;
      font-weight: bold;
      margin-right: 8px;
      white-space: nowrap;
      &-selected {
        background-color: #eff4de;
        border: 1px solid #c5dc64;
      }
    }
  }
}

.hk-desktop-digital-add-product-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--hk-color-oxygen);
  padding: 16px 24px;
  border-top: 1px solid var(--hk-color-vulcan);
  ion-button {
    --box-shadow: none;
    text-transform: none;
    height: 48px;
    width: 200px;
    font-size: 16px;
    margin: 0 8px;
  }
}

// Mobile Styles
.hk-digital-add-product {
  --background: var(--hk-color-oxygen);
}

.hk-digital-add-product-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  width: 100%;
  top: 0;
  padding: 16px 24px;
  height: 146px;
  border-bottom: 1px solid var(--hk-color-vulcan);
  background-color: var(--hk-color-oxygen);
  z-index: 1000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  &-title {
    margin-bottom: 4px;
    color: var(--hk-color-oxygen);
    font-size: 18px;
    font-weight: bold;
  }
  &-description {
    color: var(--hk-color-oxygen);
    font-size: 14px;
  }
}

.hk-digital-add-product-body {
  padding: 24px;
  margin-top: 146px;
  height: 75%;
  overflow: auto;
  &-margin-bottom {
    margin-bottom: 80px;
  }
}

.hk-digital-add-product-body-select-product {
  background-color: var(--ion-background-color);
  border-radius: 8px;
  font-size: 14px;
  height: 52px;
  margin-bottom: 24px;
  margin-top: 8px;
  &-label {
    color: var(--hk-color-voyager);
  }
}
// Steps Styles
.hk-digital-add-product-body-steps {
  margin-bottom: 24px;
  &-card {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 8px;
    &-title {
      font-size: 16px;
      font-weight: 600;
      color: var(--hk-color-oxygen);
      border-radius: 10px 10px 0 0;
      padding: 16px 24px;
      background-color: var(--hk-color-scotty);
    }
    &-subtitle {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      ion-icon {
        margin: 8px 8px 8px 16px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: var(--hk-color-uhura);
        color: var(--hk-color-discovery);
      }
      font-weight: 600;
      color: var(--hk-color-uhura);
      background-color: var(--hk-color-discovery);
    }
    &-description {
      border: 1px solid var(--hk-color-vulcan);
      border-radius: 0 0 10px 10px;
      padding: 16px;
      &-image {
        margin-top: 16px;
      }
      &-button {
        --background: var(--hk-color-oxygen);
        --box-shadow: none;
        border: 1px solid var(--hk-color-klingon);
        border-radius: 6px;
        font-size: 16px;
        font-weight: bold;
        color: var(--hk-color-klingon);
        width: 100%;
        height: 48px;
        margin-top: 16px;
        margin-inline: 0;
        ion-icon {
          margin-right: 16px;
        }
      }
    }
  }
}

.product-tag-container {
  display: flex;
  justify-content: space-between;
}
.product-tag {
  border: 1px solid var(--hk-color-romulan);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 100%;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
  &-selected {
    color: var(--hk-color-oxygen);
    background-color: var(--hk-color-scotty);
    border: 1px solid var(--hk-color-scotty);
  }
}

// Video Styles
.hk-digital-add-product-body-video {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--hk-color-vulcan);
  &-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    color: var(--hk-color-romulan);
  }
  &-content {
    display: flex;
    .video {
      width: 100%;
      margin-right: 16px;
      border-radius: 8px;
      border: 1px solid var(--hk-color-vulcan);
      &-description {
        color: var(--hk-color-romulan);
        &-title {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 8px;
        }
        &-subtitle {
          font-size: 14px;
        }
      }
    }
  }
}

// Note Styles
.hk-digital-add-product-input {
  height: 52px;
  border-radius: 8px;
  font-size: 14px;
  color: var(--hk-color-voyager);
  background: var(--ion-background-color);
}
.notes {
  margin: 24px 0;
}

// Tag Styles {
.hk-digital-add-product-body-tags {
  padding-bottom: 24px;
  &-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    color: var(--hk-color-romulan);
  }
  &-content {
    display: flex;
    height: 48px;
    overflow-x: auto;
    .tag {
      background-color: var(--ion-background-color);
      border: 1px solid var(--hk-color-vulcan);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: fit-content;
      border-radius: 4px;
      padding: 4px 8px;
      font-size: 12px;
      font-weight: bold;
      margin-right: 8px;
      white-space: nowrap;
      &-selected {
        background-color: #eff4de;
        border: 1px solid #c5dc64;
      }
    }
  }
}

.hk-digital-add-product-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--hk-color-oxygen);
  padding: 16px 24px;
  padding-bottom: 40px;
  ion-button {
    --box-shadow: none;
    text-transform: none;
    height: 48px;
    font-size: 16px;
  }
}
