// Desktop Styles
.hk-loading-spinner {
  margin-top: 80px;
}

.hk-desktop-digital-catalog-page {
  top: 120px !important;
}
.hk-desktop-digital-catalog-header {
  justify-content: center;
  background-color: var(--hk-color-discovery);
}

.hk-desktop-digital-catalog-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  padding: 8px 8px 8px 32px;
}
.hk-desktop-digital-catalog-header-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  height: 12px;
  &-bar {
    --background: var(--hk-color-vulcan);
    height: 12px;
    width: 200px;
    border-radius: 6px;
  }
  &-percentage {
    margin-left: 20px;
    font-size: 14px;
    font-weight: bold;
    color: var(--hk-color-oxygen);
  }
}
.hk-desktop-digital-catalog-body {
  display: flex;
  align-items: flex-start;
  max-width: 1440px;
  padding: 0 24px;
  margin: auto;
}

.hk-desktop-digital-catalog-video {
  position: sticky;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  &-label {
    margin-top: 20px;
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: bold;
  }
  &-player {
    height: 146px !important;
    width: 260px !important;
  }
}
.hk-desktop-digital-catalog-video-text {
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: var(--hk-color-romulan);
  margin-bottom: 24px;
  padding: 8px;
  background-color: #e0f0fa;
  border: 1px solid var(--hk-color-spock);
  border-top: 0;
  border-radius: 0 0 10px 10px;
  ion-button {
    margin-top: 8px;
    height: 44px;
  }
}

.hk-desktop-digital-catalog-task-list {
  width: 100%;
  margin: 16px 32px 24px 0px;
  .hk-accordion {
    .hk-accordion-header::part(native) {
      height: 60px;
    }
    .hk-accordion-header-task {
      height: 60px;
      width: 60px;
    }
    ion-label {
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.hk-desktop-digital-catalog-task {
  .hk-desktop-task-card-body {
    height: 100px;
  }
  .hk-desktop-task-card-body-title {
    font-size: 18px;
  }
  .hk-desktop-task-card-body-description {
    font-size: 16px;
    font-weight: initial;
    color: var(--hk-color-klingon);
  }
}

// Mobile Styles
.hk-digital-catalog-progress {
  position: sticky;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  height: 28px;
  background-color: var(--hk-color-oxygen);
  z-index: 1000;
  &-bar {
    --background: var(--hk-color-vulcan);
    height: 12px;
    border-radius: 6px;
  }
  &-percentage {
    margin-left: 20px;
    font-size: 14px;
    font-weight: bold;
    color: var(--hk-color-klingon);
  }
}

.hk-digital-catalog-video {
  margin: 16px;
  margin-bottom: 0;
  border-radius: 10px;
  max-height: 202px;
  height: 100%;
}

.hk-digital-catalog-video-text {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: var(--hk-color-romulan);
  margin: 0 16px;
  margin-bottom: 24px;
  margin-top: -2px;
  padding: 8px 14px;
  background-color: #e0f0fa;
  border: 1px solid var(--hk-color-spock);
  border-top: 0;
  border-radius: 0 0 10px 10px;
  ion-button {
    margin-top: 8px;
    height: 44px;
  }
}

.hk-digital-catalog-task-list {
  margin: 0 16px;
}

.hk-digital-catalog-task {
  height: 112px;
  .hk-task-card-body-description {
    font-size: 12px;
    font-weight: initial;
    color: var(--hk-color-klingon);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .hk-task-card-thumbnail-img {
    height: 78px;
    width: 78px;
  }
}
