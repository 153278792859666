// Desktop Styles
.hk-desktop-password-reset-view {
  &-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  h3.hk-desktop-password-reset-view-title {
    font-size: 24px;
    margin-top: 80px;
  }
  h3.hk-desktop-password-reset-view-title {
    font-size: 24px;
    margin-bottom: 8px;
  }
  h6.hk-desktop-password-reset-view-subheader {
    color: var(--hk-color-voyager);
    font-weight: 600;
    margin: auto;
    max-width: 348px;
  }
  .hk-desktop-password-reset-view-content-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 350px;
    padding: 40px 24px;
    margin-top: 40px;
    margin-bottom: 32px;
    border: 1px solid var(--hk-color-vulcan);
    border-radius: 10px;
    background-color: #ffffff;
  }
  .hk-desktop-password-reset-view-content {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .hk-auth-button {
    width: 100%;
  }
  ion-button {
    --padding-start: 0;
    --border-radius: 8px !important;
    width: 100%;
    button.button-native::part(native) {
      .button-inner {
        justify-content: flex-start !important;
      }
    }
  }
  .hk-desktop-password-reset-view-reset-form {
    .hk-form {
      width: 100%;
      margin: auto;
      &-actions {
        margin-top: 8px;
      }
      &-row {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        input {
          width: 100%;
          font-family: 'Open Sans', sans-serif;
          font-size: 16px;
          color: var(--ion-color-medium) !important;
          padding: 0 0 16px 0;
          margin-bottom: 32px;
          border-radius: 0;
          flex-grow: 1;
          background-color: #fff;
          border: 1px solid #fff;
          border-bottom: 1px solid var(--ion-color-medium);
          &:focus {
            outline: none;
          }
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            color: var(--ion-color-medium) !important;
            background-color: #fff !important;
            -webkit-box-shadow: 0 0 0 30px white inset !important;
          }
        }
        &-actions {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

// Form Validation Styles
.hk-form-validation-container {
  font-size: 14px;
  width: 100%;
}

.hk-form-validation {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  ion-icon {
    font-size: 21px;
    margin-right: 8px;
    color: var(--hk-color-enterprise);
  }
}

.invalid {
  color: var(--hk-color-vulcan);
  ion-icon {
    color: var(--hk-color-vulcan);
  }
}

// Mobile Styles
.hk-password-reset-view {
  &-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  h3.hk-password-reset-view-title {
    font-size: 24px;
    margin-top: 32px;
  }
  h3.hk-password-reset-view-title {
    font-size: 24px;
    margin-bottom: 8px;
  }
  h6.hk-password-reset-view-subheader {
    color: var(--hk-color-voyager);
    font-weight: 600;
    margin: 0;
  }
  .hk-password-reset-view-content-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 40px 24px;
    margin-top: 40px;
    margin-bottom: 16px;
    border: 1px solid var(--hk-color-vulcan);
    border-radius: 10px;
    background-color: #ffffff;
  }
  .hk-password-reset-view-content {
    width: 100%;
    padding: 0 16px;
  }
  .hk-auth-button {
    width: 100%;
  }
  ion-button {
    --padding-start: 0;
    --border-radius: 8px !important;
    width: 100%;
    button.button-native::part(native) {
      .button-inner {
        justify-content: flex-start !important;
      }
    }
  }
  .hk-password-reset-view-reset-form {
    .hk-form {
      width: 100%;
      margin: auto;
      &-actions {
        margin-top: 8px;
      }
      &-row {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        input {
          width: 100%;
          font-family: 'Open Sans', sans-serif;
          font-size: 16px;
          color: var(--ion-color-medium) !important;
          padding: 0 0 16px 0;
          margin-bottom: 32px;
          border-radius: 0;
          flex-grow: 1;
          background-color: #fff;
          border: 1px solid #fff;
          border-bottom: 1px solid var(--ion-color-medium);
          &:focus {
            outline: none;
          }
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            color: var(--ion-color-medium) !important;
            background-color: #fff !important;
            -webkit-box-shadow: 0 0 0 30px white inset !important;
          }
        }
        &-actions {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
