.hk-digital-settings {
  --background: var(--hk-color-discovery);
  --color: var(--hk-color-oxygen);
}

.hk-digital-settings-header {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  border-bottom: 1px solid var(--hk-color-scotty);
  background-color: var(--hk-color-discovery);
  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    margin-top: 60px;
    padding-left: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--hk-color-scotty);
  }
  &-search {
    width: 100%;
    padding: 4px 0;
    p {
      margin: 12px 0 24px;
      padding: 0 16px;
    }
  }
}

.hk-digital-settings-list {
  background-color: var(--hk-color-discovery);
}

.hk-digital-settings-toggles {
  padding: 16px;
  border-bottom: 1px solid var(--hk-color-scotty);
  &-group-name {
    margin: 0;
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: bold;
  }
  &-product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #4e535b;
    border-radius: 10px;
    padding: 16px 12px;
    margin-bottom: 4px;
    &-label {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      border-radius: 16px;
      padding: 4px 8px;
      background-color: #e0f5f2;
      color: #00685a;
      font-size: 12px;
      font-weight: bold;
      text-transform: capitalize;
    }
    &-toggle {
      min-height: 24px;
      min-width: 46px;
    }
  }
}

.hk-digital-settings-search-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 60vh;
  padding: 0 40px;
  h1 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    padding-bottom: 8px;
  }
  p {
    font-weight: 600;
    &:last-child {
      margin: 0;
    }
  }
}
