@import 'src/theme/variables.scss';

// Desktop Styles
.desktop-onboarding-1-header-container {
  margin-top: 120px;
  z-index: 1000;
  background-color: var(--hk-color-discovery);
  color: var(--hk-color-oxygen);
  padding: 40px;
  text-align: center;
  height: 134px;
  .desktop-onboarding-1-header {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 4px;
  }
  .desktop-onboarding-1-subheader {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 8px;
  }
}

.desktop-onboarding-1-view-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.desktop-onboarding-1-view-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: var(--hk-color-oxygen);
  border-radius: 8px;
  margin-bottom: 8px;
  border: 1px solid var(--hk-color-oxygen);
  width: 600px;
  height: 100px;
  cursor: pointer;
  &-body {
    &-title {
      font-size: 16px;
      font-weight: bold;
    }
    &-description {
      margin-top: 8px;
      color: var(--hk-color-klingon);
      font-size: 14px;
      max-width: 260px;
    }
  }
  &-checkmark {
    margin-left: 24px;
    ion-icon {
      font-size: 24px;
    }
    &-circle {
      height: 24px;
      width: 24px;
      background-color: var(--hk-color-oxygen);
      border-radius: 50%;
      border: 1px solid var(--ion-color-primary);
    }
  }
  &-selected {
    background-color: tint(#00d2b7, 85);
    border: 1px solid var(--ion-color-primary);
  }
}

.desktop-onboarding-1-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.desktop-onboarding-1-button {
  margin-top: 16px;
  --padding-start: 0;
  --border-radius: 8px !important;
  --box-shadow: none;
  text-transform: none;
  height: 48px;
  width: 200px;
  font-size: 16px;
  font-weight: bold;
  &::part(native) {
    padding: 0 !important;
  }
}

// Mobile Styles
.onboarding-1-header-container {
  z-index: 1000;
  background-color: var(--hk-color-scotty);
  color: var(--hk-color-oxygen);
  padding: 16px 24px;
  padding-top: 64px;
  .onboarding-1-header {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    width: 60%;
    margin-top: 0;
  }
  .onboarding-1-subheader {
    font-size: 14px;
    margin-bottom: 0;
  }
}

.onboarding-1-view-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px 16px 16px;
  background-color: var(--hk-color-oxygen);
  border-radius: 8px;
  margin-bottom: 8px;
  border: 1px solid var(--hk-color-oxygen);
  &-body {
    &-title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 8px;
    }
    &-description {
      color: var(--hk-color-klingon);
      font-size: 14px;
    }
  }
  &-checkmark {
    margin-left: 24px;
    ion-icon {
      font-size: 24px;
    }
    &-circle {
      height: 24px;
      width: 24px;
      background-color: var(--hk-color-oxygen);
      border-radius: 50%;
      border: 1px solid var(--ion-color-primary);
    }
  }
  &-selected {
    background-color: tint(#00d2b7, 85);
    border: 1px solid var(--ion-color-primary);
  }
}

.onboarding-1-toolbar {
  padding: 0 16px 16px 16px;
}

.onboarding-1-button {
  margin-top: 24px;
  --padding-start: 0;
  --border-radius: 8px !important;
  --box-shadow: none;
  text-transform: none;
  height: 48px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  button.button-native::part(native) {
    .button-inner {
      justify-content: flex-start !important;
    }
  }
}
