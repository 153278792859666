@import 'src/theme/variables.scss';

// Desktop Styles
.hk-recurly-view {
  --background: #fff !important;

  .hk-desktop-recurly {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    &-container {
      max-width: 700px;
    }
  }
  .hk-illustration img {
    max-width: 240px;
    margin-top: 64px;
  }

  .hk-desktop-recurly-header {
    margin-top: 36px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  h6.hk-desktop-recurly-subheader {
    color: var(--ion-color-medium);
    font-weight: 600;
    font-size: 16px;
    max-width: 326px;
    margin: auto;
  }

  .hk-desktop-subscription-card-container {
    display: flex;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 8px;
  }

  .hk-desktop-subscription-card {
    height: 140px;
    width: 346px;
    margin-right: 8px;
    padding: 24px 16px;
    border-radius: 10px;
    border: 1px solid var(--hk-color-vulcan);
    &-title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 24px;
    }
    &-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .pill {
        margin-left: 8px;
        background-color: tint(#00d2b7, 85);
        font-size: 12px;
        font-weight: bold;
        color: #00685a;
        border-radius: 16px;
        padding: 4px 16px;
      }
      .amount {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .hk-desktop-total-card {
    height: 140px;
    width: 346px;
    padding: 24px 16px;
    border-radius: 10px;
    border: 1px solid var(--hk-color-vulcan);
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-body {
      font-size: 18px;
    }
  }
}

// Desktop Styles
.hk-desktop-signup-checkout-page {
  background-color: var(--hk-color-oxygen);
}

.hk-desktop-signup-checkout-page-header {
  background-color: var(--hk-color-discovery);
  ion-breadcrumbs {
    padding: 16px 32px;
    margin: auto;
    max-width: 1440px;
    font-size: 16px;
  }
  ion-breadcrumb {
    color: var(--hk-color-enterprise);
  }
  .breadcrumb-active {
    color: var(--hk-color-oxygen);
  }
}

.hk-desktop-signup-checkout-page-container {
  max-width: 1440px;
  width: 100%;
  padding: 32px;
  margin: auto;
  margin-top: 0;
  display: flex;
  align-items: start;
  justify-content: space-between;
  color: var(--hk-color-romulan);
}

// Credit Card Styles
.hk-desktop-signup-checkout-card-section {
  width: 100%;
}
.hk-desktop-signup-checkout-card-header {
  background-color: var(--hk-color-oxygen);
  border: 1px solid var(--hk-color-vulcan);
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 16px;
  h2 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }
  p {
    font-size: 16px;
    font-weight: 600;
    color: var(--hk-color-voyager);
    margin: 0;
    padding-top: 8px;
  }
}

.hk-desktop-signup-checkout-card-container {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: start;
  padding: 24px;
  border-radius: 10px;
  border: 1px solid var(--hk-color-vulcan);
  .hk-desktop-signup-checkout-card-icon {
    width: 108px;
    height: 60px;
    margin-bottom: 32px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid var(--hk-color-vulcan);
    font-size: 12px;
    font-weight: bold;
    ion-icon {
      font-size: 24px;
    }
  }
}

.hk-billing-checkout-form {
  padding: 24px;
}

.hk-billing-checkout-button {
  width: 100%;
  padding: 0 24px;
}

.hk-desktop-recurly-form-back-button {
  cursor: pointer;
  margin-right: 16px;
  height: 48px;
  width: 200px;
  border-radius: 10px;
  border: 1px solid var(--hk-color-klingon);
  background-color: var(--hk-color-oxygen);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}

// Summary Styles
.hk-desktop-signup-checkout-summary-section {
  min-width: 390px;
  .hk-billing-summary-content {
    margin-top: 0;
    padding-top: 0;
    background-color: inherit;
  }
  .hk-billing-summary-subscription {
    background-color: var(--hk-color-oxygen);
  }
  .hk-billing-summary-due {
    background-color: var(--hk-color-oxygen);
  }
}

// Mobile Styles
.hk-recurly {
  margin-top: 80px;
}
.hk-recurly-view {
  --background: #fff !important;
  .hk-recurly-header {
    margin-top: 36px;
    font-weight: bold;
  }

  h6.hk-recurly-subheader {
    color: var(--ion-color-medium);
    font-weight: 600;
    font-size: 16px;
  }

  .subscription-card {
    margin-top: 24px;
    padding: 24px 16px;
    border-radius: 10px;
    border: 1px solid var(--hk-color-vulcan);
    &-title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 24px;
    }
    &-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .pill {
        margin-left: 8px;
        background-color: tint(#00d2b7, 85);
        font-size: 12px;
        font-weight: bold;
        color: #00685a;
        border-radius: 16px;
        padding: 4px 16px;
      }
      .amount {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .total-card {
    margin: 16px 0;
    padding: 24px 16px;
    border-radius: 10px;
    border: 1px solid var(--hk-color-vulcan);
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-body {
      font-size: 18px;
    }
  }
}

.hk-recurly-hidden {
  display: none;
}

.hk-desktop-signup-checkout-zipcode-error {
  margin-top: 16px;
  p {
    font-size: 16px;
    font-weight: 600;
    color: var(--hk-color-riker);
  }
}

.address-bar-zipcode-error {
  .hk-address-search-bar-item-selected {
    border: 1px solid var(--hk-color-riker);
  }
}
