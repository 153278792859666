.hk-checkout-content {
  --background: var(--hk-color-oxygen) !important;
}

.hk-billing-checkout-content {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  text-align: center;
  h2 {
    margin-top: 0;
    padding-top: 0px;
    font-weight: bold;
    font-size: 24px;
    color: var(--hk-color-scotty);
  }
  p {
    font-weight: 600;
    font-size: 16px;
    color: var(--hk-color-voyager);
  }
}

.hk-billing-checkout {
  width: 100%;
  border: 1px solid var(--hk-color-vulcan);
  border-radius: 10px;
  padding: 24px 16px;
  &-header {
    height: 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    h4 {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.hk-billing-checkout-due {
  margin-top: 16px;
  width: 100%;
  border: 1px solid var(--hk-color-vulcan);
  border-radius: 10px;
  padding: 24px 16px;
  &-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-name {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
    }
    &-price {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.hk-billing-checkout-address {
  margin-top: 16px;
  width: 100%;
  border: 1px solid var(--hk-color-enterprise);
  border-radius: 10px;
  padding: 16px;
  &-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-text {
      text-align: left;
      font-size: 14px;
      display: flex;
      flex-direction: column;
    }
    &-icon {
      font-size: 24px;
    }
  }
  &-error {
    border: 1px solid var(--hk-color-riker);
  }
}

.hk-billing-checkout-saved-card-container {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  padding: 24px 16px;
  border-radius: 10px;
  border: 1px solid var(--hk-color-vulcan);
  .hk-billing-checkout-card-icon {
    width: 108px;
    height: 60px;
    margin-bottom: 16px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid var(--hk-color-vulcan);
    font-size: 12px;
    font-weight: bold;
    ion-icon {
      font-size: 24px;
    }
  }
  .hk-billing-checkout-saved-card-body {
    width: 100%;
  }

  .hk-saved-card {
    display: flex;
    justify-content: space-between;
    background-color: #d9f9f4;
    padding: 16px;
    width: 100%;
    border-radius: 10px;
    border: 2px solid var(--hk-color-enterprise);
    &-info-container {
      display: flex;
    }
    &-icon {
      ion-icon {
        height: 24px;
        width: 40px;
      }
      margin-right: 8px;
    }
    &-info {
      display: flex;
      flex-direction: column;
      &-card-number {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 4px;
      }
      &-expiration {
        font-weight: 14px;
        font-weight: 600;
        color: var(--hk-color-klingon);
        margin-bottom: 4px;
      }
      &-remove {
        font-size: 14px;
        font-weight: 600;
        text-decoration: underline;
      }
    }
    &-check {
      font-size: 20px;
    }
  }

  .hk-billing-checkout-saved-card-new {
    margin-top: 24px;
    height: 48px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--hk-color-klingon);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }
}

.hk-billing-checkout-form {
  padding: 24px;
}

.hk-billing-checkout-button {
  width: 100%;
  padding: 0 24px;
}

.hk-billing-checkout-zipcode-error {
  margin-top: 16px;
  p {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    color: var(--hk-color-riker);
    margin-bottom: 0;
  }
}
