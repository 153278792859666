.digital-dashboard-settings-skeleton {
  --background: var(--hk-color-discovery);
}

.digital-dashboard-settings-skeleton-header {
  padding: 16px;
  border-bottom: 1px solid var(--hk-color-scotty);
}

.digital-dashboard-settings-skeleton-body {
  padding: 16px;
}

.hk-skeleton-rectangle {
  background-color: #4e535b;
  height: 56px;
  border-radius: 8px;
}

.hk-skeleton-oval {
  background-color: #4e535b;
  height: 16px;
  border-radius: 100px;
}
