.hk-digital-item-detail {
  --background: #fff;
  &-header {
    flex-direction: column;
    align-items: center;
  }
  &-image-slider img {
    max-width: 80%;
    margin: 0 auto 50px auto;
    max-height: 340px;
  }
  img.hk-image-placeholder {
    /*height: 64px;*/
    width: 50%;
    margin: 64px auto;
    display: inline;
    opacity: 0.1;
  }

  ion-list,
  ion-list-header,
  ion-item {
    --background: #fff !important;
    background-color: #fff !important;
  }
  ion-list ion-item {
    --inner-padding-start: 0;
  }

  &-section-header {
    margin-top: 16px;
    padding-top: 16px !important;
    border-top: 1px solid var(--ion-color-medium);
    font-size: 18px;
    font-weight: bold;
  }

  &-attribute {
    font-size: 16px;
    line-height: 18px;
    .ion-text-end {
      font-weight: bold;
      color: var(--ion-color-step-250);
    }
  }

  &-document-slider {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    &-slide {
      width: 160px;
      margin-right: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-img {
        // border: 1px solid var(--ion-color-medium);
        // border-radius: 4px;
        width: 144px;
        height: 144px;
      }
    }
  }
}

.hk-digital-item-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  padding-bottom: 40px;
  background-color: var(--hk-color-oxygen);
  ion-button {
    --box-shadow: none;
    text-transform: none;
    height: 48px;
    font-size: 16px;
  }
}
