.digital-dashboard-skeleton-header {
  width: 100%;
  margin-bottom: 24px;
}
.digital-dashboard-skeleton-toggle-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
}
.digital-dashboard-skeleton-toggle {
  width: 90px;
  margin-right: 16px;
}
.digital-dashboard-skeleton-cog {
  width: 34px;
  height: 34px;
}
.digital-dashboard-skeleton-title {
  width: 100%;
  margin-bottom: 18px;
}
.digital-dashboard-skeleton-card {
  width: 100%;
  margin-bottom: 8px;
}
