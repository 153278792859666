// Desktop Styles
.hk-desktop-digital-documents-list-page {
  top: 120px !important;
}
.hk-desktop-digital-documents-list-header {
  justify-content: center;
  background-color: var(--hk-color-discovery);
}
.hk-desktop-digital-documents-list-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  padding: 0 32px;
}
.hk-desktop-digital-documents-list-header-right {
  display: flex;
  align-items: center;
}
.hk-desktop-digital-documents-list-header-breadcrumbs {
  ion-breadcrumb {
    font-weight: 600;
    color: var(--hk-color-enterprise);
    text-transform: capitalize;
  }
  .breadcrumb-active {
    color: var(--hk-color-oxygen);
  }
}
.hk-desktop-digital-documents-list-search {
  margin-right: 24px;
  .hk-search-bar {
    padding: 16px 0;
    width: 358px;
    color: var(--ion-color-grey);
    .searchbar-input {
      height: 48px;
    }
    .searchbar-search-icon {
      width: 24px;
      top: 14px;
    }
  }
}
.hk-desktop-digital-documents-list-header-button {
  ion-button {
    --box-shadow: none;
    text-transform: none;
    height: 48px;
    font-size: 16px;
    margin: 16px 0;
  }
}

.hk-desktop-digital-document-list {
  max-width: 1440px;
  margin: auto;
  margin-top: 32px;
  padding: 0 32px;
  &.photos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  &-img {
    border-radius: 8px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 30%;
    height: 0;
    padding-top: 45%;
    margin: 8px;
  }
}

// Mobile Styles
.hk-digital-document-list {
  .hk-list-card:first-child {
    margin-top: 0;
  }

  .hk-list-card.no-image .hk-list-card-thumbnail img {
    transform: scale(0.6);
    color: var(--ion-color-medium);
    opacity: 0.2;
  }
  .hk-list-card-title {
    margin: revert;
    white-space: normal;
  }
  &.photos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  &-img {
    border-radius: 8px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 45%;
    height: 0;
    padding-top: 45%;
    margin: 8px;
  }
}

.hk-digital-documents-list-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: var(--ion-background-color);
}

.hk-mobile-digital-documents-list-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px 40px 20px;
  background-color: var(--ion-background-color);
}
ion-button {
  --box-shadow: none;
  text-transform: none;
  height: 48px;
  font-size: 16px;
}

// Document Viewer Modal
.hk-document-modal-body {
  overflow: scroll;
}
.hk-document-modal-download-button {
  width: 350px;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 16px;
}

#spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
