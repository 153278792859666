.hk-digital-catalog-complete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 32px;
  h3 {
    margin-top: 32px;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
  }
  p {
    font-size: 16px;
    font-weight: 600;
    color: var(--hk-color-klingon);
  }
}
