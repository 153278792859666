@import 'src/theme/variables.scss';

// Desktop View Styles
.hk-desktop-appointment-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 8px;
  background-color: var(--hk-color-oxygen);
  padding: 24px;
  padding-right: 40px;
  color: var(--hk-color-romulan);
  --min-height: 142x;
  height: 142px;
  &-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 64px;
    .hk-desktop-season-icon {
      min-width: 72px;
      height: 48px;
      width: 72px;
      margin-right: 24px;
      color: var(--hk-color-oxygen);
    }
    &__name {
      font-size: 15px;
      font-weight: bold;
      white-space: normal;
      &--completed {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        .hk-complete-pill {
          font-family: 'Open Sans', sans-serif;
          font-weight: bold;
          font-size: 10px;
          width: 65px;
          border-radius: 3px;
          padding: 3px 8px;
          margin-top: 4px;
          border: 1px solid var(--hk-color-enterprise);
          background-color: tint(#00d2b7, 85);
        }
      }
    }
  }

  &-body {
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    margin-right: 64px;
    &-details {
      white-space: nowrap;
    }
    ion-thumbnail {
      width: 48px;
      height: 48px;
      margin-right: 8px;
      img {
        border-radius: 4px;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 33%;
    font-size: 16px;
    &__details {
      display: flex;
      flex-direction: column;
      width: 100%;
      &-title {
        margin-bottom: 4px;
      }
    }
    ion-icon {
      font-size: 24px;
    }
  }

  &.finished {
    background-color: #fff;
    color: #000 !important;
  }

  &.finished &-footer {
    justify-content: space-between;
    align-items: center;
  }

  .hk-desktop-details-button {
    min-width: 200px;
    width: 200px;
    height: 44px;
    --background: var(--ion-color-primary);
    --border-radius: 8px !important;
    --box-shadow: none;
    font-weight: bold;
    color: #fff;
    text-transform: none;
    letter-spacing: inherit;
  }
}

// Mobile View Styles
.hk-appointment-card {
  display: block;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 16px;

  &-header-wrapper {
    background-color: var(--ion-color-grey);
    padding: 16px;
  }

  &-header {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;

    &-name {
      font-size: 15px;
      font-weight: bold;
      white-space: normal;
      &--completed {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        .hk-complete-pill {
          font-family: 'Open Sans', sans-serif;
          font-weight: bold;
          font-size: 10px;
          width: 65px;
          border-radius: 3px;
          padding: 3px 8px;
          margin-top: 4px;
          border: 1px solid var(--hk-color-enterprise);
          background: tint(#00d2b7, 85);
        }
      }
    }

    .hk-season-icon {
      width: 40px;
      height: 24px;
      margin-right: 8px;
      color: var(--hk-color-scotty);
    }
  }

  &-body-wrapper {
    background-color: var(--hk-color-oxygen);
    padding: 0 8px 8px 8px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &.finished .hk-appointment-card-body-wrapper {
    background-color: #fff;
    color: #000;
  }

  &-body {
    font-size: 14px;
    border-top: 1px solid #fff;
    width: 100%;
    display: flex;
    padding: 16px 8px 0 8px;
    &-details-name {
      margin-bottom: 4px;
    }
    ion-thumbnail {
      width: 48px;
      height: 48px;
      margin-right: 8px;
      img {
        border-radius: 4px;
      }
    }

    p {
      margin-top: 0;
      font-size: 14px;
    }

    ion-button {
      font-weight: bold;
      width: 86px;
      height: 28px;
      border-radius: 14px;
    }
  }

  &-footer {
    display: flex;
    padding: 16px 8px 24px 8px;
    font-size: 14px;
    align-items: center;

    span {
      display: flex;
      align-items: center;
    }
    ion-icon {
      margin: 0 8px 0 0;
    }
  }

  &.finished &-footer {
    align-items: center;
  }

  ion-button {
    font-weight: bold;
    height: 44px;
    width: 100%;
    --border-radius: 8px;
  }

  .hk-details-button {
    --background: var(--ion-color-primary);
    color: #fff;
    font-size: 16px;
    &.disabled {
      --background: var(--ion-color-step-900);
      color: var(--ion-color-step-500);
    }
  }
}
