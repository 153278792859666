.hk-digital-edit-contacts-modal-actions {
  display: flex;
  justify-content: end;
  padding-right: 4px;
  font-size: 24px;
  cursor: pointer;
  &-button {
    --color: var(--hk-color-oxygen);
    --background: var(--hk-color-discovery);
    font-size: 16px;
    font-weight: 600;
    &:hover {
      --background: var(--hk-color-scotty);
    }
    &-delete {
      color: var(--ion-color-danger) !important;
    }
  }
}
.hk-digital-edit-contacts-modal-details {
  --background: var(--hk-color-oxygen);
  .ion-content {
    --background: var(--hk-color-oxygen);
  }

  &-header {
    border-bottom: 1px solid var(--hk-color-vulcan);
    padding: 84px 24px 24px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-avatar-container {
      display: flex;
      align-items: center;
    }
    &-avatar {
      min-height: 48px;
      min-width: 48px;
      padding: 12px;
      margin-right: 24px;
      font-size: 18px;
      text-align: center;
      font-weight: bold !important;
      color: #fff;
      border-radius: 4px;
      background-color: var(--hk-color-scotty);
    }
    ion-text {
      color: var(--hk-color-scotty);
      display: block;
      font-size: 21px;
      font-weight: bold;
    }
  }

  ion-list {
    background: var(--hk-color-oxygen);
  }

  &-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--hk-color-scotty);
    margin: 0 24px !important;
  }

  &-label {
    margin-top: 24px;
    margin-bottom: 4px;
    font-size: 18px !important;
    font-weight: bold !important;
  }

  &-value {
    margin: 0 !important;
    font-size: 14px !important;
    color: var(--hk-color-voyager);
    ion-text {
      display: inline-block;
      height: 22px;
    }
  }

  &-edit-form {
    input {
      width: 100%;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      color: var(--ion-color-dark);
      border: none;
      padding: 12px 0;
      background-color: var(--hk-color-oxygen);
      border-bottom: 1px solid var(--hk-color-vulcan);
      &:focus {
        outline: none;
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        color: var(--ion-color-dark) !important;
        background-color: var(--hk-color-oxygen) !important;
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
    }
    &.ion-color-danger {
      border-bottom: 1px solid var(--ion-color-danger);
    }
  }

  &-select {
    border-bottom: 1px solid var(--hk-color-vulcan);
    margin-bottom: 24px;
    padding: 12px 0;
  }
}

.hk-digital-edit-contacts-modal-details-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--hk-color-oxygen);
  padding: 16px 24px;
  border-top: 1px solid var(--hk-color-vulcan);
  ion-button {
    --box-shadow: none;
    text-transform: none;
    height: 48px;
    width: 200px;
    font-size: 16px;
    margin: 0 8px;
  }
}

.invalid-input-error {
  position: absolute;
  right: 24px;
  top: 24px;
  font-size: 13px;
  color: var(--hk-color-picard);
  ion-icon {
    position: relative;
    top: 3px;
    margin-left: 8px;
    font-size: 16px;
  }
}

.hk-contact-type-alert .alert-wrapper {
  min-width: 400px;
}
