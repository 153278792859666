.hk-terms-and-conditions {
  color: var(--hk-color-scotty);
  padding: 16px;
  &-header {
    &-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-icon {
        height: 40px;
        width: 40px;
        margin: 24px 0;
      }
      &-text {
        font-size: 24px;
        font-weight: bold;
      }
      &-subtext {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        padding: 24px 0;
        border-bottom: 1px solid var(--hk-color-vulcan);
      }
    }
  }

  &-body {
    &-text {
      color: var(--hk-color-voyager);
      font-size: 14px;
      text-align: center;
      line-height: 20px;
      padding-top: 24px;
      padding-bottom: 32px;
    }
    ion-button {
      --box-shadow: none;
      --border-radius: 10px;
      text-transform: none;
      height: 48px;
      width: 100%;
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
}
