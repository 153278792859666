// Desktop Styles
.hk-desktop-recurly--width {
  width: 310px !important;
}

.hk-desktop-recurly-button {
  width: 342px !important;
}

.hk-desktop-recurly-form-container {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: start;
  padding: 24px 16px;
  border-radius: 10px;
  border: 1px solid var(--hk-color-vulcan);
  background-color: var(--hk-color-oxygen);
  .hk-desktop-recurly-form-inputs {
    display: flex;
    width: 100%;
  }
  .hk-desktop-recurly-credit-card {
    width: 108px;
    height: 60px;
    margin-bottom: 16px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid var(--hk-color-vulcan);
    font-size: 12px;
    font-weight: bold;
    ion-icon {
      font-size: 24px;
    }
  }
  .hk-desktop-recurly-terms-and-conditions {
    margin-top: 32px;
    text-align: justify;
    font-size: 14px;
    color: var(--hk-color-voyager);
  }
}

.hk-desktop-recurly-form-actions {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 24px;
  ion-button {
    --padding-start: 0;
    --border-radius: 8px !important;
    --box-shadow: none;
    text-transform: none;
    height: 48px;
    width: 100%;
    font-size: 16px;
    width: 100%;
    font-weight: bold;
    button.button-native::part(native) {
      .button-inner {
        justify-content: flex-start !important;
      }
    }
  }
}

.hk-desktop-recurly-form {
  input {
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: var(--hk-color-romulan) !important;
    padding: 16px 0 16px 2px;
    border-radius: 0;
    flex-grow: 1;
    background-color: #fff;
    border: 1px solid #fff;
    border-bottom: 1px solid var(--ion-color-medium);
    line-height: 30px;
    &:focus {
      outline: none;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      color: var(--ion-color-medium) !important;
      background-color: #fff !important;
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }
  .recurly-element {
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  &-first-name {
    margin-right: 16px;
  }
  &-field {
    font-weight: 500;
  }
  &-card-number {
    margin-right: 16px;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: var(--hk-color-romulan) !important;
    padding: 16px 0;
    border-radius: 0;
    flex-grow: 1;
    background-color: #fff;
    border: 1px solid #fff;
    border-bottom: 1px solid var(--ion-color-medium);
    &:focus {
      outline: none;
    }
  }
  &-card-month {
    margin-right: 16px;
    width: 15%;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: var(--hk-color-romulan) !important;
    padding: 16px 0;
    border-radius: 0;
    flex-grow: 1;
    background-color: #fff;
    border: 1px solid #fff;
    border-bottom: 1px solid var(--ion-color-medium);
    &:focus {
      outline: none;
    }
  }
  &-card-year {
    margin-right: 16px;
    width: 15%;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: var(--hk-color-romulan) !important;
    padding: 16px 0;
    border-radius: 0;
    flex-grow: 1;
    background-color: #fff;
    border: 1px solid #fff;
    border-bottom: 1px solid var(--ion-color-medium);
    &:focus {
      outline: none;
    }
  }
  &-card-cvv {
    margin-right: 16px;
    width: 20%;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: var(--hk-color-romulan) !important;
    padding: 16px 0;
    border-radius: 0;
    flex-grow: 1;
    background-color: #fff;
    border: 1px solid #fff;
    border-bottom: 1px solid var(--ion-color-medium);
    &:focus {
      outline: none;
    }
  }
  &-postal-code {
    width: 30% !important;
  }

  .hk-desktop-recurly-form-name-row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .hk-desktop-recurly-form-expiration-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .hk-desktop-recurly-form-back-button {
    cursor: pointer;
    margin-right: 16px;
    height: 48px;
    width: 200px;
    border-radius: 10px;
    border: 1px solid var(--hk-color-klingon);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }
}

// Mobile Styles
.hk-recurly-form-container {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  padding: 24px 16px;
  border-radius: 10px;
  border: 1px solid var(--hk-color-vulcan);
  .hk-recurly-form-inputs {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .hk-recurly-credit-card {
    width: 108px;
    height: 60px;
    margin-bottom: 16px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid var(--hk-color-vulcan);
    font-size: 12px;
    font-weight: bold;
    ion-icon {
      font-size: 24px;
    }
  }
  .hk-recurly-terms-and-conditions {
    margin-top: 32px;
    text-align: justify;
    font-size: 14px;
    color: var(--hk-color-voyager);
  }
}

.hk-recurly-form-actions {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  ion-button {
    --padding-start: 0;
    --border-radius: 8px !important;
    --box-shadow: none;
    text-transform: none;
    height: 48px;
    width: 100%;
    font-size: 16px;
    width: 100%;
    font-weight: bold;
    button.button-native::part(native) {
      .button-inner {
        justify-content: flex-start !important;
      }
    }
  }
}

.hk-recurly-form {
  input {
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: var(--hk-color-romulan) !important;
    padding: 16px 0 16px 2px;
    border-radius: 0;
    flex-grow: 1;
    background-color: #fff;
    border: 1px solid #fff;
    border-bottom: 1px solid var(--ion-color-medium);
    line-height: 30px;
    &:focus {
      outline: none;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      color: var(--ion-color-medium) !important;
      background-color: #fff !important;
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }
  .recurly-element {
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  &-field {
    font-weight: 500;
  }
  &-card-number {
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: var(--hk-color-romulan) !important;
    padding: 16px 0;
    border-radius: 0;
    flex-grow: 1;
    background-color: #fff;
    border: 1px solid #fff;
    border-bottom: 1px solid var(--ion-color-medium);
    &:focus {
      outline: none;
    }
  }
  &-card-month {
    margin-right: 16px;
    width: 15%;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: var(--hk-color-romulan) !important;
    padding: 16px 0;
    border-radius: 0;
    flex-grow: 1;
    background-color: #fff;
    border: 1px solid #fff;
    border-bottom: 1px solid var(--ion-color-medium);
    &:focus {
      outline: none;
    }
  }
  &-card-year {
    margin-right: 16px;
    width: 15%;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: var(--hk-color-romulan) !important;
    padding: 16px 0;
    border-radius: 0;
    flex-grow: 1;
    background-color: #fff;
    border: 1px solid #fff;
    border-bottom: 1px solid var(--ion-color-medium);
    &:focus {
      outline: none;
    }
  }
  &-card-cvv {
    margin-right: 16px;
    width: 20%;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: var(--hk-color-romulan) !important;
    padding: 16px 0;
    border-radius: 0;
    flex-grow: 1;
    background-color: #fff;
    border: 1px solid #fff;
    border-bottom: 1px solid var(--ion-color-medium);
    &:focus {
      outline: none;
    }
  }
  &-postal-code {
    width: 30% !important;
  }

  .hk-recurly-form-expiration-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
