// Desktop Styles
.hk-desktop-digital-add-document {
  --background: var(--hk-color-oxygen);
}

.hk-desktop-digital-add-document-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  top: 0;
  padding: 32px;
  border-bottom: 1px solid var(--hk-color-vulcan);
  background-color: var(--hk-color-oxygen);
  z-index: 1000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  &-title {
    margin-bottom: 4px;
    color: var(--hk-color-oxygen);
    font-size: 18px;
    font-weight: bold;
  }
}

.hk-desktop-digital-add-document-body {
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  overflow: auto;
  height: 426px;
  &-title {
    color: var(--hk-color-klingon);
    font-size: 16px;
    margin-bottom: 24px;
  }
  &-document-types {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    border-radius: 8px;
    background-color: var(--ion-background-color);
    font-weight: bold;
    --highlight-color-focused: none;
    margin-bottom: 32px;
  }
}

.select-popover {
  --background: var(--hk-color-oxygen) !important;
  --max-height: 170px !important;
  --min-width: 536px !important;
  transform: translateY(20px) !important;
}

ion-select-popover ion-list {
  color: var(--hk-color-oxygen) !important;
  background: var(--hk-color-oxygen) !important;
  padding: 0 !important;
  &:hover {
    cursor: pointer;
    --background: var(--hk-color-scotty);
  }
}

ion-select-popover ion-list ion-item {
  font-weight: bold;
  --background: var(--hk-color-oxygen) !important;
  &.item-radio-checked {
    --background: var(--ion-background-color) !important;
  }
}

.hk-desktop-digital-add-document-toolbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--hk-color-oxygen);
  padding: 16px 24px;
  border-top: 1px solid var(--hk-color-vulcan);
  ion-button {
    --box-shadow: none;
    text-transform: none;
    height: 48px;
    width: 200px;
    font-size: 16px;
    margin: 0 8px;
  }
}

// Cancel Upload Overlay Styles
.hk-cancel-upload-overlay {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  color: var(--hk-color-oxygen);
  font-size: 16px;
  h1 {
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  p {
    width: 224px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 32px;
  }
  .hk-cancel-upload-overlay-cancel-button {
    width: 164px;
    height: 45px;
    border-radius: 8px;
    color: var(--hk-color-klingon);
    font-size: 16px;
    font-weight: bold;
    margin-right: 16px;
    --background: var(--hk-color-oxygen);
  }
  .hk-cancel-upload-overlay-cancel-uploads-button {
    width: 164px;
    height: 45px;
    border-radius: 8px;
    color: var(--hk-color-klingon);
    font-size: 16px;
    font-weight: bold;
    margin-right: 16px;
  }
}

// Mobile Styles
.hk-digital-add-document {
  --background: var(--hk-color-oxygen);
}

.hk-digital-add-document-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  top: 0;
  padding: 28px 24px;
  height: 120px;
  border-bottom: 1px solid var(--hk-color-vulcan);
  background-color: var(--hk-color-oxygen);
  z-index: 1000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  &-title {
    margin-bottom: 4px;
    color: var(--hk-color-oxygen);
    font-size: 18px;
    font-weight: bold;
  }
}

.hk-digital-add-document-body {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  overflow: auto;
  &-title {
    color: var(--hk-color-klingon);
    font-size: 14px;
    margin-bottom: 24px;
  }
  &-document-types {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    border-radius: 8px;
    background-color: var(--ion-background-color);
    font-weight: bold;
    --highlight-color-focused: none;
    margin-bottom: 32px;
  }
}

.hk-digital-add-document-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--hk-color-oxygen);
  padding: 16px 24px;
  padding-bottom: 40px;
  ion-button {
    --box-shadow: none;
    text-transform: none;
    height: 48px;
    font-size: 16px;
  }
}

// Mobile Cancel Upload Overlay Styles
@media screen and (max-width: 768px) {
  .select-popover {
    --background: var(--hk-color-oxygen) !important;
    --max-height: 200px !important;
    --min-width: calc(100vw - 48px) !important;
    transform: translateY(20px) !important;
  }
  .hk-cancel-upload-overlay {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: start;
    position: absolute;
    padding: 80px 24px 40px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--hk-color-discovery);
    z-index: 100;
    color: var(--hk-color-oxygen);
    font-size: 16px;
    h1 {
      text-align: left;
      font-size: 23px;
      font-weight: bold;
      margin-bottom: 0px;
    }
    p {
      text-align: left;
      width: 100%;
      font-size: 16px;
      margin-bottom: 32px;
    }
    .hk-cancel-upload-overlay-buttons {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .hk-cancel-upload-overlay-cancel-button {
      width: 100%;
      height: 45px;
      border-radius: 8px;
      color: var(--hk-color-klingon);
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 20px;
      --background: var(--hk-color-oxygen);
    }
    .hk-cancel-upload-overlay-cancel-uploads-button {
      width: 100%;
      height: 45px;
      border-radius: 8px;
      color: var(--hk-color-klingon);
      font-size: 16px;
      font-weight: bold;
      margin-right: 16px;
    }
  }
}
