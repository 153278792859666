.hk-empty-list {
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-dark);
  img {
    max-width: 120px;
  }
  h2 {
    font-weight: bold;
    margin-top: 32px;
    margin-bottom: 0;
  }
  p {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
  }
}
