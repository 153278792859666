.hk-accordion-header::part(native) {
  background-color: var(--hk-color-oxygen);
  border-radius: 8px;
  margin-bottom: 8px;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  --border-width: 0 0 0px 0;
}

.hk-accordion-header .ion-accordion-toggle-icon {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.hk-accordion-header-task {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  width: 48px;
  height: 48px;
  border-radius: 0 10px 10px 0;
  background-color: #fbfbfb;
  border-left: 1px solid #f0f0f0;
  &-circle {
    min-height: 16px;
    min-width: 16px;
    background-color: var(--hk-color-oxygen);
    border-radius: 50%;
    border: 1px solid var(--ion-color-grey);
  }
}

.hk-accordion-content {
  padding: 0px;
  ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --inner-border-width: 0;
    margin-bottom: 8px;
  }
}
