.hk-header {
  display: flex;
  align-items: center;
  color: var(--hk-color-romulan);
  background: var(--hk-color-oxygen);
  height: 100px;
  z-index: 1000;
}

.hk-header-nav {
  max-width: 1440px;
  width: 100%;
  padding: 0 0 0 32px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-link {
    color: var(--hk-color-klingon);
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
    }
  }
  .get-started {
    color: var(--hk-color-oxygen);
    border-radius: 8px;
    background-color: var(--hk-color-enterprise);
    padding: 12px 32px;
    margin-right: 0;
    transition: all 0.5s ease-in-out;
    &:hover {
      background-color: var(--hk-color-romulan);
    }
  }
}

.partner-links {
  position: relative;
  display: inline-block;
  padding: 40px;
}

.partner-links-content {
  display: none;
  position: absolute;
  width: 180px;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  right: 0px;
  top: 90px;
  background-color: var(--hk-color-oxygen);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.partner-links-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.partner-links-content a:hover {
  transition: all 0.1s ease-in-out;
  background-color: #ddd;
}

.partner-links:hover .partner-links-content {
  display: block;
}

// MOBILE STYLES
.hk-mobile-header {
  display: flex;
  align-items: center;
  color: var(--hk-color-romulan);
  background: var(--hk-color-oxygen);
  height: 130px;
  padding: 24px 32px;
  padding-top: 74px;
  z-index: 1;
  &__content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &__logo {
    width: 200px;
  }
  &__icon {
    font-size: 24px;
    margin: 0;
  }
}

.hk-signup-menu {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 56px;
    &-logo {
      width: 200px;
      margin: 16px;
    }
    &-close {
      margin-right: 16px;
      font-size: 24px;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    &--link {
      padding: 24px;
      font-size: 16px;
      font-weight: bold;
      text-decoration: none;
      color: var(--hk-color-romulan);
      border-bottom: 1px solid var(--hk-color-vulcan);
    }
    &--item {
      border-bottom: 1px solid var(--hk-color-vulcan);
    }
    &--accordion {
      padding: 16px 8px;
      font-size: 16px;
      font-weight: bold;
      color: var(--hk-color-romulan);
      &-content {
        display: flex;
        flex-direction: column;
        a {
          padding: 24px 24px 24px 40px;
          font-size: 16px;
          font-weight: bold;
          text-decoration: none;
          color: var(--hk-color-romulan);
          border-bottom: 1px solid var(--hk-color-vulcan);
        }
      }
    }
  }
}
