// Desktop Styles
.hk-desktop-renew-page {
  top: 118px !important;
}

.hk-desktop-renew-header-container {
  width: 100%;
  background-color: var(--hk-color-discovery);
  color: var(--hk-color-oxygen);
}

.hk-desktop-renew-header {
  max-width: 1440px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px;
  h2 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    font-style: italic;
    color: var(--hk-color-enterprise);
    margin-top: 4px;
    margin-bottom: 24px;
  }
  p {
    font-size: 16px;
    margin: 0;
  }
}

.hk-desktop-renew-toggle-container {
  height: 48px;
  width: 326px;
}
.hk-desktop-renew-toggle {
  height: 48px;
  background-color: var(--ion-background-color);
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 2px;
  ion-chip {
    height: 44px;
    margin: 0;
    width: 326px;
    border-radius: 20px;
    justify-content: center;
    font-weight: bold;
    color: var(--hk-color-klingon);
    background-color: var(--ion-background-color);
    &.selected {
      color: #fff;
      background-color: var(--hk-color-discovery);
    }
  }
}

.hk-desktop-renew-body {
  width: 100%;
}

.hk-desktop-renew-plans {
  max-width: 1440px;
  margin: auto;
  padding: 32px;
  padding-top: 78px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.hk-plan-card-last-plan-header {
  position: absolute;
  top: -46px;
  height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 210, 183, 0.15);
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px 8px 0 0;
}

// Mobile Styles
.hk-renew-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 60px 32px 0 32px;
  h2 {
    padding-top: 24px;
    font-size: 20px;
    font-weight: bold;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    font-style: italic;
    color: var(--hk-color-enterprise);
  }
  p {
    font-size: 16px;
    margin: 0;
  }
}

.hk-renew-body {
  padding: 24px 0px 32px 0px;
}
.hk-renew-toggle-container {
  padding: 0 32px;
}
.hk-renew-toggle {
  max-width: 326px;
  z-index: 10;
  position: relative;
  background-color: #d3d3d3;
  border-radius: 20px;
  margin: auto;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  ion-chip {
    margin: 0;
    width: 326px;
    height: 48px;
    border-radius: 20px;
    justify-content: center;
    font-weight: bold;
    color: var(--ion-color-grey);
    background-color: #d3d3d3;
    &.selected {
      color: #fff;
      background-color: var(--ion-color-grey);
    }
  }
}

.hk-plan-card {
  width: 326px;
  z-index: 10;
  position: relative;
}

.hk-promo-banner {
  display: flex;
  justify-content: center;
  padding: 16px;
  align-items: center;
  min-height: 10px;
  width: 326px;
  border-radius: 10px 10px 0 0;
  background-color: #00acff;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: var(--hk-color-oxygen);
}
.no-promo {
  padding: 0;
}

.hk-subscription-plan {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0 0 10px 10px;
  width: 100%;
  height: 430px;
  background-color: var(--hk-color-romulan);
  color: var(--hk-color-vulcan);
  padding: 16px;
  text-align: center;
  &-last-plan {
    h6 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }
  }
  &-cost {
    margin-top: 16px;
    color: var(--hk-color-oxygen);
    font-size: 20px;
    font-weight: bold;
    &-amount {
      font-size: 36px;
    }
  }
  &-description {
    margin-top: 8px;
    color: var(--hk-color-oxygen);
    font-size: 16px;
    font-weight: bold;
  }
  &-list {
    margin-top: 16px;
    text-align: left;
    font-size: 14px;
    ul {
      padding-inline-start: 24px;
    }
  }
  &-renew-button {
    height: 44px;
    // margin-top: 56px;
  }
  &-add-ons {
    cursor: pointer;
    margin-top: 16px;
    color: var(--hk-color-enterprise);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  }
}

.hk-plan-addon-modal {
  &::part(content) {
    position: absolute;
    bottom: 0px;
    border-radius: 10px 10px 0 0 !important;
  }
}

.hk-renew-slider {
  .swiper-button-prev {
    top: 200px;
  }
  .swiper-button-next {
    top: 200px;
  }
}
