// DESKTOP STYLES
.hk-desktop-cancel-appointment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--hk-color-discovery);
  padding: 32px 24px 0 24px;

  &__title {
    font-size: 23px;
    font-weight: bold;
    margin: 0;
  }
  &__icon {
    font-size: 24px;
  }
}

.hk-desktop-cancel-appointment-body {
  padding-top: 0;
  &__subtitle {
    padding: 0 24px;
    margin-bottom: 40px;
  }
  &__details {
    padding: 16px 24px;
    border-top: 1px solid var(--hk-color-scotty);
    border-bottom: 1px solid var(--hk-color-scotty);
    h6 {
      font-weight: bold;
      margin-top: 0;
    }
    &-time {
      display: flex;
      align-items: center;
      ion-icon {
        margin-right: 8px;
        position: relative;
        top: 2px;
      }
      ion-text {
        font-size: 14px;
        color: #979797;
      }
    }
  }
}

.hk-desktop-cancel-appointment-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--hk-color-discovery);
  padding: 24px;
  ion-button {
    --border-radius: 8px;
    width: 224px;
    margin-bottom: 20px;
    &:first-child {
      margin-right: 16px;
    }
  }
}

// MOBILE STYLES
.hk-cancel-appointment-header {
  display: flex;
  justify-content: end;
  background-color: var(--hk-color-discovery);
  padding: 24px;
  &__icon {
    font-size: 24px;
  }
}

.hk-cancel-appointment-body {
  padding-top: 0;
  &__title {
    padding: 0 24px;
    font-size: 23px;
    font-weight: bold;
  }
  &__subtitle {
    padding: 0 24px;
    margin-bottom: 40px;
  }
  &__details {
    padding: 16px 24px;
    border-top: 1px solid var(--hk-color-scotty);
    border-bottom: 1px solid var(--hk-color-scotty);
    h6 {
      font-weight: bold;
      margin-top: 0;
    }
    &-time {
      display: flex;
      align-items: center;
      ion-icon {
        margin-right: 8px;
        position: relative;
        top: 2px;
      }
      ion-text {
        font-size: 14px;
        color: #979797;
      }
    }
  }
}

.hk-cancel-appointment-footer {
  background-color: var(--hk-color-discovery);
  padding: 24px;
  ion-button {
    --border-radius: 8px;
    margin-bottom: 20px;
  }
}

.hk-mobile-cancel-appointment-footer {
  background-color: var(--hk-color-discovery);
  padding: 24px;
  ion-button {
    --border-radius: 8px;
    margin-bottom: 40px;
  }
}
