// Desktop Styles
.hk-desktop-tutorial-header {
  --min-height: none;
  padding: 40px;

  ion-button {
    font-weight: bold;
    text-transform: lowercase;
  }
}

.hk-desktop-tutorial {
  .swiper-container {
    height: 80%;
    .swiper-slide {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 3em 0;
      h2 {
        font-weight: bold;
        padding: 0 0.5rem;
        margin-top: 40px;
        margin-bottom: 24px;
      }
      p {
        padding: 0 20px;
        font-size: 16px;
        line-height: 1.5;
        color: var(--ion-color-medium, #60646b);
      }
    }
  }
  .swiper-pagination.swiper-pagination-bullets {
    bottom: 28px;
  }

  .hk-illustration {
    min-height: 60px;
    max-height: 320px;
  }

  section {
    flex: 0 1 30%;
    width: 342px;
  }
  .hk-tutorial-buttons {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    top: -60px;
    z-index: 1;
    ion-button {
      --background: var(--ion-color-primary);
      --border-radius: 20px !important;
      --box-shadow: none;
      font-weight: bold;
      color: #fff;
      width: 90px;
      height: 40px;
      text-transform: none;
      letter-spacing: inherit;
    }
    &-back {
      position: relative;
      right: 120px;
      ion-icon {
        position: relative;
        right: 6px;
      }
    }
    &-next {
      position: relative;
      left: 120px;
      ion-icon {
        position: relative;
        left: 6px;
      }
    }
  }
}

// Mobile Styles
.hk-tutorial {
  .swiper-container {
    height: 100%;
    .swiper-slide {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 2em 0;
      h2 {
        font-weight: bold;
        padding: 0 0.5rem;
        margin: 2rem 0;
      }
      p {
        padding: 0 20px;
        font-size: 14px;
        line-height: 1.5;
        color: var(--ion-color-medium, #60646b);
      }
    }
  }
  .swiper-pagination.swiper-pagination-bullets {
    position: relative;
    bottom: 50px;
    width: 20%;
  }

  .hk-illustration {
    height: 60%;
  }

  section {
    flex: 0 1 30%;
  }
  ion-fab {
    margin: 20px;
  }
}

.tutorial-toolbar {
  --background: var(--ion-background-color);
  --ion-color-base: var(--ion-background-color);
  --border-width: 0 !important;
}

@media only screen and (max-height: 750px) {
  .swiper-slide {
    padding-bottom: 5em;
  }
}
