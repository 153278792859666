// Desktop Styles
.hk-desktop-digital-inventory-products-page {
  top: 120px !important;
}
.hk-desktop-digital-inventory-products-header {
  justify-content: center;
  background-color: var(--hk-color-discovery);
}

.hk-desktop-digital-inventory-products-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  padding: 0 32px;
}

.hk-desktop-digital-inventory-products-header-right {
  display: flex;
  align-items: center;
}

.hk-desktop-digital-inventory-products-header-breadcrumbs {
  ion-breadcrumb {
    color: var(--hk-color-enterprise);
    font-weight: 600;
  }
  .breadcrumb-active {
    color: var(--hk-color-oxygen);
  }
}

.hk-desktop-digital-inventory-products-search {
  margin-right: 24px;
  .hk-search-bar {
    padding: 16px 0;
    width: 358px;
    color: var(--ion-color-grey);
    .searchbar-input {
      height: 48px;
    }
    .searchbar-search-icon {
      width: 24px;
      top: 14px;
    }
  }
}
.hk-desktop-digital-inventory-products-header-button {
  ion-button {
    --box-shadow: none;
    text-transform: none;
    height: 48px;
    font-size: 16px;
    margin: 16px 0;
  }
}

.hk-desktop-digital-inventory-products {
  &-list {
    max-width: 1440px;
    margin: auto;
    margin-top: 32px;
    padding: 0 32px;

    .hk-desktop-digital-inventory-products-list-item-start-icon {
      min-width: 32px;
      height: 32px;
      padding: 8px;
      border-radius: 4px;
      background-color: var(--hk-color-oxygen);
      margin-right: 24px;
    }

    &-item-body {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-title {
        width: 50%;
        h2 {
          text-align: left;
          margin: 0px !important;
          font-size: 18px !important;
          font-weight: bold !important;
        }
      }
      &-description {
        width: 50%;
        text-align: left;
        font-size: 16px;
      }
      &-end-icon {
        font-size: 24px;
      }
    }
  }
}

// Mobile Styles
.hk-digital-inventory-products-list {
  // .hk-list-card:first-child {
  //   margin-top: 0;
  // }
  .hk-list-card.no-image .hk-list-card-thumbnail img {
    transform: scale(0.6);
    color: var(--ion-color-medium);
    opacity: 0.2;
  }
}

.hk-mobile-digital-inventory-products-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px 40px 24px;
  background-color: var(--ion-background-color);
  ion-button {
    --box-shadow: none;
    text-transform: none;
    height: 48px;
    font-size: 16px;
  }
}

.hk-digital-inventory-products-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: var(--ion-background-color);
  ion-button {
    --box-shadow: none;
    text-transform: none;
    height: 48px;
    font-size: 16px;
  }
}
